/* globals Lo-Dash */
// https://github.com/rwjblue/ember-cli-pretender

define('lodash', [], function() {
  "use strict";

  return {
    'default': _
  };
});
