enifed('ember-glimmer', ['exports', '@glimmer/runtime', '@glimmer/util', '@glimmer/node', 'ember-babel', '@glimmer/opcode-compiler', 'ember-owner', '@glimmer/reference', 'ember-runtime', 'ember-utils', 'ember-metal', '@ember/debug', 'ember-views', 'ember-browser-environment', '@ember/instrumentation', '@ember/service', 'node-module', '@ember/polyfills', 'ember-environment', '@ember/string', '@glimmer/wire-format', '@ember/deprecated-features', 'container', '@ember/runloop', 'rsvp', 'ember-routing'], function (exports, _runtime, _util, _node, _emberBabel, _opcodeCompiler, _emberOwner, _reference, _emberRuntime, _emberUtils, _emberMetal, _debug, _emberViews, _emberBrowserEnvironment, _instrumentation, _service, _nodeModule, _polyfills, _emberEnvironment, _string, _wireFormat, _deprecatedFeatures, _container, _runloop, _rsvp, _emberRouting) {
    'use strict';

    exports.getComponentManager = exports.setComponentManager = exports.capabilities = exports.OutletView = exports.DebugStack = exports.iterableFor = exports.INVOKE = exports.UpdatableReference = exports.AbstractComponentManager = exports._experimentalMacros = exports._registerMacros = exports.setupApplicationRegistry = exports.setupEngineRegistry = exports.setTemplates = exports.getTemplates = exports.hasTemplate = exports.setTemplate = exports.getTemplate = exports.renderSettled = exports._resetRenderers = exports.InteractiveRenderer = exports.InertRenderer = exports.Renderer = exports.isHTMLSafe = exports.htmlSafe = exports.escapeExpression = exports.SafeString = exports.Environment = exports.helper = exports.Helper = exports.ROOT_REF = exports.Component = exports.LinkComponent = exports.TextArea = exports.TextField = exports.Checkbox = exports.template = exports.RootTemplate = exports.NodeDOMTreeConstruction = exports.isSerializationFirstNode = exports.DOMTreeConstruction = exports.DOMChanges = undefined;
    Object.defineProperty(exports, 'DOMChanges', {
        enumerable: true,
        get: function () {
            return _runtime.DOMChanges;
        }
    });
    Object.defineProperty(exports, 'DOMTreeConstruction', {
        enumerable: true,
        get: function () {
            return _runtime.DOMTreeConstruction;
        }
    });
    Object.defineProperty(exports, 'isSerializationFirstNode', {
        enumerable: true,
        get: function () {
            return _util.isSerializationFirstNode;
        }
    });
    Object.defineProperty(exports, 'NodeDOMTreeConstruction', {
        enumerable: true,
        get: function () {
            return _node.NodeDOMTreeConstruction;
        }
    });

    var _templateObject = (0, _emberBabel.taggedTemplateLiteralLoose)(['template:components/-default'], ['template:components/-default']),
        _templateObject2 = (0, _emberBabel.taggedTemplateLiteralLoose)(['component:-default'], ['component:-default']),
        _templateObject3 = (0, _emberBabel.taggedTemplateLiteralLoose)(['template:-root'], ['template:-root']),
        _templateObject4 = (0, _emberBabel.taggedTemplateLiteralLoose)(['template-compiler:main'], ['template-compiler:main']);

    var _CoreView$extend;

    function template(json) {
        return new FactoryWrapper((0, _opcodeCompiler.templateFactory)(json));
    }

    var FactoryWrapper = function () {
        function FactoryWrapper(factory) {
            (0, _emberBabel.classCallCheck)(this, FactoryWrapper);

            this.factory = factory;
            this.id = factory.id;
            this.meta = factory.meta;
        }

        FactoryWrapper.prototype.create = function create(injections) {
            var owner = (0, _emberOwner.getOwner)(injections);
            return this.factory.create(injections.compiler, { owner: owner });
        };

        return FactoryWrapper;
    }();

    var RootTemplate = template({ "id": "Zi0CBVtc", "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[[22,0,[]]],null],false]],\"hasEval\":false}", "meta": { "moduleName": "packages/ember-glimmer/lib/templates/root.hbs" } });

    /**
    @module @ember/component
    */
    var RECOMPUTE_TAG = (0, _emberUtils.symbol)('RECOMPUTE_TAG');
    function isHelperFactory(helper) {
        return typeof helper === 'object' && helper !== null && helper.class && helper.class.isHelperFactory;
    }
    function isSimpleHelper(helper) {
        return helper.destroy === undefined;
    }
    /**
      Ember Helpers are functions that can compute values, and are used in templates.
      For example, this code calls a helper named `format-currency`:
    
      ```handlebars
      <div>{{format-currency cents currency="$"}}</div>
      ```
    
      Additionally a helper can be called as a nested helper (sometimes called a
      subexpression). In this example, the computed value of a helper is passed
      to a component named `show-money`:
    
      ```handlebars
      {{show-money amount=(format-currency cents currency="$")}}
      ```
    
      Helpers defined using a class must provide a `compute` function. For example:
    
      ```app/helpers/format-currency.js
      import Helper from '@ember/component/helper';
    
      export default Helper.extend({
        compute([cents], { currency }) {
          return `${currency}${cents * 0.01}`;
        }
      });
      ```
    
      Each time the input to a helper changes, the `compute` function will be
      called again.
    
      As instances, these helpers also have access to the container an will accept
      injected dependencies.
    
      Additionally, class helpers can call `recompute` to force a new computation.
    
      @class Helper
      @public
      @since 1.13.0
    */
    var Helper = _emberRuntime.FrameworkObject.extend({
        init: function () {
            this._super.apply(this, arguments);
            this[RECOMPUTE_TAG] = _reference.DirtyableTag.create();
        },
        recompute: function () {
            this[RECOMPUTE_TAG].inner.dirty();
        }
    });
    Helper.isHelperFactory = true;

    var Wrapper = function () {
        function Wrapper(compute) {
            (0, _emberBabel.classCallCheck)(this, Wrapper);

            this.compute = compute;
            this.isHelperFactory = true;
        }

        Wrapper.prototype.create = function create() {
            // needs new instance or will leak containers
            return {
                compute: this.compute
            };
        };

        return Wrapper;
    }();

    /**
      In many cases, the ceremony of a full `Helper` class is not required.
      The `helper` method create pure-function helpers without instances. For
      example:
    
      ```app/helpers/format-currency.js
      import { helper } from '@ember/component/helper';
    
      export default helper(function(params, hash) {
        let cents = params[0];
        let currency = hash.currency;
        return `${currency}${cents * 0.01}`;
      });
      ```
    
      @static
      @param {Function} helper The helper function
      @method helper
      @for @ember/component/helper
      @public
      @since 1.13.0
    */
    function helper(helperFn) {
        return new Wrapper(helperFn);
    }

    function _toBool(predicate) {
        if ((0, _emberRuntime.isArray)(predicate)) {
            return predicate.length !== 0;
        } else {
            return !!predicate;
        }
    }

    var UPDATE = (0, _emberUtils.symbol)('UPDATE');
    var INVOKE = (0, _emberUtils.symbol)('INVOKE');
    var ACTION = (0, _emberUtils.symbol)('ACTION');
    var maybeFreeze = void 0;
    if (true) {
        // gaurding this in a DEBUG gaurd (as well as all invocations)
        // so that it is properly stripped during the minification's
        // dead code elimination
        maybeFreeze = function (obj) {
            // re-freezing an already frozen object introduces a significant
            // performance penalty on Chrome (tested through 59).
            //
            // See: https://bugs.chromium.org/p/v8/issues/detail?id=6450
            if (!Object.isFrozen(obj)) {
                Object.freeze(obj);
            }
        };
    }

    var EmberPathReference = function () {
        function EmberPathReference() {
            (0, _emberBabel.classCallCheck)(this, EmberPathReference);
        }

        EmberPathReference.prototype.get = function get(key) {
            return PropertyReference.create(this, key);
        };

        return EmberPathReference;
    }();

    var CachedReference$1 = function (_EmberPathReference) {
        (0, _emberBabel.inherits)(CachedReference$1, _EmberPathReference);

        function CachedReference$1() {
            (0, _emberBabel.classCallCheck)(this, CachedReference$1);

            var _this = (0, _emberBabel.possibleConstructorReturn)(this, _EmberPathReference.call(this));

            _this._lastRevision = null;
            _this._lastValue = null;
            return _this;
        }

        CachedReference$1.prototype.value = function value() {
            var tag = this.tag,
                _lastRevision = this._lastRevision,
                _lastValue = this._lastValue;

            if (_lastRevision === null || !tag.validate(_lastRevision)) {
                _lastValue = this._lastValue = this.compute();
                this._lastRevision = tag.value();
            }
            return _lastValue;
        };

        return CachedReference$1;
    }(EmberPathReference);

    var RootReference = function (_ConstReference) {
        (0, _emberBabel.inherits)(RootReference, _ConstReference);

        function RootReference(value) {
            (0, _emberBabel.classCallCheck)(this, RootReference);

            var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _ConstReference.call(this, value));

            _this2.children = Object.create(null);
            return _this2;
        }

        RootReference.prototype.get = function get(propertyKey) {
            var ref = this.children[propertyKey];
            if (ref === undefined) {
                ref = this.children[propertyKey] = new RootPropertyReference(this.inner, propertyKey);
            }
            return ref;
        };

        return RootReference;
    }(_reference.ConstReference);

    var _TwoWayFlushDetectionTag = void 0;
    if (true) {
        _TwoWayFlushDetectionTag = function () {
            TwoWayFlushDetectionTag.create = function create(tag, key, ref) {
                return new _reference.TagWrapper(tag.type, new _TwoWayFlushDetectionTag(tag, key, ref));
            };

            function TwoWayFlushDetectionTag(tag, key, ref) {
                (0, _emberBabel.classCallCheck)(this, TwoWayFlushDetectionTag);

                this.tag = tag;
                this.parent = null;
                this.key = key;
                this.ref = ref;
            }

            TwoWayFlushDetectionTag.prototype.value = function value() {
                return this.tag.value();
            };

            TwoWayFlushDetectionTag.prototype.validate = function validate(ticket) {
                var parent = this.parent,
                    key = this.key;

                var isValid = this.tag.validate(ticket);
                if (isValid && parent) {
                    (0, _emberMetal.didRender)(parent, key, this.ref);
                }
                return isValid;
            };

            TwoWayFlushDetectionTag.prototype.didCompute = function didCompute(parent) {
                this.parent = parent;
                (0, _emberMetal.didRender)(parent, this.key, this.ref);
            };

            return TwoWayFlushDetectionTag;
        }();
    }

    var PropertyReference = function (_CachedReference$) {
        (0, _emberBabel.inherits)(PropertyReference, _CachedReference$);

        function PropertyReference() {
            (0, _emberBabel.classCallCheck)(this, PropertyReference);
            return (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference$.apply(this, arguments));
        }

        PropertyReference.create = function create(parentReference, propertyKey) {
            if ((0, _reference.isConst)(parentReference)) {
                return new RootPropertyReference(parentReference.value(), propertyKey);
            } else {
                return new NestedPropertyReference(parentReference, propertyKey);
            }
        };

        PropertyReference.prototype.get = function get(key) {
            return new NestedPropertyReference(this, key);
        };

        return PropertyReference;
    }(CachedReference$1);

    var RootPropertyReference = function (_PropertyReference) {
        (0, _emberBabel.inherits)(RootPropertyReference, _PropertyReference);

        function RootPropertyReference(parentValue, propertyKey) {
            (0, _emberBabel.classCallCheck)(this, RootPropertyReference);

            var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _PropertyReference.call(this));

            _this4._parentValue = parentValue;
            _this4._propertyKey = propertyKey;
            if (true) {
                _this4.tag = _TwoWayFlushDetectionTag.create((0, _emberMetal.tagForProperty)(parentValue, propertyKey), propertyKey, _this4);
            } else {
                _this4.tag = (0, _emberMetal.tagForProperty)(parentValue, propertyKey);
            }
            if (true) {
                (0, _emberMetal.watchKey)(parentValue, propertyKey);
            }
            return _this4;
        }

        RootPropertyReference.prototype.compute = function compute() {
            var _parentValue = this._parentValue,
                _propertyKey = this._propertyKey;

            if (true) {
                this.tag.inner.didCompute(_parentValue);
            }
            return (0, _emberMetal.get)(_parentValue, _propertyKey);
        };

        RootPropertyReference.prototype[UPDATE] = function (value) {
            (0, _emberMetal.set)(this._parentValue, this._propertyKey, value);
        };

        return RootPropertyReference;
    }(PropertyReference);

    var NestedPropertyReference = function (_PropertyReference2) {
        (0, _emberBabel.inherits)(NestedPropertyReference, _PropertyReference2);

        function NestedPropertyReference(parentReference, propertyKey) {
            (0, _emberBabel.classCallCheck)(this, NestedPropertyReference);

            var _this5 = (0, _emberBabel.possibleConstructorReturn)(this, _PropertyReference2.call(this));

            var parentReferenceTag = parentReference.tag;
            var parentObjectTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
            _this5._parentReference = parentReference;
            _this5._parentObjectTag = parentObjectTag;
            _this5._propertyKey = propertyKey;
            if (true) {
                var tag = (0, _reference.combine)([parentReferenceTag, parentObjectTag]);
                _this5.tag = _TwoWayFlushDetectionTag.create(tag, propertyKey, _this5);
            } else {
                _this5.tag = (0, _reference.combine)([parentReferenceTag, parentObjectTag]);
            }
            return _this5;
        }

        NestedPropertyReference.prototype.compute = function compute() {
            var _parentReference = this._parentReference,
                _parentObjectTag = this._parentObjectTag,
                _propertyKey = this._propertyKey;

            var parentValue = _parentReference.value();
            _parentObjectTag.inner.update((0, _emberMetal.tagForProperty)(parentValue, _propertyKey));
            var parentValueType = typeof parentValue;
            if (parentValueType === 'string' && _propertyKey === 'length') {
                return parentValue.length;
            }
            if (parentValueType === 'object' && parentValue !== null || parentValueType === 'function') {
                if (true) {
                    (0, _emberMetal.watchKey)(parentValue, _propertyKey);
                }
                if (true) {
                    this.tag.inner.didCompute(parentValue);
                }
                return (0, _emberMetal.get)(parentValue, _propertyKey);
            } else {
                return undefined;
            }
        };

        NestedPropertyReference.prototype[UPDATE] = function (value) {
            var parent = this._parentReference.value();
            (0, _emberMetal.set)(parent, this._propertyKey, value);
        };

        return NestedPropertyReference;
    }(PropertyReference);

    var UpdatableReference = function (_EmberPathReference2) {
        (0, _emberBabel.inherits)(UpdatableReference, _EmberPathReference2);

        function UpdatableReference(value) {
            (0, _emberBabel.classCallCheck)(this, UpdatableReference);

            var _this6 = (0, _emberBabel.possibleConstructorReturn)(this, _EmberPathReference2.call(this));

            _this6.tag = _reference.DirtyableTag.create();
            _this6._value = value;
            return _this6;
        }

        UpdatableReference.prototype.value = function value() {
            return this._value;
        };

        UpdatableReference.prototype.update = function update(value) {
            var _value = this._value;

            if (value !== _value) {
                this.tag.inner.dirty();
                this._value = value;
            }
        };

        return UpdatableReference;
    }(EmberPathReference);

    var ConditionalReference$1 = function (_ConditionalReference) {
        (0, _emberBabel.inherits)(ConditionalReference$1, _ConditionalReference);

        ConditionalReference$1.create = function create(reference) {
            if ((0, _reference.isConst)(reference)) {
                var value = reference.value();
                if ((0, _emberUtils.isProxy)(value)) {
                    return new RootPropertyReference(value, 'isTruthy');
                } else {
                    return _runtime.PrimitiveReference.create(_toBool(value));
                }
            }
            return new ConditionalReference$1(reference);
        };

        function ConditionalReference$1(reference) {
            (0, _emberBabel.classCallCheck)(this, ConditionalReference$1);

            var _this7 = (0, _emberBabel.possibleConstructorReturn)(this, _ConditionalReference.call(this, reference));

            _this7.objectTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
            _this7.tag = (0, _reference.combine)([reference.tag, _this7.objectTag]);
            return _this7;
        }

        ConditionalReference$1.prototype.toBool = function toBool(predicate) {
            if ((0, _emberUtils.isProxy)(predicate)) {
                this.objectTag.inner.update((0, _emberMetal.tagForProperty)(predicate, 'isTruthy'));
                return (0, _emberMetal.get)(predicate, 'isTruthy');
            } else {
                this.objectTag.inner.update((0, _emberMetal.tagFor)(predicate));
                return _toBool(predicate);
            }
        };

        return ConditionalReference$1;
    }(_runtime.ConditionalReference);

    var SimpleHelperReference = function (_CachedReference$2) {
        (0, _emberBabel.inherits)(SimpleHelperReference, _CachedReference$2);

        SimpleHelperReference.create = function create(helper$$1, args) {
            if ((0, _reference.isConst)(args)) {
                var positional = args.positional,
                    named = args.named;

                var positionalValue = positional.value();
                var namedValue = named.value();
                if (true) {
                    maybeFreeze(positionalValue);
                    maybeFreeze(namedValue);
                }
                var result = helper$$1(positionalValue, namedValue);
                return valueToRef(result);
            } else {
                return new SimpleHelperReference(helper$$1, args);
            }
        };

        function SimpleHelperReference(helper$$1, args) {
            (0, _emberBabel.classCallCheck)(this, SimpleHelperReference);

            var _this8 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference$2.call(this));

            _this8.tag = args.tag;
            _this8.helper = helper$$1;
            _this8.args = args;
            return _this8;
        }

        SimpleHelperReference.prototype.compute = function compute() {
            var helper$$1 = this.helper,
                _args2 = this.args,
                positional = _args2.positional,
                named = _args2.named;

            var positionalValue = positional.value();
            var namedValue = named.value();
            if (true) {
                maybeFreeze(positionalValue);
                maybeFreeze(namedValue);
            }
            return helper$$1(positionalValue, namedValue);
        };

        return SimpleHelperReference;
    }(CachedReference$1);

    var ClassBasedHelperReference = function (_CachedReference$3) {
        (0, _emberBabel.inherits)(ClassBasedHelperReference, _CachedReference$3);

        ClassBasedHelperReference.create = function create(instance, args) {
            return new ClassBasedHelperReference(instance, args);
        };

        function ClassBasedHelperReference(instance, args) {
            (0, _emberBabel.classCallCheck)(this, ClassBasedHelperReference);

            var _this9 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference$3.call(this));

            _this9.tag = (0, _reference.combine)([instance[RECOMPUTE_TAG], args.tag]);
            _this9.instance = instance;
            _this9.args = args;
            return _this9;
        }

        ClassBasedHelperReference.prototype.compute = function compute() {
            var instance = this.instance,
                _args3 = this.args,
                positional = _args3.positional,
                named = _args3.named;

            var positionalValue = positional.value();
            var namedValue = named.value();
            if (true) {
                maybeFreeze(positionalValue);
                maybeFreeze(namedValue);
            }
            return instance.compute(positionalValue, namedValue);
        };

        return ClassBasedHelperReference;
    }(CachedReference$1);

    var InternalHelperReference = function (_CachedReference$4) {
        (0, _emberBabel.inherits)(InternalHelperReference, _CachedReference$4);

        function InternalHelperReference(helper$$1, args) {
            (0, _emberBabel.classCallCheck)(this, InternalHelperReference);

            var _this10 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference$4.call(this));

            _this10.tag = args.tag;
            _this10.helper = helper$$1;
            _this10.args = args;
            return _this10;
        }

        InternalHelperReference.prototype.compute = function compute() {
            var helper$$1 = this.helper,
                args = this.args;

            return helper$$1(args);
        };

        return InternalHelperReference;
    }(CachedReference$1);

    var UnboundReference = function (_ConstReference2) {
        (0, _emberBabel.inherits)(UnboundReference, _ConstReference2);

        function UnboundReference() {
            (0, _emberBabel.classCallCheck)(this, UnboundReference);
            return (0, _emberBabel.possibleConstructorReturn)(this, _ConstReference2.apply(this, arguments));
        }

        UnboundReference.create = function create(value) {
            return valueToRef(value, false);
        };

        UnboundReference.prototype.get = function get(key) {
            return valueToRef((0, _emberMetal.get)(this.inner, key), false);
        };

        return UnboundReference;
    }(_reference.ConstReference);

    var ReadonlyReference = function (_CachedReference$5) {
        (0, _emberBabel.inherits)(ReadonlyReference, _CachedReference$5);

        function ReadonlyReference(inner) {
            (0, _emberBabel.classCallCheck)(this, ReadonlyReference);

            var _this12 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference$5.call(this));

            _this12.inner = inner;
            return _this12;
        }

        ReadonlyReference.prototype.compute = function compute() {
            return this.inner.value();
        };

        ReadonlyReference.prototype.get = function get(key) {
            return this.inner.get(key);
        };

        (0, _emberBabel.createClass)(ReadonlyReference, [{
            key: 'tag',
            get: function () {
                return this.inner.tag;
            }
        }, {
            key: INVOKE,
            get: function () {
                return this.inner[INVOKE];
            }
        }]);
        return ReadonlyReference;
    }(CachedReference$1);

    function referenceFromParts(root, parts) {
        var reference = root;
        for (var i = 0; i < parts.length; i++) {
            reference = reference.get(parts[i]);
        }
        return reference;
    }
    function valueToRef(value) {
        var bound = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        if (value !== null && typeof value === 'object') {
            // root of interop with ember objects
            return bound ? new RootReference(value) : new UnboundReference(value);
        }
        // ember doesn't do observing with functions
        if (typeof value === 'function') {
            return new UnboundReference(value);
        }
        return _runtime.PrimitiveReference.create(value);
    }

    var DIRTY_TAG = (0, _emberUtils.symbol)('DIRTY_TAG');
    var ARGS = (0, _emberUtils.symbol)('ARGS');
    var ROOT_REF = (0, _emberUtils.symbol)('ROOT_REF');
    var IS_DISPATCHING_ATTRS = (0, _emberUtils.symbol)('IS_DISPATCHING_ATTRS');
    var HAS_BLOCK = (0, _emberUtils.symbol)('HAS_BLOCK');
    var BOUNDS = (0, _emberUtils.symbol)('BOUNDS');
    /**
    @module @ember/component
    */
    /**
      A `Component` is a view that is completely
      isolated. Properties accessed in its templates go
      to the view object and actions are targeted at
      the view object. There is no access to the
      surrounding context or outer controller; all
      contextual information must be passed in.
    
      The easiest way to create a `Component` is via
      a template. If you name a template
      `app/components/my-foo.hbs`, you will be able to use
      `{{my-foo}}` in other templates, which will make
      an instance of the isolated component.
    
      ```app/components/my-foo.hbs
      {{person-profile person=currentUser}}
      ```
    
      ```app/components/person-profile.hbs
      <h1>{{person.title}}</h1>
      <img src={{person.avatar}}>
      <p class='signature'>{{person.signature}}</p>
      ```
    
      You can use `yield` inside a template to
      include the **contents** of any block attached to
      the component. The block will be executed in the
      context of the surrounding context or outer controller:
    
      ```handlebars
      {{#person-profile person=currentUser}}
        <p>Admin mode</p>
        {{! Executed in the controller's context. }}
      {{/person-profile}}
      ```
    
      ```app/components/person-profile.hbs
      <h1>{{person.title}}</h1>
      {{! Executed in the component's context. }}
      {{yield}} {{! block contents }}
      ```
    
      If you want to customize the component, in order to
      handle events or actions, you implement a subclass
      of `Component` named after the name of the
      component.
    
      For example, you could implement the action
      `hello` for the `person-profile` component:
    
      ```app/components/person-profile.js
      import Component from '@ember/component';
    
      export default Component.extend({
        actions: {
          hello(name) {
            console.log("Hello", name);
          }
        }
      });
      ```
    
      And then use it in the component's template:
    
      ```app/templates/components/person-profile.hbs
      <h1>{{person.title}}</h1>
      {{yield}} <!-- block contents -->
      <button {{action 'hello' person.name}}>
        Say Hello to {{person.name}}
      </button>
      ```
    
      Components must have a `-` in their name to avoid
      conflicts with built-in controls that wrap HTML
      elements. This is consistent with the same
      requirement in web components.
    
      ## HTML Tag
    
      The default HTML tag name used for a component's DOM representation is `div`.
      This can be customized by setting the `tagName` property.
      The following component class:
    
      ```app/components/emphasized-paragraph.js
      import Component from '@ember/component';
    
      export default Component.extend({
        tagName: 'em'
      });
      ```
    
      Would result in instances with the following HTML:
    
      ```html
      <em id="ember1" class="ember-view"></em>
      ```
    
      ## HTML `class` Attribute
    
      The HTML `class` attribute of a component's tag can be set by providing a
      `classNames` property that is set to an array of strings:
    
      ```app/components/my-widget.js
      import Component from '@ember/component';
    
      export default Component.extend({
        classNames: ['my-class', 'my-other-class']
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <div id="ember1" class="ember-view my-class my-other-class"></div>
      ```
    
      `class` attribute values can also be set by providing a `classNameBindings`
      property set to an array of properties names for the component. The return value
      of these properties will be added as part of the value for the components's `class`
      attribute. These properties can be computed properties:
    
      ```app/components/my-widget.js
      import Component from '@ember/component';
      import { computed } from '@ember/object';
    
      export default Component.extend({
        classNameBindings: ['propertyA', 'propertyB'],
    
        propertyA: 'from-a',
        propertyB: computed(function() {
          if (someLogic) { return 'from-b'; }
        })
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <div id="ember1" class="ember-view from-a from-b"></div>
      ```
    
      If the value of a class name binding returns a boolean the property name
      itself will be used as the class name if the property is true.
      The class name will not be added if the value is `false` or `undefined`.
    
      ```app/components/my-widget.js
      import Component from '@ember/component';
    
      export default Component.extend({
        classNameBindings: ['hovered'],
    
        hovered: true
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <div id="ember1" class="ember-view hovered"></div>
      ```
    
      When using boolean class name bindings you can supply a string value other
      than the property name for use as the `class` HTML attribute by appending the
      preferred value after a ":" character when defining the binding:
    
      ```app/components/my-widget.js
      import Component from '@ember/component';
    
      export default Component.extend({
        classNameBindings: ['awesome:so-very-cool'],
    
        awesome: true
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <div id="ember1" class="ember-view so-very-cool"></div>
      ```
    
      Boolean value class name bindings whose property names are in a
      camelCase-style format will be converted to a dasherized format:
    
      ```app/components/my-widget.js
      import Component from '@ember/component';
    
      export default Component.extend({
        classNameBindings: ['isUrgent'],
    
        isUrgent: true
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <div id="ember1" class="ember-view is-urgent"></div>
      ```
    
      Class name bindings can also refer to object values that are found by
      traversing a path relative to the component itself:
    
      ```app/components/my-widget.js
      import Component from '@ember/component';
      import EmberObject from '@ember/object';
    
      export default Component.extend({
        classNameBindings: ['messages.empty'],
    
        messages: EmberObject.create({
          empty: true
        })
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <div id="ember1" class="ember-view empty"></div>
      ```
    
      If you want to add a class name for a property which evaluates to true and
      and a different class name if it evaluates to false, you can pass a binding
      like this:
    
      ```app/components/my-widget.js
      import Component from '@ember/component';
    
      export default Component.extend({
        classNameBindings: ['isEnabled:enabled:disabled'],
        isEnabled: true
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <div id="ember1" class="ember-view enabled"></div>
      ```
    
      When isEnabled is `false`, the resulting HTML representation looks like
      this:
    
      ```html
      <div id="ember1" class="ember-view disabled"></div>
      ```
    
      This syntax offers the convenience to add a class if a property is `false`:
    
      ```app/components/my-widget.js
      import Component from '@ember/component';
    
      // Applies no class when isEnabled is true and class 'disabled' when isEnabled is false
      export default Component.extend({
        classNameBindings: ['isEnabled::disabled'],
        isEnabled: true
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <div id="ember1" class="ember-view"></div>
      ```
    
      When the `isEnabled` property on the component is set to `false`, it will result
      in component instances with an HTML representation of:
    
      ```html
      <div id="ember1" class="ember-view disabled"></div>
      ```
    
      Updates to the value of a class name binding will result in automatic
      update of the  HTML `class` attribute in the component's rendered HTML
      representation. If the value becomes `false` or `undefined` the class name
      will be removed.
      Both `classNames` and `classNameBindings` are concatenated properties. See
      [EmberObject](/api/ember/release/classes/EmberObject) documentation for more
      information about concatenated properties.
    
      ## HTML Attributes
    
      The HTML attribute section of a component's tag can be set by providing an
      `attributeBindings` property set to an array of property names on the component.
      The return value of these properties will be used as the value of the component's
      HTML associated attribute:
    
      ```app/components/my-anchor.js
      import Component from '@ember/component';
    
      export default Component.extend({
        tagName: 'a',
        attributeBindings: ['href'],
    
        href: 'http://google.com'
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <a id="ember1" class="ember-view" href="http://google.com"></a>
      ```
    
      One property can be mapped on to another by placing a ":" between
      the source property and the destination property:
    
      ```app/components/my-anchor.js
      import Component from '@ember/component';
    
      export default Component.extend({
        tagName: 'a',
        attributeBindings: ['url:href'],
    
        url: 'http://google.com'
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <a id="ember1" class="ember-view" href="http://google.com"></a>
      ```
    
      Namespaced attributes (e.g. `xlink:href`) are supported, but have to be
      mapped, since `:` is not a valid character for properties in Javascript:
    
      ```app/components/my-use.js
      import Component from '@ember/component';
    
      export default Component.extend({
        tagName: 'use',
        attributeBindings: ['xlinkHref:xlink:href'],
    
        xlinkHref: '#triangle'
      });
      ```
    
      Will result in component instances with an HTML representation of:
    
      ```html
      <use xlink:href="#triangle"></use>
      ```
    
      If the return value of an `attributeBindings` monitored property is a boolean
      the attribute will be present or absent depending on the value:
    
      ```app/components/my-text-input.js
      import Component from '@ember/component';
    
      export default Component.extend({
        tagName: 'input',
        attributeBindings: ['disabled'],
    
        disabled: false
      });
      ```
    
      Will result in a component instance with an HTML representation of:
    
      ```html
      <input id="ember1" class="ember-view" />
      ```
    
      `attributeBindings` can refer to computed properties:
    
      ```app/components/my-text-input.js
      import Component from '@ember/component';
      import { computed } from '@ember/object';
    
      export default Component.extend({
        tagName: 'input',
        attributeBindings: ['disabled'],
    
        disabled: computed(function() {
          if (someLogic) {
            return true;
          } else {
            return false;
          }
        })
      });
      ```
    
      To prevent setting an attribute altogether, use `null` or `undefined` as the
      return value of the `attributeBindings` monitored property:
    
      ```app/components/my-text-input.js
      import Component from '@ember/component';
    
      export default Component.extend({
        tagName: 'form',
        attributeBindings: ['novalidate'],
        novalidate: null
      });
      ```
    
      Updates to the property of an attribute binding will result in automatic
      update of the  HTML attribute in the component's rendered HTML representation.
      `attributeBindings` is a concatenated property. See [EmberObject](/api/ember/release/classes/EmberObject)
      documentation for more information about concatenated properties.
    
      ## Layouts
    
      See [Ember.Templates.helpers.yield](/api/ember/release/classes/Ember.Templates.helpers/methods/yield?anchor=yield)
      for more information.
    
      Layout can be used to wrap content in a component. In addition
      to wrapping content in a Component's template, you can also use
      the public layout API in your Component JavaScript.
    
      ```app/templates/components/person-profile.hbs
        <h1>Person's Title</h1>
        <div class='details'>{{yield}}</div>
      ```
    
      ```app/components/person-profile.js
        import Component from '@ember/component';
        import layout from '../templates/components/person-profile';
    
        export default Component.extend({
          layout
        });
      ```
    
      The above will result in the following HTML output:
    
      ```html
        <h1>Person's Title</h1>
        <div class="details">
          <h2>Chief Basket Weaver</h2>
          <h3>Fisherman Industries</h3>
        </div>
      ```
    
      ## Responding to Browser Events
    
      Components can respond to user-initiated events in one of three ways: method
      implementation, through an event manager, and through `{{action}}` helper use
      in their template or layout.
    
      ### Method Implementation
    
      Components can respond to user-initiated events by implementing a method that
      matches the event name. A `jQuery.Event` object will be passed as the
      argument to this method.
    
      ```app/components/my-widget.js
      import Component from '@ember/component';
    
      export default Component.extend({
        click(event) {
          // will be called when an instance's
          // rendered element is clicked
        }
      });
      ```
    
      ### `{{action}}` Helper
    
      See [Ember.Templates.helpers.action](/api/ember/release/classes/Ember.Templates.helpers/methods/yield?anchor=yield).
    
      ### Event Names
    
      All of the event handling approaches described above respond to the same set
      of events. The names of the built-in events are listed below. (The hash of
      built-in events exists in `Ember.EventDispatcher`.) Additional, custom events
      can be registered by using `Application.customEvents`.
    
      Touch events:
    
      * `touchStart`
      * `touchMove`
      * `touchEnd`
      * `touchCancel`
    
      Keyboard events:
    
      * `keyDown`
      * `keyUp`
      * `keyPress`
    
      Mouse events:
    
      * `mouseDown`
      * `mouseUp`
      * `contextMenu`
      * `click`
      * `doubleClick`
      * `mouseMove`
      * `focusIn`
      * `focusOut`
      * `mouseEnter`
      * `mouseLeave`
    
      Form events:
    
      * `submit`
      * `change`
      * `focusIn`
      * `focusOut`
      * `input`
    
      HTML5 drag and drop events:
    
      * `dragStart`
      * `drag`
      * `dragEnter`
      * `dragLeave`
      * `dragOver`
      * `dragEnd`
      * `drop`
    
      @class Component
      @extends Ember.CoreView
      @uses Ember.TargetActionSupport
      @uses Ember.ClassNamesSupport
      @uses Ember.ActionSupport
      @uses Ember.ViewMixin
      @uses Ember.ViewStateSupport
      @public
    */
    var Component = _emberViews.CoreView.extend(_emberViews.ChildViewsSupport, _emberViews.ViewStateSupport, _emberViews.ClassNamesSupport, _emberRuntime.TargetActionSupport, _emberViews.ActionSupport, _emberViews.ViewMixin, (_CoreView$extend = {
        isComponent: true,
        init: function () {
            var _this13 = this;

            this._super.apply(this, arguments);
            this[IS_DISPATCHING_ATTRS] = false;
            this[DIRTY_TAG] = _reference.DirtyableTag.create();
            this[ROOT_REF] = new RootReference(this);
            this[BOUNDS] = null;
            // If in a tagless component, assert that no event handlers are defined
            (true && !(this.tagName !== '' || !this.renderer._destinedForDOM || !function () {
                var eventDispatcher = (0, _emberOwner.getOwner)(_this13).lookup('event_dispatcher:main');
                var events = eventDispatcher && eventDispatcher._finalEvents || {};
                // tslint:disable-next-line:forin
                for (var key in events) {
                    var methodName = events[key];
                    if (typeof _this13[methodName] === 'function') {
                        return true; // indicate that the assertion should be triggered
                    }
                }
                return false;
            }()) && (0, _debug.assert)('You can not define a function that handles DOM events in the `' + this + '` tagless component since it doesn\'t have any DOM element.', this.tagName !== '' || !this.renderer._destinedForDOM || !function () {
                var eventDispatcher = (0, _emberOwner.getOwner)(_this13).lookup('event_dispatcher:main');var events = eventDispatcher && eventDispatcher._finalEvents || {};for (var key in events) {
                    var methodName = events[key];if (typeof _this13[methodName] === 'function') {
                        return true;
                    }
                }return false;
            }()));
        },
        rerender: function () {
            this[DIRTY_TAG].inner.dirty();
            this._super();
        }
    }, _CoreView$extend[_emberMetal.PROPERTY_DID_CHANGE] = function (key) {
        if (this[IS_DISPATCHING_ATTRS]) {
            return;
        }
        var args = this[ARGS];
        var reference = args !== undefined ? args[key] : undefined;
        if (reference !== undefined && reference[UPDATE] !== undefined) {
            reference[UPDATE]((0, _emberMetal.get)(this, key));
        }
    }, _CoreView$extend.getAttr = function (key) {
        // TODO Intimate API should be deprecated
        return this.get(key);
    }, _CoreView$extend.readDOMAttr = function (name) {
        // TODO revisit this
        var element = (0, _emberViews.getViewElement)(this);
        var isSVG = element.namespaceURI === _runtime.SVG_NAMESPACE;

        var _normalizeProperty = (0, _runtime.normalizeProperty)(element, name),
            type = _normalizeProperty.type,
            normalized = _normalizeProperty.normalized;

        if (isSVG || type === 'attr') {
            return element.getAttribute(normalized);
        }
        return element[normalized];
    }, _CoreView$extend));
    Component.toString = function () {
        return '@ember/component';
    };
    Component.reopenClass({
        isComponentFactory: true,
        positionalParams: []
    });

    var layout = template({ "id": "5jp2oO+o", "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}", "meta": { "moduleName": "packages/ember-glimmer/lib/templates/empty.hbs" } });

    /**
    @module @ember/component
    */
    /**
      The internal class used to create text inputs when the `{{input}}`
      helper is used with `type` of `checkbox`.
    
      See [Ember.Templates.helpers.input](/api/ember/release/classes/Ember.Templates.helpers/methods/input?anchor=input)  for usage details.
    
      ## Direct manipulation of `checked`
    
      The `checked` attribute of an `Checkbox` object should always be set
      through the Ember object or by interacting with its rendered element
      representation via the mouse, keyboard, or touch. Updating the value of the
      checkbox via jQuery will result in the checked value of the object and its
      element losing synchronization.
    
      ## Layout and LayoutName properties
    
      Because HTML `input` elements are self closing `layout` and `layoutName`
      properties will not be applied.
    
      @class Checkbox
      @extends Component
      @public
    */
    var Checkbox = Component.extend({
        layout: layout,
        classNames: ['ember-checkbox'],
        tagName: 'input',
        attributeBindings: ['type', 'checked', 'indeterminate', 'disabled', 'tabindex', 'name', 'autofocus', 'required', 'form'],
        type: 'checkbox',
        disabled: false,
        indeterminate: false,
        didInsertElement: function () {
            this._super.apply(this, arguments);
            (0, _emberMetal.get)(this, 'element').indeterminate = !!(0, _emberMetal.get)(this, 'indeterminate');
        },
        change: function () {
            (0, _emberMetal.set)(this, 'checked', this.element.checked);
        }
    });
    Checkbox.toString = function () {
        return '@ember/component/checkbox';
    };

    /**
    @module @ember/component
    */
    var inputTypes = Object.create(null);
    function canSetTypeOfInput(type) {
        if (type in inputTypes) {
            return inputTypes[type];
        }
        // if running in outside of a browser always return the
        // original type
        if (!_emberBrowserEnvironment.hasDOM) {
            inputTypes[type] = type;
            return type;
        }
        var inputTypeTestElement = document.createElement('input');
        try {
            inputTypeTestElement.type = type;
        } catch (e) {
            // ignored
        }
        return inputTypes[type] = inputTypeTestElement.type === type;
    }
    /**
    
      The internal class used to create text inputs when the `{{input}}`
      helper is used with `type` of `text`.
    
      See [Ember.Templates.helpers.input](/api/ember/release/classes/Ember.Templates.helpers/methods/input?anchor=input)  for usage details.
    
      ## Layout and LayoutName properties
    
      Because HTML `input` elements are self closing `layout` and `layoutName`
      properties will not be applied.
    
      @class TextField
      @extends Component
      @uses Ember.TextSupport
      @public
    */
    var TextField = Component.extend(_emberViews.TextSupport, {
        layout: layout,
        classNames: ['ember-text-field'],
        tagName: 'input',
        attributeBindings: ['accept', 'autocomplete', 'autosave', 'dir', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'type', 'max', 'min', 'multiple', 'name', 'pattern', 'size', 'step', 'value', 'width'],
        /**
          The `value` attribute of the input element. As the user inputs text, this
          property is updated live.
             @property value
          @type String
          @default ""
          @public
        */
        value: '',
        /**
          The `type` attribute of the input element.
             @property type
          @type String
          @default "text"
          @public
        */
        type: (0, _emberMetal.computed)({
            get: function () {
                return 'text';
            },
            set: function (_key, value) {
                var type = 'text';
                if (canSetTypeOfInput(value)) {
                    type = value;
                }
                return type;
            }
        }),
        /**
          The `size` of the text field in characters.
             @property size
          @type String
          @default null
          @public
        */
        size: null,
        /**
          The `pattern` attribute of input element.
             @property pattern
          @type String
          @default null
          @public
        */
        pattern: null,
        /**
          The `min` attribute of input element used with `type="number"` or `type="range"`.
             @property min
          @type String
          @default null
          @since 1.4.0
          @public
        */
        min: null,
        /**
          The `max` attribute of input element used with `type="number"` or `type="range"`.
             @property max
          @type String
          @default null
          @since 1.4.0
          @public
        */
        max: null
    });
    TextField.toString = function () {
        return '@ember/component/text-field';
    };

    /**
    @module @ember/component
    */
    /**
      `{{textarea}}` inserts a new instance of `<textarea>` tag into the template.
      The attributes of `{{textarea}}` match those of the native HTML tags as
      closely as possible.
    
      The following HTML attributes can be set:
    
        * `value`
        * `name`
        * `rows`
        * `cols`
        * `placeholder`
        * `disabled`
        * `maxlength`
        * `tabindex`
        * `selectionEnd`
        * `selectionStart`
        * `autocomplete`
        * `selectionDirection`
        * `wrap`
        * `readonly`
        * `autofocus`
        * `form`
        * `spellcheck`
        * `required`
    
      When set to a quoted string, these value will be directly applied to the HTML
      element. When left unquoted, these values will be bound to a property on the
      template's current rendering context (most typically a controller instance).
    
      Unbound:
    
      ```handlebars
      {{textarea value="Lots of static text that ISN'T bound"}}
      ```
    
      Would result in the following HTML:
    
      ```html
      <textarea class="ember-text-area">
        Lots of static text that ISN'T bound
      </textarea>
      ```
    
      Bound:
    
      In the following example, the `writtenWords` property on the application
      Controller will be updated live as the user types 'Lots of text that IS
      bound' into the text area of their browser's window.
    
      ```app/controllers/application.js
      import Controller from '@ember/controller';
    
      export default Controller.extend({
        writtenWords: "Lots of text that IS bound"
      });
      ```
    
      ```handlebars
      {{textarea value=writtenWords}}
      ```
    
      Would result in the following HTML:
    
      ```html
      <textarea class="ember-text-area">
        Lots of text that IS bound
      </textarea>
      ```
    
      If you wanted a one way binding between the text area and a div tag
      somewhere else on your screen, you could use `oneWay`:
    
      ```app/controllers/application.js
      import Controller from '@ember/controller';
      import { oneWay } from '@ember/object/computed';
    
      export default Controller.extend({
        writtenWords: "Lots of text that IS bound",
    
        outputWrittenWords: oneWay("writtenWords")
      });
      ```
    
      ```handlebars
      {{textarea value=writtenWords}}
      <div>
        {{outputWrittenWords}}
      </div>
      ```
    
      Would result in the following HTML:
    
      ```html
      <textarea class="ember-text-area">
        Lots of text that IS bound
      </textarea>
      <-- the following div will be updated in real time as you type -->
      <div>
        Lots of text that IS bound
      </div>
      ```
    
      Finally, this example really shows the power and ease of Ember when two
      properties are bound to eachother via `alias`. Type into
      either text area box and they'll both stay in sync. Note that
      `alias` costs more in terms of performance, so only use it when
      your really binding in both directions:
    
      ```app/controllers/application.js
      import Controller from '@ember/controller';
      import { alias } from '@ember/object/computed';
    
      export default Controller.extend({
        writtenWords: "Lots of text that IS bound",
    
        twoWayWrittenWords: alias("writtenWords")
      });
      ```
    
      ```handlebars
      {{textarea value=writtenWords}}
      {{textarea value=twoWayWrittenWords}}
      ```
    
      ```html
      <textarea id="ember1" class="ember-text-area">
        Lots of text that IS bound
      </textarea>
      <-- both updated in real time -->
      <textarea id="ember2" class="ember-text-area">
        Lots of text that IS bound
      </textarea>
      ```
    
      ### Actions
    
      The helper can send multiple actions based on user events.
      The action property defines the action which is send when
      the user presses the return key.
    
      ```handlebars
      {{input action="submit"}}
      ```
    
      The helper allows some user events to send actions.
    
      * `enter`
      * `insert-newline`
      * `escape-press`
      * `focus-in`
      * `focus-out`
      * `key-press`
    
      For example, if you desire an action to be sent when the input is blurred,
      you only need to setup the action name to the event name property.
    
      ```handlebars
      {{textarea focus-out="alertMessage"}}
      ```
    
      See more about [Text Support Actions](/api/ember/release/classes/TextArea)
    
      ### Extension
    
      Internally, `{{textarea}}` creates an instance of `TextArea`, passing
      arguments from the helper to `TextArea`'s `create` method. You can
      extend the capabilities of text areas in your application by reopening this
      class. For example, if you are building a Bootstrap project where `data-*`
      attributes are used, you can globally add support for a `data-*` attribute
      on all `{{textarea}}`s' in your app by reopening `TextArea` or
      `TextSupport` and adding it to the `attributeBindings` concatenated
      property:
    
      ```javascript
      import TextArea from '@ember/component/text-area';
    
      TextArea.reopen({
        attributeBindings: ['data-error']
      });
      ```
    
      Keep in mind when writing `TextArea` subclasses that `TextArea`
      itself extends `Component`. Expect isolated component semantics, not
      legacy 1.x view semantics (like `controller` being present).
    
      See more about [Ember components](/api/ember/release/classes/Component)
    
      @method textarea
      @for Ember.Templates.helpers
      @param {Hash} options
      @public
    */
    /**
      The internal class used to create textarea element when the `{{textarea}}`
      helper is used.
    
      See [Ember.Templates.helpers.textarea](/api/ember/release/classes/Ember.Templates.helpers/methods/textarea?anchor=textarea)  for usage details.
    
      ## Layout and LayoutName properties
    
      Because HTML `textarea` elements do not contain inner HTML the `layout` and
      `layoutName` properties will not be applied.
    
      @class TextArea
      @extends Component
      @uses Ember.TextSupport
      @public
    */
    var TextArea = Component.extend(_emberViews.TextSupport, {
        classNames: ['ember-text-area'],
        layout: layout,
        tagName: 'textarea',
        attributeBindings: ['rows', 'cols', 'name', 'selectionEnd', 'selectionStart', 'autocomplete', 'wrap', 'lang', 'dir', 'value'],
        rows: null,
        cols: null
    });
    TextArea.toString = function () {
        return '@ember/component/text-area';
    };

    var layout$1 = template({ "id": "/tT8MjC4", "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"linkTitle\"]]],null,{\"statements\":[[1,[21,\"linkTitle\"],false]],\"parameters\":[]},{\"statements\":[[14,1]],\"parameters\":[]}]],\"hasEval\":false}", "meta": { "moduleName": "packages/ember-glimmer/lib/templates/link-to.hbs" } });

    /**
    @module ember
    */
    /**
      @module @ember/routing
    */
    /**
      `LinkComponent` renders an element whose `click` event triggers a
      transition of the application's instance of `Router` to
      a supplied route by name.
    
      `LinkComponent` components are invoked with {{#link-to}}. Properties
      of this class can be overridden with `reopen` to customize application-wide
      behavior.
    
      @class LinkComponent
      @extends Component
      @see {Ember.Templates.helpers.link-to}
      @public
    **/
    var LinkComponent = Component.extend({
        layout: layout$1,
        tagName: 'a',
        /**
          Used to determine when this `LinkComponent` is active.
             @property current-when
          @public
        */
        'current-when': null,
        /**
          Sets the `title` attribute of the `LinkComponent`'s HTML element.
             @property title
          @default null
          @public
        **/
        title: null,
        /**
          Sets the `rel` attribute of the `LinkComponent`'s HTML element.
             @property rel
          @default null
          @public
        **/
        rel: null,
        /**
          Sets the `tabindex` attribute of the `LinkComponent`'s HTML element.
             @property tabindex
          @default null
          @public
        **/
        tabindex: null,
        /**
          Sets the `target` attribute of the `LinkComponent`'s HTML element.
             @since 1.8.0
          @property target
          @default null
          @public
        **/
        target: null,
        /**
          The CSS class to apply to `LinkComponent`'s element when its `active`
          property is `true`.
             @property activeClass
          @type String
          @default active
          @public
        **/
        activeClass: 'active',
        /**
          The CSS class to apply to `LinkComponent`'s element when its `loading`
          property is `true`.
             @property loadingClass
          @type String
          @default loading
          @private
        **/
        loadingClass: 'loading',
        /**
          The CSS class to apply to a `LinkComponent`'s element when its `disabled`
          property is `true`.
             @property disabledClass
          @type String
          @default disabled
          @private
        **/
        disabledClass: 'disabled',
        /**
          Determines whether the `LinkComponent` will trigger routing via
          the `replaceWith` routing strategy.
             @property replace
          @type Boolean
          @default false
          @public
        **/
        replace: false,
        /**
          By default the `{{link-to}}` component will bind to the `href` and
          `title` attributes. It's discouraged that you override these defaults,
          however you can push onto the array if needed.
             @property attributeBindings
          @type Array | String
          @default ['title', 'rel', 'tabindex', 'target']
          @public
        */
        attributeBindings: ['href', 'title', 'rel', 'tabindex', 'target'],
        /**
          By default the `{{link-to}}` component will bind to the `active`, `loading`,
          and `disabled` classes. It is discouraged to override these directly.
             @property classNameBindings
          @type Array
          @default ['active', 'loading', 'disabled', 'ember-transitioning-in', 'ember-transitioning-out']
          @public
        */
        classNameBindings: ['active', 'loading', 'disabled', 'transitioningIn', 'transitioningOut'],
        /**
          By default the `{{link-to}}` component responds to the `click` event. You
          can override this globally by setting this property to your custom
          event name.
             This is particularly useful on mobile when one wants to avoid the 300ms
          click delay using some sort of custom `tap` event.
             @property eventName
          @type String
          @default click
          @private
        */
        eventName: 'click',
        init: function () {
            this._super.apply(this, arguments);
            // Map desired event name to invoke function
            var eventName = (0, _emberMetal.get)(this, 'eventName');
            this.on(eventName, this, this._invoke);
        },

        _routing: (0, _service.inject)('-routing'),
        /**
          Accessed as a classname binding to apply the `LinkComponent`'s `disabledClass`
          CSS `class` to the element when the link is disabled.
             When `true` interactions with the element will not trigger route changes.
          @property disabled
          @private
        */
        disabled: (0, _emberMetal.computed)({
            get: function (_key) {
                // always returns false for `get` because (due to the `set` just below)
                // the cached return value from the set will prevent this getter from _ever_
                // being called after a set has occured
                return false;
            },
            set: function (_key, value) {
                this._isDisabled = value;
                return value ? (0, _emberMetal.get)(this, 'disabledClass') : false;
            }
        }),
        _isActive: function (routerState) {
            if ((0, _emberMetal.get)(this, 'loading')) {
                return false;
            }
            var currentWhen = (0, _emberMetal.get)(this, 'current-when');
            if (typeof currentWhen === 'boolean') {
                return currentWhen;
            }
            var isCurrentWhenSpecified = !!currentWhen;
            currentWhen = currentWhen || (0, _emberMetal.get)(this, 'qualifiedRouteName');
            currentWhen = currentWhen.split(' ');
            var routing = (0, _emberMetal.get)(this, '_routing');
            var models = (0, _emberMetal.get)(this, 'models');
            var resolvedQueryParams = (0, _emberMetal.get)(this, 'resolvedQueryParams');
            for (var i = 0; i < currentWhen.length; i++) {
                if (routing.isActiveForRoute(models, resolvedQueryParams, currentWhen[i], routerState, isCurrentWhenSpecified)) {
                    return true;
                }
            }
            return false;
        },

        /**
          Accessed as a classname binding to apply the `LinkComponent`'s `activeClass`
          CSS `class` to the element when the link is active.
             A `LinkComponent` is considered active when its `currentWhen` property is `true`
          or the application's current route is the route the `LinkComponent` would trigger
          transitions into.
             The `currentWhen` property can match against multiple routes by separating
          route names using the ` ` (space) character.
             @property active
          @private
        */
        active: (0, _emberMetal.computed)('activeClass', '_active', function computeLinkToComponentActiveClass() {
            return this.get('_active') ? (0, _emberMetal.get)(this, 'activeClass') : false;
        }),
        _active: (0, _emberMetal.computed)('_routing.currentState', 'attrs.params', function computeLinkToComponentActive() {
            var currentState = (0, _emberMetal.get)(this, '_routing.currentState');
            if (!currentState) {
                return false;
            }
            return this._isActive(currentState);
        }),
        willBeActive: (0, _emberMetal.computed)('_routing.targetState', function computeLinkToComponentWillBeActive() {
            var routing = (0, _emberMetal.get)(this, '_routing');
            var targetState = (0, _emberMetal.get)(routing, 'targetState');
            if ((0, _emberMetal.get)(routing, 'currentState') === targetState) {
                return;
            }
            return this._isActive(targetState);
        }),
        transitioningIn: (0, _emberMetal.computed)('active', 'willBeActive', function computeLinkToComponentTransitioningIn() {
            if ((0, _emberMetal.get)(this, 'willBeActive') === true && !(0, _emberMetal.get)(this, '_active')) {
                return 'ember-transitioning-in';
            } else {
                return false;
            }
        }),
        transitioningOut: (0, _emberMetal.computed)('active', 'willBeActive', function computeLinkToComponentTransitioningOut() {
            if ((0, _emberMetal.get)(this, 'willBeActive') === false && (0, _emberMetal.get)(this, '_active')) {
                return 'ember-transitioning-out';
            } else {
                return false;
            }
        }),
        _invoke: function (event) {
            if (!(0, _emberViews.isSimpleClick)(event)) {
                return true;
            }
            var preventDefault = (0, _emberMetal.get)(this, 'preventDefault');
            var targetAttribute = (0, _emberMetal.get)(this, 'target');
            if (preventDefault !== false) {
                if (!targetAttribute || targetAttribute === '_self') {
                    event.preventDefault();
                }
            }
            if ((0, _emberMetal.get)(this, 'bubbles') === false) {
                event.stopPropagation();
            }
            if (this._isDisabled) {
                return false;
            }
            if ((0, _emberMetal.get)(this, 'loading')) {
                (true && (0, _debug.warn)('This link-to is in an inactive loading state because at least one of its parameters presently has a null/undefined value, or the provided route name is invalid.', false, {
                    id: 'ember-glimmer.link-to.inactive-loading-state'
                }));

                return false;
            }
            if (targetAttribute && targetAttribute !== '_self') {
                return false;
            }
            var qualifiedRouteName = (0, _emberMetal.get)(this, 'qualifiedRouteName');
            var models = (0, _emberMetal.get)(this, 'models');
            var queryParams = (0, _emberMetal.get)(this, 'queryParams.values');
            var shouldReplace = (0, _emberMetal.get)(this, 'replace');
            var payload = {
                queryParams: queryParams,
                routeName: qualifiedRouteName
            };
            // tslint:disable-next-line:max-line-length
            (0, _instrumentation.flaggedInstrument)('interaction.link-to', payload, this._generateTransition(payload, qualifiedRouteName, models, queryParams, shouldReplace));
            return false;
        },
        _generateTransition: function (payload, qualifiedRouteName, models, queryParams, shouldReplace) {
            var routing = (0, _emberMetal.get)(this, '_routing');
            return function () {
                payload.transition = routing.transitionTo(qualifiedRouteName, models, queryParams, shouldReplace);
            };
        },

        queryParams: null,
        qualifiedRouteName: (0, _emberMetal.computed)('targetRouteName', '_routing.currentState', function computeLinkToComponentQualifiedRouteName() {
            var params = (0, _emberMetal.get)(this, 'params');
            var paramsLength = params.length;
            var lastParam = params[paramsLength - 1];
            if (lastParam && lastParam.isQueryParams) {
                paramsLength--;
            }
            var onlyQueryParamsSupplied = this[HAS_BLOCK] ? paramsLength === 0 : paramsLength === 1;
            if (onlyQueryParamsSupplied) {
                return (0, _emberMetal.get)(this, '_routing.currentRouteName');
            }
            return (0, _emberMetal.get)(this, 'targetRouteName');
        }),
        resolvedQueryParams: (0, _emberMetal.computed)('queryParams', function computeLinkToComponentResolvedQueryParams() {
            var resolvedQueryParams = {};
            var queryParams = (0, _emberMetal.get)(this, 'queryParams');
            if (!queryParams) {
                return resolvedQueryParams;
            }
            var values = queryParams.values;
            for (var key in values) {
                if (!values.hasOwnProperty(key)) {
                    continue;
                }
                resolvedQueryParams[key] = values[key];
            }
            return resolvedQueryParams;
        }),
        /**
          Sets the element's `href` attribute to the url for
          the `LinkComponent`'s targeted route.
             If the `LinkComponent`'s `tagName` is changed to a value other
          than `a`, this property will be ignored.
             @property href
          @private
        */
        href: (0, _emberMetal.computed)('models', 'qualifiedRouteName', function computeLinkToComponentHref() {
            if ((0, _emberMetal.get)(this, 'tagName') !== 'a') {
                return;
            }
            var qualifiedRouteName = (0, _emberMetal.get)(this, 'qualifiedRouteName');
            var models = (0, _emberMetal.get)(this, 'models');
            if ((0, _emberMetal.get)(this, 'loading')) {
                return (0, _emberMetal.get)(this, 'loadingHref');
            }
            var routing = (0, _emberMetal.get)(this, '_routing');
            var queryParams = (0, _emberMetal.get)(this, 'queryParams.values');
            if (true) {
                /*
                 * Unfortunately, to get decent error messages, we need to do this.
                 * In some future state we should be able to use a "feature flag"
                 * which allows us to strip this without needing to call it twice.
                 *
                 * if (isDebugBuild()) {
                 *   // Do the useful debug thing, probably including try/catch.
                 * } else {
                 *   // Do the performant thing.
                 * }
                 */
                try {
                    routing.generateURL(qualifiedRouteName, models, queryParams);
                } catch (e) {
                    (true && !(false) && (0, _debug.assert)('You attempted to define a `{{link-to "' + qualifiedRouteName + '"}}` but did not pass the parameters required for generating its dynamic segments. ' + e.message));
                }
            }
            return routing.generateURL(qualifiedRouteName, models, queryParams);
        }),
        loading: (0, _emberMetal.computed)('_modelsAreLoaded', 'qualifiedRouteName', function computeLinkToComponentLoading() {
            var qualifiedRouteName = (0, _emberMetal.get)(this, 'qualifiedRouteName');
            var modelsAreLoaded = (0, _emberMetal.get)(this, '_modelsAreLoaded');
            if (!modelsAreLoaded || qualifiedRouteName === null || qualifiedRouteName === undefined) {
                return (0, _emberMetal.get)(this, 'loadingClass');
            }
        }),
        _modelsAreLoaded: (0, _emberMetal.computed)('models', function computeLinkToComponentModelsAreLoaded() {
            var models = (0, _emberMetal.get)(this, 'models');
            for (var i = 0; i < models.length; i++) {
                var model = models[i];
                if (model === null || model === undefined) {
                    return false;
                }
            }
            return true;
        }),
        _getModels: function (params) {
            var modelCount = params.length - 1;
            var models = new Array(modelCount);
            for (var i = 0; i < modelCount; i++) {
                var value = params[i + 1];
                models[i] = value;
            }
            return models;
        },

        /**
          The default href value to use while a link-to is loading.
          Only applies when tagName is 'a'
             @property loadingHref
          @type String
          @default #
          @private
        */
        loadingHref: '#',
        didReceiveAttrs: function () {
            var queryParams = void 0;
            var params = (0, _emberMetal.get)(this, 'params');
            if (params) {
                // Do not mutate params in place
                params = params.slice();
            }
            (true && !(params && params.length) && (0, _debug.assert)('You must provide one or more parameters to the link-to component.', params && params.length));

            var disabledWhen = (0, _emberMetal.get)(this, 'disabledWhen');
            if (disabledWhen !== undefined) {
                this.set('disabled', disabledWhen);
            }
            // Process the positional arguments, in order.
            // 1. Inline link title comes first, if present.
            if (!this[HAS_BLOCK]) {
                this.set('linkTitle', params.shift());
            }
            // 2. `targetRouteName` is now always at index 0.
            this.set('targetRouteName', params[0]);
            // 3. The last argument (if still remaining) is the `queryParams` object.
            var lastParam = params[params.length - 1];
            if (lastParam && lastParam.isQueryParams) {
                queryParams = params.pop();
            } else {
                queryParams = { values: {} };
            }
            this.set('queryParams', queryParams);
            // 4. Any remaining indices (excepting `targetRouteName` at 0) are `models`.
            if (params.length > 1) {
                this.set('models', this._getModels(params));
            } else {
                this.set('models', []);
            }
        }
    });
    LinkComponent.toString = function () {
        return '@ember/routing/link-component';
    };
    LinkComponent.reopenClass({
        positionalParams: 'params'
    });

    // @ts-check
    var DebugStack = void 0;
    if (true) {
        var Element = function Element(name) {
            (0, _emberBabel.classCallCheck)(this, Element);

            this.name = name;
        };

        var TemplateElement = function (_Element) {
            (0, _emberBabel.inherits)(TemplateElement, _Element);

            function TemplateElement() {
                (0, _emberBabel.classCallCheck)(this, TemplateElement);
                return (0, _emberBabel.possibleConstructorReturn)(this, _Element.apply(this, arguments));
            }

            return TemplateElement;
        }(Element);

        var EngineElement = function (_Element2) {
            (0, _emberBabel.inherits)(EngineElement, _Element2);

            function EngineElement() {
                (0, _emberBabel.classCallCheck)(this, EngineElement);
                return (0, _emberBabel.possibleConstructorReturn)(this, _Element2.apply(this, arguments));
            }

            return EngineElement;
        }(Element);

        // tslint:disable-next-line:no-shadowed-variable
        DebugStack = function () {
            function DebugStack() {
                (0, _emberBabel.classCallCheck)(this, DebugStack);

                this._stack = [];
            }

            DebugStack.prototype.push = function push(name) {
                this._stack.push(new TemplateElement(name));
            };

            DebugStack.prototype.pushEngine = function pushEngine(name) {
                this._stack.push(new EngineElement(name));
            };

            DebugStack.prototype.pop = function pop() {
                var element = this._stack.pop();
                if (element) {
                    return element.name;
                }
            };

            DebugStack.prototype.peek = function peek() {
                var template = this._currentTemplate();
                var engine = this._currentEngine();
                if (engine) {
                    return '"' + template + '" (in "' + engine + '")';
                } else if (template) {
                    return '"' + template + '"';
                }
            };

            DebugStack.prototype._currentTemplate = function _currentTemplate() {
                return this._getCurrentByType(TemplateElement);
            };

            DebugStack.prototype._currentEngine = function _currentEngine() {
                return this._getCurrentByType(EngineElement);
            };

            DebugStack.prototype._getCurrentByType = function _getCurrentByType(type) {
                for (var i = this._stack.length; i >= 0; i--) {
                    var element = this._stack[i];
                    if (element instanceof type) {
                        return element.name;
                    }
                }
            };

            return DebugStack;
        }();
    }
    var DebugStack$1 = DebugStack;

    /**
      The `{{#each}}` helper loops over elements in a collection. It is an extension
      of the base Handlebars `{{#each}}` helper.
      The default behavior of `{{#each}}` is to yield its inner block once for every
      item in an array passing the item as the first block parameter.
    
      ```javascript
      var developers = [{ name: 'Yehuda' },{ name: 'Tom' }, { name: 'Paul' }];
      ```
    
      ```handlebars
      {{#each developers key="name" as |person|}}
        {{person.name}}
        {{! `this` is whatever it was outside the #each }}
      {{/each}}
      ```
    
      The same rules apply to arrays of primitives.
    
      ```javascript
      var developerNames = ['Yehuda', 'Tom', 'Paul']
      ```
    
      ```handlebars
      {{#each developerNames key="@index" as |name|}}
        {{name}}
      {{/each}}
      ```
    
      During iteration, the index of each item in the array is provided as a second block parameter.
    
      ```handlebars
      <ul>
        {{#each people as |person index|}}
          <li>Hello, {{person.name}}! You're number {{index}} in line</li>
        {{/each}}
      </ul>
      ```
    
      ### Specifying Keys
    
      The `key` option is used to tell Ember how to determine if the array being
      iterated over with `{{#each}}` has changed between renders. By helping Ember
      detect that some elements in the array are the same, DOM elements can be
      re-used, significantly improving rendering speed.
    
      For example, here's the `{{#each}}` helper with its `key` set to `id`:
    
      ```handlebars
      {{#each model key="id" as |item|}}
      {{/each}}
      ```
    
      When this `{{#each}}` re-renders, Ember will match up the previously rendered
      items (and reorder the generated DOM elements) based on each item's `id`
      property.
      By default the item's own reference is used.
    
      ### {{else}} condition
    
      `{{#each}}` can have a matching `{{else}}`. The contents of this block will render
      if the collection is empty.
    
      ```handlebars
      {{#each developers as |person|}}
        {{person.name}}
      {{else}}
        <p>Sorry, nobody is available for this task.</p>
      {{/each}}
      ```
    
      @method each
      @for Ember.Templates.helpers
      @public
     */
    /**
      The `{{each-in}}` helper loops over properties on an object.
    
      For example, given a `user` object that looks like:
    
      ```javascript
      {
        "name": "Shelly Sails",
        "age": 42
      }
      ```
    
      This template would display all properties on the `user`
      object in a list:
    
      ```handlebars
      <ul>
      {{#each-in user as |key value|}}
        <li>{{key}}: {{value}}</li>
      {{/each-in}}
      </ul>
      ```
    
      Outputting their name and age.
    
      @method each-in
      @for Ember.Templates.helpers
      @public
      @since 2.1.0
    */
    var EACH_IN_REFERENCE = (0, _emberUtils.symbol)('EACH_IN');

    var EachInReference = function () {
        function EachInReference(inner) {
            (0, _emberBabel.classCallCheck)(this, EachInReference);

            this.inner = inner;
            this.tag = inner.tag;
            this[EACH_IN_REFERENCE] = true;
        }

        EachInReference.prototype.value = function value() {
            return this.inner.value();
        };

        EachInReference.prototype.get = function get(key) {
            return this.inner.get(key);
        };

        return EachInReference;
    }();

    function isEachIn(ref) {
        return ref !== null && typeof ref === 'object' && ref[EACH_IN_REFERENCE];
    }
    function eachIn(_vm, args) {
        return new EachInReference(args.positional.at(0));
    }

    var ITERATOR_KEY_GUID = 'be277757-bbbe-4620-9fcb-213ef433cca2';
    function _iterableFor(ref, keyPath) {
        if (isEachIn(ref)) {
            return new EachInIterable(ref, keyPath || '@key');
        } else {
            return new EachIterable(ref, keyPath || '@identity');
        }
    }

    var BoundedIterator = function () {
        function BoundedIterator(length, keyFor) {
            (0, _emberBabel.classCallCheck)(this, BoundedIterator);

            this.length = length;
            this.keyFor = keyFor;
            this.position = 0;
        }

        BoundedIterator.prototype.isEmpty = function isEmpty() {
            return false;
        };

        BoundedIterator.prototype.memoFor = function memoFor(position) {
            return position;
        };

        BoundedIterator.prototype.next = function next() {
            var length = this.length,
                keyFor = this.keyFor,
                position = this.position;

            if (position >= length) {
                return null;
            }
            var value = this.valueFor(position);
            var memo = this.memoFor(position);
            var key = keyFor(value, memo, position);
            this.position++;
            return { key: key, value: value, memo: memo };
        };

        return BoundedIterator;
    }();

    var ArrayIterator = function (_BoundedIterator) {
        (0, _emberBabel.inherits)(ArrayIterator, _BoundedIterator);

        function ArrayIterator(array, length, keyFor) {
            (0, _emberBabel.classCallCheck)(this, ArrayIterator);

            var _this16 = (0, _emberBabel.possibleConstructorReturn)(this, _BoundedIterator.call(this, length, keyFor));

            _this16.array = array;
            return _this16;
        }

        ArrayIterator.from = function from(array, keyFor) {
            var length = array.length;

            if (length === 0) {
                return EMPTY_ITERATOR;
            } else {
                return new this(array, length, keyFor);
            }
        };

        ArrayIterator.fromForEachable = function fromForEachable(object, keyFor) {
            var array = [];
            object.forEach(function (item) {
                return array.push(item);
            });
            return this.from(array, keyFor);
        };

        ArrayIterator.prototype.valueFor = function valueFor(position) {
            return this.array[position];
        };

        return ArrayIterator;
    }(BoundedIterator);

    var EmberArrayIterator = function (_BoundedIterator2) {
        (0, _emberBabel.inherits)(EmberArrayIterator, _BoundedIterator2);

        function EmberArrayIterator(array, length, keyFor) {
            (0, _emberBabel.classCallCheck)(this, EmberArrayIterator);

            var _this17 = (0, _emberBabel.possibleConstructorReturn)(this, _BoundedIterator2.call(this, length, keyFor));

            _this17.array = array;
            return _this17;
        }

        EmberArrayIterator.from = function from(array, keyFor) {
            var length = array.length;

            if (length === 0) {
                return EMPTY_ITERATOR;
            } else {
                return new this(array, length, keyFor);
            }
        };

        EmberArrayIterator.prototype.valueFor = function valueFor(position) {
            return (0, _emberMetal.objectAt)(this.array, position);
        };

        return EmberArrayIterator;
    }(BoundedIterator);

    var ObjectIterator = function (_BoundedIterator3) {
        (0, _emberBabel.inherits)(ObjectIterator, _BoundedIterator3);

        function ObjectIterator(keys, values, length, keyFor) {
            (0, _emberBabel.classCallCheck)(this, ObjectIterator);

            var _this18 = (0, _emberBabel.possibleConstructorReturn)(this, _BoundedIterator3.call(this, length, keyFor));

            _this18.keys = keys;
            _this18.values = values;
            return _this18;
        }

        ObjectIterator.fromIndexable = function fromIndexable(obj, keyFor) {
            var keys = Object.keys(obj);
            var values = [];
            var length = keys.length;

            for (var i = 0; i < length; i++) {
                values.push((0, _emberMetal.get)(obj, keys[i]));
            }
            if (length === 0) {
                return EMPTY_ITERATOR;
            } else {
                return new this(keys, values, length, keyFor);
            }
        };

        ObjectIterator.fromForEachable = function fromForEachable(obj, keyFor) {
            var _arguments = arguments;

            var keys = [];
            var values = [];
            var length = 0;
            var isMapLike = false;
            obj.forEach(function (value, key) {
                isMapLike = isMapLike || _arguments.length >= 2;
                if (isMapLike) {
                    keys.push(key);
                }
                values.push(value);
                length++;
            });
            if (length === 0) {
                return EMPTY_ITERATOR;
            } else if (isMapLike) {
                return new this(keys, values, length, keyFor);
            } else {
                return new ArrayIterator(values, length, keyFor);
            }
        };

        ObjectIterator.prototype.valueFor = function valueFor(position) {
            return this.values[position];
        };

        ObjectIterator.prototype.memoFor = function memoFor(position) {
            return this.keys[position];
        };

        return ObjectIterator;
    }(BoundedIterator);

    var NativeIterator = function () {
        function NativeIterator(iterable, result, keyFor) {
            (0, _emberBabel.classCallCheck)(this, NativeIterator);

            this.iterable = iterable;
            this.result = result;
            this.keyFor = keyFor;
            this.position = 0;
        }

        NativeIterator.from = function from(iterable, keyFor) {
            var iterator = iterable[Symbol.iterator]();
            var result = iterator.next();
            var value = result.value,
                done = result.done;

            if (done) {
                return EMPTY_ITERATOR;
            } else if (Array.isArray(value) && value.length === 2) {
                return new this(iterator, result, keyFor);
            } else {
                return new ArrayLikeNativeIterator(iterator, result, keyFor);
            }
        };

        NativeIterator.prototype.isEmpty = function isEmpty() {
            return false;
        };

        NativeIterator.prototype.next = function next() {
            var iterable = this.iterable,
                result = this.result,
                position = this.position,
                keyFor = this.keyFor;

            if (result.done) {
                return null;
            }
            var value = this.valueFor(result, position);
            var memo = this.memoFor(result, position);
            var key = keyFor(value, memo, position);
            this.position++;
            this.result = iterable.next();
            return { key: key, value: value, memo: memo };
        };

        return NativeIterator;
    }();

    var ArrayLikeNativeIterator = function (_NativeIterator) {
        (0, _emberBabel.inherits)(ArrayLikeNativeIterator, _NativeIterator);

        function ArrayLikeNativeIterator() {
            (0, _emberBabel.classCallCheck)(this, ArrayLikeNativeIterator);
            return (0, _emberBabel.possibleConstructorReturn)(this, _NativeIterator.apply(this, arguments));
        }

        ArrayLikeNativeIterator.prototype.valueFor = function valueFor(result) {
            return result.value;
        };

        ArrayLikeNativeIterator.prototype.memoFor = function memoFor(_result, position) {
            return position;
        };

        return ArrayLikeNativeIterator;
    }(NativeIterator);

    var MapLikeNativeIterator = function (_NativeIterator2) {
        (0, _emberBabel.inherits)(MapLikeNativeIterator, _NativeIterator2);

        function MapLikeNativeIterator() {
            (0, _emberBabel.classCallCheck)(this, MapLikeNativeIterator);
            return (0, _emberBabel.possibleConstructorReturn)(this, _NativeIterator2.apply(this, arguments));
        }

        MapLikeNativeIterator.prototype.valueFor = function valueFor(result) {
            return result.value[1];
        };

        MapLikeNativeIterator.prototype.memoFor = function memoFor(result) {
            return result.value[0];
        };

        return MapLikeNativeIterator;
    }(NativeIterator);

    var EMPTY_ITERATOR = {
        isEmpty: function () {
            return true;
        },
        next: function () {
            (true && !(false) && (0, _debug.assert)('Cannot call next() on an empty iterator'));

            return null;
        }
    };

    var EachInIterable = function () {
        function EachInIterable(ref, keyPath) {
            (0, _emberBabel.classCallCheck)(this, EachInIterable);

            this.ref = ref;
            this.keyPath = keyPath;
            this.valueTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
            this.tag = (0, _reference.combine)([ref.tag, this.valueTag]);
        }

        EachInIterable.prototype.iterate = function iterate() {
            var ref = this.ref,
                valueTag = this.valueTag;

            var iterable = ref.value();
            var tag = (0, _emberMetal.tagFor)(iterable);
            if ((0, _emberUtils.isProxy)(iterable)) {
                // this is because the each-in doesn't actually get(proxy, 'key') but bypasses it
                // and the proxy's tag is lazy updated on access
                iterable = (0, _emberRuntime._contentFor)(iterable);
            }
            valueTag.inner.update(tag);
            if (!isIndexable(iterable)) {
                return EMPTY_ITERATOR;
            }
            if (Array.isArray(iterable) || (0, _emberRuntime.isEmberArray)(iterable)) {
                return ObjectIterator.fromIndexable(iterable, this.keyFor(true));
            } else if (_emberUtils.HAS_NATIVE_SYMBOL && isNativeIterable(iterable)) {
                return MapLikeNativeIterator.from(iterable, this.keyFor());
            } else if (hasForEach(iterable)) {
                return ObjectIterator.fromForEachable(iterable, this.keyFor());
            } else {
                return ObjectIterator.fromIndexable(iterable, this.keyFor(true));
            }
        };

        EachInIterable.prototype.valueReferenceFor = function valueReferenceFor(item) {
            return new UpdatableReference(item.value);
        };

        EachInIterable.prototype.updateValueReference = function updateValueReference(ref, item) {
            ref.update(item.value);
        };

        EachInIterable.prototype.memoReferenceFor = function memoReferenceFor(item) {
            return new UpdatableReference(item.memo);
        };

        EachInIterable.prototype.updateMemoReference = function updateMemoReference(ref, item) {
            ref.update(item.memo);
        };

        EachInIterable.prototype.keyFor = function keyFor() {
            var hasUniqueKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
            var keyPath = this.keyPath;

            switch (keyPath) {
                case '@key':
                    return hasUniqueKeys ? ObjectKey : Unique(MapKey);
                case '@index':
                    return Index;
                case '@identity':
                    return Unique(Identity);
                default:
                    (true && !(keyPath[0] !== '@') && (0, _debug.assert)('Invalid key: ' + keyPath, keyPath[0] !== '@'));

                    return Unique(KeyPath(keyPath));
            }
        };

        return EachInIterable;
    }();

    var EachIterable = function () {
        function EachIterable(ref, keyPath) {
            (0, _emberBabel.classCallCheck)(this, EachIterable);

            this.ref = ref;
            this.keyPath = keyPath;
            this.valueTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
            this.tag = (0, _reference.combine)([ref.tag, this.valueTag]);
        }

        EachIterable.prototype.iterate = function iterate() {
            var ref = this.ref,
                valueTag = this.valueTag;

            var iterable = ref.value();
            valueTag.inner.update((0, _emberMetal.tagForProperty)(iterable, '[]'));
            if (iterable === null || typeof iterable !== 'object') {
                return EMPTY_ITERATOR;
            }
            var keyFor = this.keyFor();
            if (Array.isArray(iterable)) {
                return ArrayIterator.from(iterable, keyFor);
            } else if ((0, _emberRuntime.isEmberArray)(iterable)) {
                return EmberArrayIterator.from(iterable, keyFor);
            } else if (_emberUtils.HAS_NATIVE_SYMBOL && isNativeIterable(iterable)) {
                return ArrayLikeNativeIterator.from(iterable, keyFor);
            } else if (hasForEach(iterable)) {
                return ArrayIterator.fromForEachable(iterable, keyFor);
            } else {
                return EMPTY_ITERATOR;
            }
        };

        EachIterable.prototype.valueReferenceFor = function valueReferenceFor(item) {
            return new UpdatableReference(item.value);
        };

        EachIterable.prototype.updateValueReference = function updateValueReference(ref, item) {
            ref.update(item.value);
        };

        EachIterable.prototype.memoReferenceFor = function memoReferenceFor(item) {
            return new UpdatableReference(item.memo);
        };

        EachIterable.prototype.updateMemoReference = function updateMemoReference(ref, item) {
            ref.update(item.memo);
        };

        EachIterable.prototype.keyFor = function keyFor() {
            var keyPath = this.keyPath;

            switch (keyPath) {
                case '@index':
                    return Index;
                case '@identity':
                    return Unique(Identity);
                default:
                    (true && !(keyPath[0] !== '@') && (0, _debug.assert)('Invalid key: ' + keyPath, keyPath[0] !== '@'));

                    return Unique(KeyPath(keyPath));
            }
        };

        return EachIterable;
    }();

    function hasForEach(value) {
        return typeof value['forEach'] === 'function';
    }
    function isNativeIterable(value) {
        return typeof value[Symbol.iterator] === 'function';
    }
    function isIndexable(value) {
        return value !== null && (typeof value === 'object' || typeof value === 'function');
    }
    // Position in an array is guarenteed to be unique
    function Index(_value, _memo, position) {
        return String(position);
    }
    // Object.keys(...) is guarenteed to be strings and unique
    function ObjectKey(_value, memo) {
        return memo;
    }
    // Map keys can be any objects
    function MapKey(_value, memo) {
        return Identity(memo);
    }
    function Identity(value) {
        switch (typeof value) {
            case 'string':
                return value;
            case 'number':
                return String(value);
            default:
                return (0, _emberUtils.guidFor)(value);
        }
    }
    function KeyPath(keyPath) {
        return function (value) {
            return String((0, _emberMetal.get)(value, keyPath));
        };
    }
    function Unique(func) {
        var seen = {};
        return function (value, memo, position) {
            var key = func(value, memo, position);
            var count = seen[key];
            if (count === undefined) {
                seen[key] = 0;
                return key;
            } else {
                seen[key] = ++count;
                return '' + key + ITERATOR_KEY_GUID + count;
            }
        };
    }

    /**
    @module @ember/string
    */

    var SafeString = function () {
        function SafeString(string) {
            (0, _emberBabel.classCallCheck)(this, SafeString);

            this.string = string;
        }

        SafeString.prototype.toString = function toString() {
            return '' + this.string;
        };

        SafeString.prototype.toHTML = function toHTML() {
            return this.toString();
        };

        return SafeString;
    }();

    var escape = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '`': '&#x60;',
        '=': '&#x3D;'
    };
    var possible = /[&<>"'`=]/;
    var badChars = /[&<>"'`=]/g;
    function escapeChar(chr) {
        return escape[chr];
    }
    function escapeExpression(string) {
        if (typeof string !== 'string') {
            // don't escape SafeStrings, since they're already safe
            if (string && string.toHTML) {
                return string.toHTML();
            } else if (string === null || string === undefined) {
                return '';
            } else if (!string) {
                return string + '';
            }
            // Force a string conversion as this will be done by the append regardless and
            // the regex test will do this transparently behind the scenes, causing issues if
            // an object's to string has escaped characters in it.
            string = '' + string;
        }
        if (!possible.test(string)) {
            return string;
        }
        return string.replace(badChars, escapeChar);
    }
    /**
      Mark a string as safe for unescaped output with Ember templates. If you
      return HTML from a helper, use this function to
      ensure Ember's rendering layer does not escape the HTML.
    
      ```javascript
      import { htmlSafe } from '@ember/string';
    
      htmlSafe('<div>someString</div>')
      ```
    
      @method htmlSafe
      @for @ember/template
      @static
      @return {Handlebars.SafeString} A string that will not be HTML escaped by Handlebars.
      @public
    */
    function htmlSafe(str) {
        if (str === null || str === undefined) {
            str = '';
        } else if (typeof str !== 'string') {
            str = '' + str;
        }
        return new SafeString(str);
    }
    /**
      Detects if a string was decorated using `htmlSafe`.
    
      ```javascript
      import { htmlSafe, isHTMLSafe } from '@ember/string';
    
      var plainString = 'plain string',
          safeString = htmlSafe('<div>someValue</div>');
    
      isHTMLSafe(plainString); // false
      isHTMLSafe(safeString);  // true
      ```
    
      @method isHTMLSafe
      @for @ember/template
      @static
      @return {Boolean} `true` if the string was decorated with `htmlSafe`, `false` otherwise.
      @public
    */
    function isHTMLSafe(str) {
        return str !== null && typeof str === 'object' && typeof str.toHTML === 'function';
    }

    /* globals module, URL */
    var nodeURL = void 0;
    var parsingNode = void 0;
    function installProtocolForURL(environment) {
        var protocol = void 0;
        if (_emberBrowserEnvironment.hasDOM) {
            protocol = browserProtocolForURL.call(environment, 'foobar:baz');
        }
        // Test to see if our DOM implementation parses
        // and normalizes URLs.
        if (protocol === 'foobar:') {
            // Swap in the method that doesn't do this test now that
            // we know it works.
            environment.protocolForURL = browserProtocolForURL;
        } else if (typeof URL === 'object') {
            // URL globally provided, likely from FastBoot's sandbox
            nodeURL = URL;
            environment.protocolForURL = nodeProtocolForURL;
        } else if (_nodeModule.IS_NODE) {
            // Otherwise, we need to fall back to our own URL parsing.
            // Global `require` is shadowed by Ember's loader so we have to use the fully
            // qualified `module.require`.
            // tslint:disable-next-line:no-require-imports
            nodeURL = (0, _nodeModule.require)('url');
            environment.protocolForURL = nodeProtocolForURL;
        } else {
            throw new Error('Could not find valid URL parsing mechanism for URL Sanitization');
        }
    }
    function browserProtocolForURL(url) {
        if (!parsingNode) {
            parsingNode = document.createElement('a');
        }
        parsingNode.href = url;
        return parsingNode.protocol;
    }
    function nodeProtocolForURL(url) {
        var protocol = null;
        if (typeof url === 'string') {
            protocol = nodeURL.parse(url).protocol;
        }
        return protocol === null ? ':' : protocol;
    }

    var Environment$1 = function (_Environment) {
        (0, _emberBabel.inherits)(Environment$1, _Environment);

        function Environment$1(injections) {
            (0, _emberBabel.classCallCheck)(this, Environment$1);

            var _this21 = (0, _emberBabel.possibleConstructorReturn)(this, _Environment.call(this, injections));

            _this21.inTransaction = false;
            _this21.owner = injections[_emberOwner.OWNER];
            _this21.isInteractive = _this21.owner.lookup('-environment:main').isInteractive;
            // can be removed once https://github.com/tildeio/glimmer/pull/305 lands
            _this21.destroyedComponents = [];
            installProtocolForURL(_this21);
            if (true) {
                _this21.debugStack = new DebugStack$1();
            }
            return _this21;
        }

        Environment$1.create = function create(options) {
            return new this(options);
        };

        Environment$1.prototype.protocolForURL = function protocolForURL(s) {
            return s;
        };

        Environment$1.prototype.lookupComponent = function lookupComponent(name, meta) {
            return (0, _emberViews.lookupComponent)(meta.owner, name, meta);
        };

        Environment$1.prototype.toConditionalReference = function toConditionalReference(reference) {
            return ConditionalReference$1.create(reference);
        };

        Environment$1.prototype.iterableFor = function iterableFor(ref, key) {
            return _iterableFor(ref, key);
        };

        Environment$1.prototype.scheduleInstallModifier = function scheduleInstallModifier(modifier, manager) {
            if (this.isInteractive) {
                _Environment.prototype.scheduleInstallModifier.call(this, modifier, manager);
            }
        };

        Environment$1.prototype.scheduleUpdateModifier = function scheduleUpdateModifier(modifier, manager) {
            if (this.isInteractive) {
                _Environment.prototype.scheduleUpdateModifier.call(this, modifier, manager);
            }
        };

        Environment$1.prototype.didDestroy = function didDestroy(destroyable) {
            destroyable.destroy();
        };

        Environment$1.prototype.begin = function begin() {
            this.inTransaction = true;
            _Environment.prototype.begin.call(this);
        };

        Environment$1.prototype.commit = function commit() {
            var destroyedComponents = this.destroyedComponents;
            this.destroyedComponents = [];
            // components queued for destruction must be destroyed before firing
            // `didCreate` to prevent errors when removing and adding a component
            // with the same name (would throw an error when added to view registry)
            for (var i = 0; i < destroyedComponents.length; i++) {
                destroyedComponents[i].destroy();
            }
            try {
                _Environment.prototype.commit.call(this);
            } finally {
                this.inTransaction = false;
            }
        };

        return Environment$1;
    }(_runtime.Environment);

    if (true) {
        var StyleAttributeManager = function (_SimpleDynamicAttribu) {
            (0, _emberBabel.inherits)(StyleAttributeManager, _SimpleDynamicAttribu);

            function StyleAttributeManager() {
                (0, _emberBabel.classCallCheck)(this, StyleAttributeManager);
                return (0, _emberBabel.possibleConstructorReturn)(this, _SimpleDynamicAttribu.apply(this, arguments));
            }

            StyleAttributeManager.prototype.set = function set(dom, value, env) {
                (true && (0, _debug.warn)((0, _emberViews.constructStyleDeprecationMessage)(value), function () {
                    if (value === null || value === undefined || isHTMLSafe(value)) {
                        return true;
                    }
                    return false;
                }(), { id: 'ember-htmlbars.style-xss-warning' }));

                _SimpleDynamicAttribu.prototype.set.call(this, dom, value, env);
            };

            StyleAttributeManager.prototype.update = function update(value, env) {
                (true && (0, _debug.warn)((0, _emberViews.constructStyleDeprecationMessage)(value), function () {
                    if (value === null || value === undefined || isHTMLSafe(value)) {
                        return true;
                    }
                    return false;
                }(), { id: 'ember-htmlbars.style-xss-warning' }));

                _SimpleDynamicAttribu.prototype.update.call(this, value, env);
            };

            return StyleAttributeManager;
        }(_runtime.SimpleDynamicAttribute);

        Environment$1.prototype.attributeFor = function (element, attribute, isTrusting, namespace) {
            if (attribute === 'style' && !isTrusting) {
                return new StyleAttributeManager({ element: element, name: attribute, namespace: namespace });
            }
            return _runtime.Environment.prototype.attributeFor.call(this, element, attribute, isTrusting, namespace);
        };
    }

    // implements the ComponentManager interface as defined in glimmer:
    // tslint:disable-next-line:max-line-length
    // https://github.com/glimmerjs/glimmer-vm/blob/v0.24.0-beta.4/packages/%40glimmer/runtime/lib/component/interfaces.ts#L21

    var AbstractManager = function () {
        function AbstractManager() {
            (0, _emberBabel.classCallCheck)(this, AbstractManager);

            this.debugStack = undefined;
        }

        AbstractManager.prototype.prepareArgs = function prepareArgs(_state, _args) {
            return null;
        };

        AbstractManager.prototype.didCreateElement = function didCreateElement(_component, _element, _operations) {}
        // noop

        // inheritors should also call `this.debugStack.pop()` to
        // ensure the rerendering assertion messages are properly
        // maintained
        ;

        AbstractManager.prototype.didRenderLayout = function didRenderLayout(_component, _bounds) {
            // noop
        };

        AbstractManager.prototype.didCreate = function didCreate(_bucket) {}
        // noop

        // inheritors should also call `this._pushToDebugStack`
        // to ensure the rerendering assertion messages are
        // properly maintained
        ;

        AbstractManager.prototype.update = function update(_bucket, _dynamicScope) {}
        // noop

        // inheritors should also call `this.debugStack.pop()` to
        // ensure the rerendering assertion messages are properly
        // maintained
        ;

        AbstractManager.prototype.didUpdateLayout = function didUpdateLayout(_bucket, _bounds) {
            // noop
        };

        AbstractManager.prototype.didUpdate = function didUpdate(_bucket) {
            // noop
        };

        return AbstractManager;
    }();

    if (true) {
        AbstractManager.prototype._pushToDebugStack = function (name, environment) {
            this.debugStack = environment.debugStack;
            this.debugStack.push(name);
        };
        AbstractManager.prototype._pushEngineToDebugStack = function (name, environment) {
            this.debugStack = environment.debugStack;
            this.debugStack.pushEngine(name);
        };
    }

    function instrumentationPayload(def) {
        return { object: def.name + ':' + def.outlet };
    }
    var CAPABILITIES = {
        dynamicLayout: false,
        dynamicTag: false,
        prepareArgs: false,
        createArgs: false,
        attributeHook: false,
        elementHook: false,
        createCaller: true,
        dynamicScope: true,
        updateHook: true,
        createInstance: true
    };

    var OutletComponentManager = function (_AbstractManager) {
        (0, _emberBabel.inherits)(OutletComponentManager, _AbstractManager);

        function OutletComponentManager() {
            (0, _emberBabel.classCallCheck)(this, OutletComponentManager);
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
        }

        OutletComponentManager.prototype.create = function create(environment, definition, _args, dynamicScope) {
            if (true) {
                this._pushToDebugStack('template:' + definition.template.referrer.moduleName, environment);
            }
            dynamicScope.outletState = definition.ref;
            // this is only used for render helper which is legacy
            if (dynamicScope.rootOutletState === undefined) {
                dynamicScope.rootOutletState = dynamicScope.outletState;
            }
            var controller = definition.controller;
            var self = controller === undefined ? _runtime.UNDEFINED_REFERENCE : new RootReference(controller);
            return {
                self: self,
                finalize: (0, _instrumentation._instrumentStart)('render.outlet', instrumentationPayload, definition)
            };
        };

        OutletComponentManager.prototype.layoutFor = function layoutFor(_state, _component, _env) {
            throw new Error('Method not implemented.');
        };

        OutletComponentManager.prototype.getLayout = function getLayout(_ref, _resolver) {
            var template = _ref.template;

            // The router has already resolved the template
            var layout = template.asLayout();
            return {
                handle: layout.compile(),
                symbolTable: layout.symbolTable
            };
        };

        OutletComponentManager.prototype.getCapabilities = function getCapabilities() {
            return CAPABILITIES;
        };

        OutletComponentManager.prototype.getSelf = function getSelf(_ref2) {
            var self = _ref2.self;

            return self;
        };

        OutletComponentManager.prototype.getTag = function getTag() {
            // an outlet has no hooks
            return _reference.CONSTANT_TAG;
        };

        OutletComponentManager.prototype.didRenderLayout = function didRenderLayout(state) {
            state.finalize();
            if (true) {
                this.debugStack.pop();
            }
        };

        OutletComponentManager.prototype.getDestructor = function getDestructor() {
            return null;
        };

        return OutletComponentManager;
    }(AbstractManager);

    var OUTLET_MANAGER = new OutletComponentManager();

    var OutletComponentDefinition = function OutletComponentDefinition(state) {
        var manager = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTLET_MANAGER;
        (0, _emberBabel.classCallCheck)(this, OutletComponentDefinition);

        this.state = state;
        this.manager = manager;
    };

    function createRootOutlet(outletView) {
        if (_emberEnvironment.ENV._APPLICATION_TEMPLATE_WRAPPER) {
            var WRAPPED_CAPABILITIES = (0, _polyfills.assign)({}, CAPABILITIES, {
                dynamicTag: true,
                elementHook: true
            });
            var WrappedOutletComponentManager = function (_OutletComponentManag) {
                (0, _emberBabel.inherits)(WrappedOutletComponentManager, _OutletComponentManag);

                function WrappedOutletComponentManager() {
                    (0, _emberBabel.classCallCheck)(this, WrappedOutletComponentManager);
                    return (0, _emberBabel.possibleConstructorReturn)(this, _OutletComponentManag.apply(this, arguments));
                }

                WrappedOutletComponentManager.prototype.getTagName = function getTagName(_component) {
                    return 'div';
                };

                WrappedOutletComponentManager.prototype.getLayout = function getLayout(state) {
                    // The router has already resolved the template
                    var template = state.template;
                    var layout = template.asWrappedLayout();
                    return {
                        handle: layout.compile(),
                        symbolTable: layout.symbolTable
                    };
                };

                WrappedOutletComponentManager.prototype.getCapabilities = function getCapabilities() {
                    return WRAPPED_CAPABILITIES;
                };

                WrappedOutletComponentManager.prototype.didCreateElement = function didCreateElement(component, element, _operations) {
                    // to add GUID id and class
                    element.setAttribute('class', 'ember-view');
                    element.setAttribute('id', (0, _emberUtils.guidFor)(component));
                };

                return WrappedOutletComponentManager;
            }(OutletComponentManager);
            var WRAPPED_OUTLET_MANAGER = new WrappedOutletComponentManager();
            return new OutletComponentDefinition(outletView.state, WRAPPED_OUTLET_MANAGER);
        } else {
            return new OutletComponentDefinition(outletView.state);
        }
    }

    // tslint:disable-next-line:no-empty
    function NOOP() {}
    /**
      @module ember
    */
    /**
      Represents the internal state of the component.
    
      @class ComponentStateBucket
      @private
    */

    var ComponentStateBucket = function () {
        function ComponentStateBucket(environment, component, args, finalizer, hasWrappedElement) {
            (0, _emberBabel.classCallCheck)(this, ComponentStateBucket);

            this.environment = environment;
            this.component = component;
            this.args = args;
            this.finalizer = finalizer;
            this.hasWrappedElement = hasWrappedElement;
            this.classRef = null;
            this.classRef = null;
            this.argsRevision = args === null ? 0 : args.tag.value();
        }

        ComponentStateBucket.prototype.destroy = function destroy() {
            var component = this.component,
                environment = this.environment;

            if (environment.isInteractive) {
                component.trigger('willDestroyElement');
                component.trigger('willClearRender');
            }
            environment.destroyedComponents.push(component);
        };

        ComponentStateBucket.prototype.finalize = function finalize() {
            var finalizer = this.finalizer;

            finalizer();
            this.finalizer = NOOP;
        };

        return ComponentStateBucket;
    }();

    function referenceForKey(component, key) {
        return component[ROOT_REF].get(key);
    }
    function referenceForParts(component, parts) {
        var isAttrs = parts[0] === 'attrs';
        // TODO deprecate this
        if (isAttrs) {
            parts.shift();
            if (parts.length === 1) {
                return referenceForKey(component, parts[0]);
            }
        }
        return referenceFromParts(component[ROOT_REF], parts);
    }
    // TODO we should probably do this transform at build time
    function wrapComponentClassAttribute(hash) {
        if (hash === null) {
            return;
        }
        var keys = hash[0],
            values = hash[1];

        var index = keys === null ? -1 : keys.indexOf('class');
        if (index !== -1) {
            var value = values[index];
            if (!Array.isArray(value)) {
                return;
            }
            var type = value[0];

            if (type === _wireFormat.Ops.Get || type === _wireFormat.Ops.MaybeLocal) {
                var path = value[value.length - 1];
                var propName = path[path.length - 1];
                values[index] = [_wireFormat.Ops.Helper, '-class', [value, propName], null];
            }
        }
    }
    var AttributeBinding = {
        parse: function (microsyntax) {
            var colonIndex = microsyntax.indexOf(':');
            if (colonIndex === -1) {
                (true && !(microsyntax !== 'class') && (0, _debug.assert)('You cannot use class as an attributeBinding, use classNameBindings instead.', microsyntax !== 'class'));

                return [microsyntax, microsyntax, true];
            } else {
                var prop = microsyntax.substring(0, colonIndex);
                var attribute = microsyntax.substring(colonIndex + 1);
                (true && !(attribute !== 'class') && (0, _debug.assert)('You cannot use class as an attributeBinding, use classNameBindings instead.', attribute !== 'class'));

                return [prop, attribute, false];
            }
        },
        install: function (_element, component, parsed, operations) {
            var prop = parsed[0],
                attribute = parsed[1],
                isSimple = parsed[2];

            if (attribute === 'id') {
                var elementId = (0, _emberMetal.get)(component, prop);
                if (elementId === undefined || elementId === null) {
                    elementId = component.elementId;
                }
                elementId = _runtime.PrimitiveReference.create(elementId);
                operations.setAttribute('id', elementId, true, null);
                // operations.addStaticAttribute(element, 'id', elementId);
                return;
            }
            var isPath = prop.indexOf('.') > -1;
            var reference = isPath ? referenceForParts(component, prop.split('.')) : referenceForKey(component, prop);
            (true && !(!(isSimple && isPath)) && (0, _debug.assert)('Illegal attributeBinding: \'' + prop + '\' is not a valid attribute name.', !(isSimple && isPath)));

            if (attribute === 'style') {
                reference = new StyleBindingReference(reference, referenceForKey(component, 'isVisible'));
            }
            operations.setAttribute(attribute, reference, false, null);
            // operations.addDynamicAttribute(element, attribute, reference, false);
        }
    };
    var DISPLAY_NONE = 'display: none;';
    var SAFE_DISPLAY_NONE = htmlSafe(DISPLAY_NONE);

    var StyleBindingReference = function (_CachedReference) {
        (0, _emberBabel.inherits)(StyleBindingReference, _CachedReference);

        function StyleBindingReference(inner, isVisible) {
            (0, _emberBabel.classCallCheck)(this, StyleBindingReference);

            var _this25 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference.call(this));

            _this25.inner = inner;
            _this25.isVisible = isVisible;
            _this25.tag = (0, _reference.combine)([inner.tag, isVisible.tag]);
            return _this25;
        }

        StyleBindingReference.prototype.compute = function compute() {
            var value = this.inner.value();
            var isVisible = this.isVisible.value();
            if (isVisible !== false) {
                return value;
            } else if (!value) {
                return SAFE_DISPLAY_NONE;
            } else {
                var style = value + ' ' + DISPLAY_NONE;
                return isHTMLSafe(value) ? htmlSafe(style) : style;
            }
        };

        return StyleBindingReference;
    }(_reference.CachedReference);

    var IsVisibleBinding = {
        install: function (_element, component, operations) {
            operations.setAttribute('style', (0, _reference.map)(referenceForKey(component, 'isVisible'), this.mapStyleValue), false, null);
            // // the upstream type for addDynamicAttribute's `value` argument
            // // appears to be incorrect. It is currently a Reference<string>, I
            // // think it should be a Reference<string|null>.
            // operations.addDynamicAttribute(element, 'style', ref as any as Reference<string>, false);
        },
        mapStyleValue: function (isVisible) {
            return isVisible === false ? SAFE_DISPLAY_NONE : null;
        }
    };
    var ClassNameBinding = {
        install: function (_element, component, microsyntax, operations) {
            var _microsyntax$split = microsyntax.split(':'),
                prop = _microsyntax$split[0],
                truthy = _microsyntax$split[1],
                falsy = _microsyntax$split[2];

            var isStatic = prop === '';
            if (isStatic) {
                operations.setAttribute('class', _runtime.PrimitiveReference.create(truthy), true, null);
            } else {
                var isPath = prop.indexOf('.') > -1;
                var parts = isPath ? prop.split('.') : [];
                var value = isPath ? referenceForParts(component, parts) : referenceForKey(component, prop);
                var ref = void 0;
                if (truthy === undefined) {
                    ref = new SimpleClassNameBindingReference(value, isPath ? parts[parts.length - 1] : prop);
                } else {
                    ref = new ColonClassNameBindingReference(value, truthy, falsy);
                }
                operations.setAttribute('class', ref, false, null);
                // // the upstream type for addDynamicAttribute's `value` argument
                // // appears to be incorrect. It is currently a Reference<string>, I
                // // think it should be a Reference<string|null>.
                // operations.addDynamicAttribute(element, 'class', ref as any as Reference<string>, false);
            }
        }
    };

    var SimpleClassNameBindingReference = function (_CachedReference2) {
        (0, _emberBabel.inherits)(SimpleClassNameBindingReference, _CachedReference2);

        function SimpleClassNameBindingReference(inner, path) {
            (0, _emberBabel.classCallCheck)(this, SimpleClassNameBindingReference);

            var _this26 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference2.call(this));

            _this26.inner = inner;
            _this26.path = path;
            _this26.tag = inner.tag;
            _this26.inner = inner;
            _this26.path = path;
            _this26.dasherizedPath = null;
            return _this26;
        }

        SimpleClassNameBindingReference.prototype.compute = function compute() {
            var value = this.inner.value();
            if (value === true) {
                var path = this.path,
                    dasherizedPath = this.dasherizedPath;

                return dasherizedPath || (this.dasherizedPath = (0, _string.dasherize)(path));
            } else if (value || value === 0) {
                return String(value);
            } else {
                return null;
            }
        };

        return SimpleClassNameBindingReference;
    }(_reference.CachedReference);

    var ColonClassNameBindingReference = function (_CachedReference3) {
        (0, _emberBabel.inherits)(ColonClassNameBindingReference, _CachedReference3);

        function ColonClassNameBindingReference(inner) {
            var truthy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
            var falsy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
            (0, _emberBabel.classCallCheck)(this, ColonClassNameBindingReference);

            var _this27 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference3.call(this));

            _this27.inner = inner;
            _this27.truthy = truthy;
            _this27.falsy = falsy;
            _this27.tag = inner.tag;
            return _this27;
        }

        ColonClassNameBindingReference.prototype.compute = function compute() {
            var inner = this.inner,
                truthy = this.truthy,
                falsy = this.falsy;

            return inner.value() ? truthy : falsy;
        };

        return ColonClassNameBindingReference;
    }(_reference.CachedReference);

    // ComponentArgs takes EvaluatedNamedArgs and converts them into the
    // inputs needed by CurlyComponents (attrs and props, with mutable
    // cells, etc).
    function processComponentArgs(namedArgs) {
        var keys = namedArgs.names;
        var attrs = namedArgs.value();
        var props = Object.create(null);
        var args = Object.create(null);
        props[ARGS] = args;
        for (var i = 0; i < keys.length; i++) {
            var name = keys[i];
            var ref = namedArgs.get(name);
            var value = attrs[name];
            if (typeof value === 'function' && value[ACTION]) {
                attrs[name] = value;
            } else if (ref[UPDATE]) {
                attrs[name] = new MutableCell(ref, value);
            }
            args[name] = ref;
            props[name] = value;
        }
        props.attrs = attrs;
        return props;
    }
    var REF = (0, _emberUtils.symbol)('REF');

    var MutableCell = function () {
        function MutableCell(ref, value) {
            (0, _emberBabel.classCallCheck)(this, MutableCell);

            this[_emberViews.MUTABLE_CELL] = true;
            this[REF] = ref;
            this.value = value;
        }

        MutableCell.prototype.update = function update(val) {
            this[REF][UPDATE](val);
        };

        return MutableCell;
    }();

    function aliasIdToElementId(args, props) {
        if (args.named.has('id')) {
            (true && !(!args.named.has('elementId')) && (0, _debug.assert)('You cannot invoke a component with both \'id\' and \'elementId\' at the same time.', !args.named.has('elementId')));

            props.elementId = props.id;
        }
    }
    function isTemplateFactory(template) {
        return typeof template.create === 'function';
    }
    // We must traverse the attributeBindings in reverse keeping track of
    // what has already been applied. This is essentially refining the concatenated
    // properties applying right to left.
    function applyAttributeBindings(element, attributeBindings, component, operations) {
        var seen = [];
        var i = attributeBindings.length - 1;
        while (i !== -1) {
            var binding = attributeBindings[i];
            var parsed = AttributeBinding.parse(binding);
            var attribute = parsed[1];
            if (seen.indexOf(attribute) === -1) {
                seen.push(attribute);
                AttributeBinding.install(element, component, parsed, operations);
            }
            i--;
        }
        if (seen.indexOf('id') === -1) {
            var id = component.elementId ? component.elementId : (0, _emberUtils.guidFor)(component);
            operations.setAttribute('id', _runtime.PrimitiveReference.create(id), false, null);
        }
        if (seen.indexOf('style') === -1) {
            IsVisibleBinding.install(element, component, operations);
        }
    }
    var DEFAULT_LAYOUT = (0, _container.privatize)(_templateObject);

    var CurlyComponentManager = function (_AbstractManager2) {
        (0, _emberBabel.inherits)(CurlyComponentManager, _AbstractManager2);

        function CurlyComponentManager() {
            (0, _emberBabel.classCallCheck)(this, CurlyComponentManager);
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager2.apply(this, arguments));
        }

        CurlyComponentManager.prototype.getLayout = function getLayout(state, _resolver) {
            return {
                // TODO fix
                handle: state.handle,
                symbolTable: state.symbolTable
            };
        };

        CurlyComponentManager.prototype.templateFor = function templateFor(component, resolver) {
            var layout = (0, _emberMetal.get)(component, 'layout');
            if (layout !== undefined) {
                // This needs to be cached by template.id
                if (isTemplateFactory(layout)) {
                    return resolver.createTemplate(layout, (0, _emberOwner.getOwner)(component));
                } else {
                    // we were provided an instance already
                    return layout;
                }
            }
            var owner = (0, _emberOwner.getOwner)(component);
            var layoutName = (0, _emberMetal.get)(component, 'layoutName');
            if (layoutName) {
                var _template = owner.lookup('template:' + layoutName);
                if (_template) {
                    return _template;
                }
            }
            return owner.lookup(DEFAULT_LAYOUT);
        };

        CurlyComponentManager.prototype.getDynamicLayout = function getDynamicLayout(_ref3, resolver) {
            var component = _ref3.component;

            var template = this.templateFor(component, resolver);
            var layout = template.asWrappedLayout();
            return {
                handle: layout.compile(),
                symbolTable: layout.symbolTable
            };
        };

        CurlyComponentManager.prototype.getTagName = function getTagName(state) {
            var component = state.component,
                hasWrappedElement = state.hasWrappedElement;

            if (!hasWrappedElement) {
                return null;
            }
            return component && component.tagName || 'div';
        };

        CurlyComponentManager.prototype.getCapabilities = function getCapabilities(state) {
            return state.capabilities;
        };

        CurlyComponentManager.prototype.prepareArgs = function prepareArgs(state, args) {
            var positionalParams = state.ComponentClass.class.positionalParams;

            // early exits
            if (positionalParams === undefined || positionalParams === null || args.positional.length === 0) {
                return null;
            }
            var named = void 0;
            if (typeof positionalParams === 'string') {
                var _named;

                (true && !(!args.named.has(positionalParams)) && (0, _debug.assert)('You cannot specify positional parameters and the hash argument `' + positionalParams + '`.', !args.named.has(positionalParams)));

                named = (_named = {}, _named[positionalParams] = args.positional.capture(), _named);
                (0, _polyfills.assign)(named, args.named.capture().map);
            } else if (Array.isArray(positionalParams) && positionalParams.length > 0) {
                var count = Math.min(positionalParams.length, args.positional.length);
                named = {};
                (0, _polyfills.assign)(named, args.named.capture().map);
                if (_deprecatedFeatures.POSITIONAL_PARAM_CONFLICT) {
                    for (var i = 0; i < count; i++) {
                        var name = positionalParams[i];
                        (true && !(!args.named.has(name)) && (0, _debug.deprecate)('You cannot specify both a positional param (at position ' + i + ') and the hash argument `' + name + '`.', !args.named.has(name), {
                            id: 'ember-glimmer.positional-param-conflict',
                            until: '3.5.0'
                        }));

                        named[name] = args.positional.at(i);
                    }
                }
            } else {
                return null;
            }
            return { positional: _util.EMPTY_ARRAY, named: named };
        };

        CurlyComponentManager.prototype.create = function create(environment, state, args, dynamicScope, callerSelfRef, hasBlock) {
            if (true) {
                this._pushToDebugStack('component:' + state.name, environment);
            }
            // Get the nearest concrete component instance from the scope. "Virtual"
            // components will be skipped.
            var parentView = dynamicScope.view;
            // Get the Ember.Component subclass to instantiate for this component.
            var factory = state.ComponentClass;
            // Capture the arguments, which tells Glimmer to give us our own, stable
            // copy of the Arguments object that is safe to hold on to between renders.
            var capturedArgs = args.named.capture();
            var props = processComponentArgs(capturedArgs);
            // Alias `id` argument to `elementId` property on the component instance.
            aliasIdToElementId(args, props);
            // Set component instance's parentView property to point to nearest concrete
            // component.
            props.parentView = parentView;
            // Set whether this component was invoked with a block
            // (`{{#my-component}}{{/my-component}}`) or without one
            // (`{{my-component}}`).
            props[HAS_BLOCK] = hasBlock;
            // Save the current `this` context of the template as the component's
            // `_targetObject`, so bubbled actions are routed to the right place.
            props._targetObject = callerSelfRef.value();
            // static layout asserts CurriedDefinition
            if (state.template) {
                props.layout = state.template;
            }
            // Now that we've built up all of the properties to set on the component instance,
            // actually create it.
            var component = factory.create(props);
            var finalizer = (0, _instrumentation._instrumentStart)('render.component', initialRenderInstrumentDetails, component);
            // We become the new parentView for downstream components, so save our
            // component off on the dynamic scope.
            dynamicScope.view = component;
            // Unless we're the root component, we need to add ourselves to our parent
            // component's childViews array.
            if (parentView !== null && parentView !== undefined) {
                (0, _emberViews.addChildView)(parentView, component);
            }
            if (_emberEnvironment.ENV._ENABLE_DID_INIT_ATTRS_SUPPORT === true) {
                component.trigger('didInitAttrs');
            }
            component.trigger('didReceiveAttrs');
            var hasWrappedElement = component.tagName !== '';
            // We usually do this in the `didCreateElement`, but that hook doesn't fire for tagless components
            if (!hasWrappedElement) {
                if (environment.isInteractive) {
                    component.trigger('willRender');
                }
                component._transitionTo('hasElement');
                if (environment.isInteractive) {
                    component.trigger('willInsertElement');
                }
            }
            // Track additional lifecycle metadata about this component in a state bucket.
            // Essentially we're saving off all the state we'll need in the future.
            var bucket = new ComponentStateBucket(environment, component, capturedArgs, finalizer, hasWrappedElement);
            if (args.named.has('class')) {
                bucket.classRef = args.named.get('class');
            }
            if (true) {
                processComponentInitializationAssertions(component, props);
            }
            if (environment.isInteractive && hasWrappedElement) {
                component.trigger('willRender');
            }
            return bucket;
        };

        CurlyComponentManager.prototype.getSelf = function getSelf(_ref4) {
            var component = _ref4.component;

            return component[ROOT_REF];
        };

        CurlyComponentManager.prototype.didCreateElement = function didCreateElement(_ref5, element, operations) {
            var component = _ref5.component,
                classRef = _ref5.classRef,
                environment = _ref5.environment;

            (0, _emberViews.setViewElement)(component, element);
            var attributeBindings = component.attributeBindings,
                classNames = component.classNames,
                classNameBindings = component.classNameBindings;

            if (attributeBindings && attributeBindings.length) {
                applyAttributeBindings(element, attributeBindings, component, operations);
            } else {
                var id = component.elementId ? component.elementId : (0, _emberUtils.guidFor)(component);
                operations.setAttribute('id', _runtime.PrimitiveReference.create(id), false, null);
                IsVisibleBinding.install(element, component, operations);
            }
            if (classRef) {
                var ref = new SimpleClassNameBindingReference(classRef, classRef['_propertyKey']);
                operations.setAttribute('class', ref, false, null);
            }
            if (classNames && classNames.length) {
                classNames.forEach(function (name) {
                    operations.setAttribute('class', _runtime.PrimitiveReference.create(name), false, null);
                });
            }
            if (classNameBindings && classNameBindings.length) {
                classNameBindings.forEach(function (binding) {
                    ClassNameBinding.install(element, component, binding, operations);
                });
            }
            operations.setAttribute('class', _runtime.PrimitiveReference.create('ember-view'), false, null);
            if ('ariaRole' in component) {
                operations.setAttribute('role', referenceForKey(component, 'ariaRole'), false, null);
            }
            component._transitionTo('hasElement');
            if (environment.isInteractive) {
                component.trigger('willInsertElement');
            }
        };

        CurlyComponentManager.prototype.didRenderLayout = function didRenderLayout(bucket, bounds) {
            bucket.component[BOUNDS] = bounds;
            bucket.finalize();
            if (true) {
                this.debugStack.pop();
            }
        };

        CurlyComponentManager.prototype.getTag = function getTag(_ref6) {
            var args = _ref6.args,
                component = _ref6.component;

            return args ? (0, _reference.combine)([args.tag, component[DIRTY_TAG]]) : component[DIRTY_TAG];
        };

        CurlyComponentManager.prototype.didCreate = function didCreate(_ref7) {
            var component = _ref7.component,
                environment = _ref7.environment;

            if (environment.isInteractive) {
                component._transitionTo('inDOM');
                component.trigger('didInsertElement');
                component.trigger('didRender');
            }
        };

        CurlyComponentManager.prototype.update = function update(bucket) {
            var component = bucket.component,
                args = bucket.args,
                argsRevision = bucket.argsRevision,
                environment = bucket.environment;

            if (true) {
                this._pushToDebugStack(component._debugContainerKey, environment);
            }
            bucket.finalizer = (0, _instrumentation._instrumentStart)('render.component', rerenderInstrumentDetails, component);
            if (args && !args.tag.validate(argsRevision)) {
                var props = processComponentArgs(args);
                bucket.argsRevision = args.tag.value();
                component[IS_DISPATCHING_ATTRS] = true;
                component.setProperties(props);
                component[IS_DISPATCHING_ATTRS] = false;
                component.trigger('didUpdateAttrs');
                component.trigger('didReceiveAttrs');
            }
            if (environment.isInteractive) {
                component.trigger('willUpdate');
                component.trigger('willRender');
            }
        };

        CurlyComponentManager.prototype.didUpdateLayout = function didUpdateLayout(bucket) {
            bucket.finalize();
            if (true) {
                this.debugStack.pop();
            }
        };

        CurlyComponentManager.prototype.didUpdate = function didUpdate(_ref8) {
            var component = _ref8.component,
                environment = _ref8.environment;

            if (environment.isInteractive) {
                component.trigger('didUpdate');
                component.trigger('didRender');
            }
        };

        CurlyComponentManager.prototype.getDestructor = function getDestructor(stateBucket) {
            return stateBucket;
        };

        return CurlyComponentManager;
    }(AbstractManager);

    function processComponentInitializationAssertions(component, props) {
        (true && !(function () {
            var classNameBindings = component.classNameBindings;

            for (var i = 0; i < classNameBindings.length; i++) {
                var binding = classNameBindings[i];
                if (typeof binding !== 'string' || binding.length === 0) {
                    return false;
                }
            }
            return true;
        }()) && (0, _debug.assert)('classNameBindings must be non-empty strings: ' + component, function () {
            var classNameBindings = component.classNameBindings;
            for (var i = 0; i < classNameBindings.length; i++) {
                var binding = classNameBindings[i];if (typeof binding !== 'string' || binding.length === 0) {
                    return false;
                }
            }return true;
        }()));
        (true && !(function () {
            var classNameBindings = component.classNameBindings;

            for (var i = 0; i < classNameBindings.length; i++) {
                var binding = classNameBindings[i];
                if (binding.split(' ').length > 1) {
                    return false;
                }
            }
            return true;
        }()) && (0, _debug.assert)('classNameBindings must not have spaces in them: ' + component, function () {
            var classNameBindings = component.classNameBindings;
            for (var i = 0; i < classNameBindings.length; i++) {
                var binding = classNameBindings[i];if (binding.split(' ').length > 1) {
                    return false;
                }
            }return true;
        }()));
        (true && !(component.tagName !== '' || !component.classNameBindings || component.classNameBindings.length === 0) && (0, _debug.assert)('You cannot use `classNameBindings` on a tag-less component: ' + component, component.tagName !== '' || !component.classNameBindings || component.classNameBindings.length === 0));
        (true && !(component.tagName !== '' || props.id === component.elementId || !component.elementId && component.elementId !== '') && (0, _debug.assert)('You cannot use `elementId` on a tag-less component: ' + component, component.tagName !== '' || props.id === component.elementId || !component.elementId && component.elementId !== ''));
        (true && !(component.tagName !== '' || !component.attributeBindings || component.attributeBindings.length === 0) && (0, _debug.assert)('You cannot use `attributeBindings` on a tag-less component: ' + component, component.tagName !== '' || !component.attributeBindings || component.attributeBindings.length === 0));
    }
    function initialRenderInstrumentDetails(component) {
        return component.instrumentDetails({ initialRender: true });
    }
    function rerenderInstrumentDetails(component) {
        return component.instrumentDetails({ initialRender: false });
    }
    var CURLY_CAPABILITIES = {
        dynamicLayout: true,
        dynamicTag: true,
        prepareArgs: true,
        createArgs: true,
        attributeHook: true,
        elementHook: true,
        createCaller: true,
        dynamicScope: true,
        updateHook: true,
        createInstance: true
    };
    var CURLY_COMPONENT_MANAGER = new CurlyComponentManager();

    var CurlyComponentDefinition =
    // tslint:disable-next-line:no-shadowed-variable
    function CurlyComponentDefinition(name, ComponentClass, handle, template, args) {
        (0, _emberBabel.classCallCheck)(this, CurlyComponentDefinition);

        this.name = name;
        this.ComponentClass = ComponentClass;
        this.handle = handle;
        this.manager = CURLY_COMPONENT_MANAGER;
        var layout = template && template.asLayout();
        var symbolTable = layout ? layout.symbolTable : undefined;
        this.symbolTable = symbolTable;
        this.template = template;
        this.args = args;
        this.state = {
            name: name,
            ComponentClass: ComponentClass,
            handle: handle,
            template: template,
            capabilities: CURLY_CAPABILITIES,
            symbolTable: symbolTable
        };
    };

    var RootComponentManager = function (_CurlyComponentManage) {
        (0, _emberBabel.inherits)(RootComponentManager, _CurlyComponentManage);

        function RootComponentManager(component) {
            (0, _emberBabel.classCallCheck)(this, RootComponentManager);

            var _this29 = (0, _emberBabel.possibleConstructorReturn)(this, _CurlyComponentManage.call(this));

            _this29.component = component;
            return _this29;
        }

        RootComponentManager.prototype.getLayout = function getLayout(_state, resolver) {
            var template = this.templateFor(this.component, resolver);
            var layout = template.asWrappedLayout();
            return {
                handle: layout.compile(),
                symbolTable: layout.symbolTable
            };
        };

        RootComponentManager.prototype.create = function create(environment, _state, _args, dynamicScope) {
            var component = this.component;
            if (true) {
                this._pushToDebugStack(component._debugContainerKey, environment);
            }
            var finalizer = (0, _instrumentation._instrumentStart)('render.component', initialRenderInstrumentDetails, component);
            dynamicScope.view = component;
            var hasWrappedElement = component.tagName !== '';
            // We usually do this in the `didCreateElement`, but that hook doesn't fire for tagless components
            if (!hasWrappedElement) {
                if (environment.isInteractive) {
                    component.trigger('willRender');
                }
                component._transitionTo('hasElement');
                if (environment.isInteractive) {
                    component.trigger('willInsertElement');
                }
            }
            if (true) {
                processComponentInitializationAssertions(component, {});
            }
            return new ComponentStateBucket(environment, component, null, finalizer, hasWrappedElement);
        };

        return RootComponentManager;
    }(CurlyComponentManager);

    // ROOT is the top-level template it has nothing but one yield.
    // it is supposed to have a dummy element
    var ROOT_CAPABILITIES = {
        dynamicLayout: false,
        dynamicTag: true,
        prepareArgs: false,
        createArgs: false,
        attributeHook: true,
        elementHook: true,
        createCaller: true,
        dynamicScope: true,
        updateHook: true,
        createInstance: false
    };

    var RootComponentDefinition = function () {
        function RootComponentDefinition(component) {
            (0, _emberBabel.classCallCheck)(this, RootComponentDefinition);

            this.component = component;
            var manager = new RootComponentManager(component);
            this.manager = manager;
            var factory = _container.FACTORY_FOR.get(component);
            this.state = {
                name: factory.fullName.slice(10),
                capabilities: ROOT_CAPABILITIES,
                ComponentClass: factory,
                handle: null
            };
        }

        RootComponentDefinition.prototype.getTag = function getTag(_ref9) {
            var component = _ref9.component;

            return component[DIRTY_TAG];
        };

        return RootComponentDefinition;
    }();

    var DynamicScope = function () {
        function DynamicScope(view, outletState, rootOutletState) {
            (0, _emberBabel.classCallCheck)(this, DynamicScope);

            this.view = view;
            this.outletState = outletState;
            this.rootOutletState = rootOutletState;
        }

        DynamicScope.prototype.child = function child() {
            return new DynamicScope(this.view, this.outletState, this.rootOutletState);
        };

        DynamicScope.prototype.get = function get(key) {
            (true && !(key === 'outletState') && (0, _debug.assert)('Using `-get-dynamic-scope` is only supported for `outletState` (you used `' + key + '`).', key === 'outletState'));

            return this.outletState;
        };

        DynamicScope.prototype.set = function set(key, value) {
            (true && !(key === 'outletState') && (0, _debug.assert)('Using `-with-dynamic-scope` is only supported for `outletState` (you used `' + key + '`).', key === 'outletState'));

            this.outletState = value;
            return value;
        };

        return DynamicScope;
    }();

    var RootState = function () {
        function RootState(root, env, template, self, parentElement, dynamicScope, builder) {
            var _this30 = this;

            (0, _emberBabel.classCallCheck)(this, RootState);
            (true && !(template !== undefined) && (0, _debug.assert)('You cannot render `' + self.value() + '` without a template.', template !== undefined));

            this.id = (0, _emberViews.getViewId)(root);
            this.env = env;
            this.root = root;
            this.result = undefined;
            this.shouldReflush = false;
            this.destroyed = false;
            var options = this.options = {
                alwaysRevalidate: false
            };
            this.render = function () {
                var layout = template.asLayout();
                var handle = layout.compile();
                var iterator = (0, _runtime.renderMain)(layout['compiler'].program, env, self, dynamicScope, builder(env, { element: parentElement, nextSibling: null }), handle);
                var iteratorResult = void 0;
                do {
                    iteratorResult = iterator.next();
                } while (!iteratorResult.done);
                var result = _this30.result = iteratorResult.value;
                // override .render function after initial render
                _this30.render = function () {
                    return result.rerender(options);
                };
            };
        }

        RootState.prototype.isFor = function isFor(possibleRoot) {
            return this.root === possibleRoot;
        };

        RootState.prototype.destroy = function destroy() {
            var result = this.result,
                env = this.env;

            this.destroyed = true;
            this.env = undefined;
            this.root = null;
            this.result = undefined;
            this.render = undefined;
            if (result) {
                /*
                 Handles these scenarios:
                        * When roots are removed during standard rendering process, a transaction exists already
                   `.begin()` / `.commit()` are not needed.
                 * When roots are being destroyed manually (`component.append(); component.destroy() case), no
                   transaction exists already.
                 * When roots are being destroyed during `Renderer#destroy`, no transaction exists
                        */
                var needsTransaction = !env.inTransaction;
                if (needsTransaction) {
                    env.begin();
                }
                try {
                    result.destroy();
                } finally {
                    if (needsTransaction) {
                        env.commit();
                    }
                }
            }
        };

        return RootState;
    }();

    var renderers = [];
    function _resetRenderers() {
        renderers.length = 0;
    }
    (0, _emberMetal.setHasViews)(function () {
        return renderers.length > 0;
    });
    function register(renderer) {
        (true && !(renderers.indexOf(renderer) === -1) && (0, _debug.assert)('Cannot register the same renderer twice', renderers.indexOf(renderer) === -1));

        renderers.push(renderer);
    }
    function deregister(renderer) {
        var index = renderers.indexOf(renderer);
        (true && !(index !== -1) && (0, _debug.assert)('Cannot deregister unknown unregistered renderer', index !== -1));

        renderers.splice(index, 1);
    }
    function loopBegin() {
        for (var i = 0; i < renderers.length; i++) {
            renderers[i]._scheduleRevalidate();
        }
    }
    function K() {
        /* noop */
    }
    var renderSettledDeferred = null;
    /*
      Returns a promise which will resolve when rendering has settled. Settled in
      this context is defined as when all of the tags in use are "current" (e.g.
      `renderers.every(r => r._isValid())`). When this is checked at the _end_ of
      the run loop, this essentially guarantees that all rendering is completed.
    
      @method renderSettled
      @returns {Promise<void>} a promise which fulfills when rendering has settled
    */
    function renderSettled() {
        if (renderSettledDeferred === null) {
            renderSettledDeferred = _rsvp.default.defer();
            // if there is no current runloop, the promise created above will not have
            // a chance to resolve (because its resolved in backburner's "end" event)
            if (!(0, _runloop.getCurrentRunLoop)()) {
                // ensure a runloop has been kicked off
                _runloop.backburner.schedule('actions', null, K);
            }
        }
        return renderSettledDeferred.promise;
    }
    function resolveRenderPromise() {
        if (renderSettledDeferred !== null) {
            var resolve = renderSettledDeferred.resolve;
            renderSettledDeferred = null;
            _runloop.backburner.join(null, resolve);
        }
    }
    var loops = 0;
    function loopEnd() {
        for (var i = 0; i < renderers.length; i++) {
            if (!renderers[i]._isValid()) {
                if (loops > 10) {
                    loops = 0;
                    // TODO: do something better
                    renderers[i].destroy();
                    throw new Error('infinite rendering invalidation detected');
                }
                loops++;
                return _runloop.backburner.join(null, K);
            }
        }
        loops = 0;
        resolveRenderPromise();
    }
    _runloop.backburner.on('begin', loopBegin);
    _runloop.backburner.on('end', loopEnd);

    var Renderer = function () {
        function Renderer(env, rootTemplate) {
            var _viewRegistry = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _emberViews.fallbackViewRegistry;

            var destinedForDOM = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
            var builder = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _runtime.clientBuilder;
            (0, _emberBabel.classCallCheck)(this, Renderer);

            this._env = env;
            this._rootTemplate = rootTemplate;
            this._viewRegistry = _viewRegistry;
            this._destinedForDOM = destinedForDOM;
            this._destroyed = false;
            this._roots = [];
            this._lastRevision = -1;
            this._isRenderingRoots = false;
            this._removedRoots = [];
            this._builder = builder;
        }
        // renderer HOOKS


        Renderer.prototype.appendOutletView = function appendOutletView(view, target) {
            var definition = createRootOutlet(view);
            this._appendDefinition(view, (0, _runtime.curry)(definition), target);
        };

        Renderer.prototype.appendTo = function appendTo(view, target) {
            var definition = new RootComponentDefinition(view);
            this._appendDefinition(view, (0, _runtime.curry)(definition), target);
        };

        Renderer.prototype._appendDefinition = function _appendDefinition(root, definition, target) {
            var self = new UnboundReference(definition);
            var dynamicScope = new DynamicScope(null, _runtime.UNDEFINED_REFERENCE);
            var rootState = new RootState(root, this._env, this._rootTemplate, self, target, dynamicScope, this._builder);
            this._renderRoot(rootState);
        };

        Renderer.prototype.rerender = function rerender() {
            this._scheduleRevalidate();
        };

        Renderer.prototype.register = function register(view) {
            var id = (0, _emberViews.getViewId)(view);
            (true && !(!this._viewRegistry[id]) && (0, _debug.assert)('Attempted to register a view with an id already in use: ' + id, !this._viewRegistry[id]));

            this._viewRegistry[id] = view;
        };

        Renderer.prototype.unregister = function unregister(view) {
            delete this._viewRegistry[(0, _emberViews.getViewId)(view)];
        };

        Renderer.prototype.remove = function remove(view) {
            view._transitionTo('destroying');
            this.cleanupRootFor(view);
            (0, _emberViews.setViewElement)(view, null);
            if (this._destinedForDOM) {
                view.trigger('didDestroyElement');
            }
            if (!view.isDestroying) {
                view.destroy();
            }
        };

        Renderer.prototype.cleanupRootFor = function cleanupRootFor(view) {
            // no need to cleanup roots if we have already been destroyed
            if (this._destroyed) {
                return;
            }
            var roots = this._roots;
            // traverse in reverse so we can remove items
            // without mucking up the index
            var i = this._roots.length;
            while (i--) {
                var root = roots[i];
                if (root.isFor(view)) {
                    root.destroy();
                    roots.splice(i, 1);
                }
            }
        };

        Renderer.prototype.destroy = function destroy() {
            if (this._destroyed) {
                return;
            }
            this._destroyed = true;
            this._clearAllRoots();
        };

        Renderer.prototype.getBounds = function getBounds(view) {
            var bounds = view[BOUNDS];
            var parentElement = bounds.parentElement();
            var firstNode = bounds.firstNode();
            var lastNode = bounds.lastNode();
            return { parentElement: parentElement, firstNode: firstNode, lastNode: lastNode };
        };

        Renderer.prototype.createElement = function createElement(tagName) {
            return this._env.getAppendOperations().createElement(tagName);
        };

        Renderer.prototype._renderRoot = function _renderRoot(root) {
            var roots = this._roots;

            roots.push(root);
            if (roots.length === 1) {
                register(this);
            }
            this._renderRootsTransaction();
        };

        Renderer.prototype._renderRoots = function _renderRoots() {
            var roots = this._roots,
                env = this._env,
                removedRoots = this._removedRoots;

            var globalShouldReflush = void 0;
            var initialRootsLength = void 0;
            do {
                env.begin();
                try {
                    // ensure that for the first iteration of the loop
                    // each root is processed
                    initialRootsLength = roots.length;
                    globalShouldReflush = false;
                    for (var i = 0; i < roots.length; i++) {
                        var root = roots[i];
                        if (root.destroyed) {
                            // add to the list of roots to be removed
                            // they will be removed from `this._roots` later
                            removedRoots.push(root);
                            // skip over roots that have been marked as destroyed
                            continue;
                        }
                        var shouldReflush = root.shouldReflush;

                        // when processing non-initial reflush loops,
                        // do not process more roots than needed
                        if (i >= initialRootsLength && !shouldReflush) {
                            continue;
                        }
                        root.options.alwaysRevalidate = shouldReflush;
                        // track shouldReflush based on this roots render result
                        shouldReflush = root.shouldReflush = (0, _emberMetal.runInTransaction)(root, 'render');
                        // globalShouldReflush should be `true` if *any* of
                        // the roots need to reflush
                        globalShouldReflush = globalShouldReflush || shouldReflush;
                    }
                    this._lastRevision = _reference.CURRENT_TAG.value();
                } finally {
                    env.commit();
                }
            } while (globalShouldReflush || roots.length > initialRootsLength);
            // remove any roots that were destroyed during this transaction
            while (removedRoots.length) {
                var _root = removedRoots.pop();
                var rootIndex = roots.indexOf(_root);
                roots.splice(rootIndex, 1);
            }
            if (this._roots.length === 0) {
                deregister(this);
            }
        };

        Renderer.prototype._renderRootsTransaction = function _renderRootsTransaction() {
            if (this._isRenderingRoots) {
                // currently rendering roots, a new root was added and will
                // be processed by the existing _renderRoots invocation
                return;
            }
            // used to prevent calling _renderRoots again (see above)
            // while we are actively rendering roots
            this._isRenderingRoots = true;
            var completedWithoutError = false;
            try {
                this._renderRoots();
                completedWithoutError = true;
            } finally {
                if (!completedWithoutError) {
                    this._lastRevision = _reference.CURRENT_TAG.value();
                    if (this._env.inTransaction === true) {
                        this._env.commit();
                    }
                }
                this._isRenderingRoots = false;
            }
        };

        Renderer.prototype._clearAllRoots = function _clearAllRoots() {
            var roots = this._roots;
            for (var i = 0; i < roots.length; i++) {
                var root = roots[i];
                root.destroy();
            }
            this._removedRoots.length = 0;
            this._roots = [];
            // if roots were present before destroying
            // deregister this renderer instance
            if (roots.length) {
                deregister(this);
            }
        };

        Renderer.prototype._scheduleRevalidate = function _scheduleRevalidate() {
            _runloop.backburner.scheduleOnce('render', this, this._revalidate);
        };

        Renderer.prototype._isValid = function _isValid() {
            return this._destroyed || this._roots.length === 0 || _reference.CURRENT_TAG.validate(this._lastRevision);
        };

        Renderer.prototype._revalidate = function _revalidate() {
            if (this._isValid()) {
                return;
            }
            this._renderRootsTransaction();
        };

        return Renderer;
    }();

    var InertRenderer = function (_Renderer) {
        (0, _emberBabel.inherits)(InertRenderer, _Renderer);

        function InertRenderer() {
            (0, _emberBabel.classCallCheck)(this, InertRenderer);
            return (0, _emberBabel.possibleConstructorReturn)(this, _Renderer.apply(this, arguments));
        }

        InertRenderer.create = function create(_ref10) {
            var env = _ref10.env,
                rootTemplate = _ref10.rootTemplate,
                _viewRegistry = _ref10._viewRegistry,
                builder = _ref10.builder;

            return new this(env, rootTemplate, _viewRegistry, false, builder);
        };

        InertRenderer.prototype.getElement = function getElement(_view) {
            throw new Error('Accessing `this.element` is not allowed in non-interactive environments (such as FastBoot).');
        };

        return InertRenderer;
    }(Renderer);

    var InteractiveRenderer = function (_Renderer2) {
        (0, _emberBabel.inherits)(InteractiveRenderer, _Renderer2);

        function InteractiveRenderer() {
            (0, _emberBabel.classCallCheck)(this, InteractiveRenderer);
            return (0, _emberBabel.possibleConstructorReturn)(this, _Renderer2.apply(this, arguments));
        }

        InteractiveRenderer.create = function create(_ref11) {
            var env = _ref11.env,
                rootTemplate = _ref11.rootTemplate,
                _viewRegistry = _ref11._viewRegistry,
                builder = _ref11.builder;

            return new this(env, rootTemplate, _viewRegistry, true, builder);
        };

        InteractiveRenderer.prototype.getElement = function getElement(view) {
            return (0, _emberViews.getViewElement)(view);
        };

        return InteractiveRenderer;
    }(Renderer);

    var TEMPLATES = {};
    function setTemplates(templates) {
        TEMPLATES = templates;
    }
    function getTemplates() {
        return TEMPLATES;
    }
    function getTemplate(name) {
        if (TEMPLATES.hasOwnProperty(name)) {
            return TEMPLATES[name];
        }
    }
    function hasTemplate(name) {
        return TEMPLATES.hasOwnProperty(name);
    }
    function setTemplate(name, template) {
        return TEMPLATES[name] = template;
    }

    ///<reference path="./simple-dom.d.ts" />

    /**
    @module ember
    */
    /**
      Calls [loc](/api/classes/Ember.String.html#method_loc) with the
      provided string. This is a convenient way to localize text within a template.
      For example:
    
      ```javascript
      Ember.STRINGS = {
        '_welcome_': 'Bonjour'
      };
      ```
    
      ```handlebars
      <div class='message'>
        {{loc '_welcome_'}}
      </div>
      ```
    
      ```html
      <div class='message'>
        Bonjour
      </div>
      ```
    
      See [String.loc](/api/ember/release/classes/String/methods/loc?anchor=loc) for how to
      set up localized string references.
    
      @method loc
      @for Ember.Templates.helpers
      @param {String} str The string to format.
      @see {String#loc}
      @public
    */
    var loc$1 = helper(function (params) {
        return _string.loc.apply(null, params);
    });

    var CompileTimeLookup = function () {
        function CompileTimeLookup(resolver) {
            (0, _emberBabel.classCallCheck)(this, CompileTimeLookup);

            this.resolver = resolver;
        }

        CompileTimeLookup.prototype.getCapabilities = function getCapabilities(handle) {
            var definition = this.resolver.resolve(handle);
            var manager = definition.manager,
                state = definition.state;

            return manager.getCapabilities(state);
        };

        CompileTimeLookup.prototype.getLayout = function getLayout(handle) {
            var _resolver$resolve = this.resolver.resolve(handle),
                manager = _resolver$resolve.manager,
                state = _resolver$resolve.state;

            var capabilities = manager.getCapabilities(state);
            if (capabilities.dynamicLayout) {
                return null;
            }
            var invocation = manager.getLayout(state, this.resolver);
            return {
                compile: function () {
                    return invocation.handle;
                },

                symbolTable: invocation.symbolTable
            };
        };

        CompileTimeLookup.prototype.lookupHelper = function lookupHelper(name, referrer) {
            return this.resolver.lookupHelper(name, referrer);
        };

        CompileTimeLookup.prototype.lookupModifier = function lookupModifier(name, referrer) {
            return this.resolver.lookupModifier(name, referrer);
        };

        CompileTimeLookup.prototype.lookupComponentDefinition = function lookupComponentDefinition(name, referrer) {
            return this.resolver.lookupComponentHandle(name, referrer);
        };

        CompileTimeLookup.prototype.lookupPartial = function lookupPartial(name, referrer) {
            return this.resolver.lookupPartial(name, referrer);
        };

        return CompileTimeLookup;
    }();

    var CAPABILITIES$1 = {
        dynamicLayout: false,
        dynamicTag: false,
        prepareArgs: false,
        createArgs: true,
        attributeHook: false,
        elementHook: false,
        createCaller: false,
        dynamicScope: true,
        updateHook: true,
        createInstance: true
    };
    function capabilities(managerAPI) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        (true && !(managerAPI === '3.4') && (0, _debug.assert)('Invalid component manager compatibility specified', managerAPI === '3.4'));

        return {
            asyncLifeCycleCallbacks: !!options.asyncLifecycleCallbacks,
            destructor: !!options.destructor
        };
    }
    function hasAsyncLifeCycleCallbacks(delegate) {
        return delegate.capabilities.asyncLifeCycleCallbacks;
    }
    function hasDestructors(delegate) {
        return delegate.capabilities.destructor;
    }
    function valueForCapturedArgs(args) {
        return {
            named: args.named.value(),
            positional: args.positional.value()
        };
    }
    /**
      The CustomComponentManager allows addons to provide custom component
      implementations that integrate seamlessly into Ember. This is accomplished
      through a delegate, registered with the custom component manager, which
      implements a set of hooks that determine component behavior.
    
      To create a custom component manager, instantiate a new CustomComponentManager
      class and pass the delegate as the first argument:
    
      ```js
      let manager = new CustomComponentManager({
        // ...delegate implementation...
      });
      ```
    
      ## Delegate Hooks
    
      Throughout the lifecycle of a component, the component manager will invoke
      delegate hooks that are responsible for surfacing those lifecycle changes to
      the end developer.
    
      * `create()` - invoked when a new instance of a component should be created
      * `update()` - invoked when the arguments passed to a component change
      * `getContext()` - returns the object that should be
    */

    var CustomComponentManager = function (_AbstractManager3) {
        (0, _emberBabel.inherits)(CustomComponentManager, _AbstractManager3);

        function CustomComponentManager() {
            (0, _emberBabel.classCallCheck)(this, CustomComponentManager);
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager3.apply(this, arguments));
        }

        CustomComponentManager.prototype.create = function create(_env, definition, args) {
            var delegate = definition.delegate;

            var capturedArgs = args.capture();
            var invocationArgs = valueForCapturedArgs(capturedArgs);
            var component = delegate.createComponent(definition.ComponentClass.class, invocationArgs);
            return new CustomComponentState(delegate, component, capturedArgs);
        };

        CustomComponentManager.prototype.update = function update(_ref12) {
            var delegate = _ref12.delegate,
                component = _ref12.component,
                args = _ref12.args;

            delegate.updateComponent(component, valueForCapturedArgs(args));
        };

        CustomComponentManager.prototype.didCreate = function didCreate(_ref13) {
            var delegate = _ref13.delegate,
                component = _ref13.component;

            if (hasAsyncLifeCycleCallbacks(delegate)) {
                delegate.didCreateComponent(component);
            }
        };

        CustomComponentManager.prototype.didUpdate = function didUpdate(_ref14) {
            var delegate = _ref14.delegate,
                component = _ref14.component;

            if (hasAsyncLifeCycleCallbacks(delegate)) {
                delegate.didUpdateComponent(component);
            }
        };

        CustomComponentManager.prototype.getContext = function getContext(_ref15) {
            var delegate = _ref15.delegate,
                component = _ref15.component;

            delegate.getContext(component);
        };

        CustomComponentManager.prototype.getSelf = function getSelf(_ref16) {
            var delegate = _ref16.delegate,
                component = _ref16.component;

            var context = delegate.getContext(component);
            return new RootReference(context);
        };

        CustomComponentManager.prototype.getDestructor = function getDestructor(state) {
            if (hasDestructors(state.delegate)) {
                return state;
            } else {
                return null;
            }
        };

        CustomComponentManager.prototype.getCapabilities = function getCapabilities() {
            return CAPABILITIES$1;
        };

        CustomComponentManager.prototype.getTag = function getTag(_ref17) {
            var args = _ref17.args;

            return args.tag;
        };

        CustomComponentManager.prototype.didRenderLayout = function didRenderLayout() {};

        CustomComponentManager.prototype.getLayout = function getLayout(state) {
            return {
                handle: state.template.asLayout().compile(),
                symbolTable: state.symbolTable
            };
        };

        return CustomComponentManager;
    }(AbstractManager);

    var CUSTOM_COMPONENT_MANAGER = new CustomComponentManager();
    /**
     * Stores internal state about a component instance after it's been created.
     */

    var CustomComponentState = function () {
        function CustomComponentState(delegate, component, args) {
            (0, _emberBabel.classCallCheck)(this, CustomComponentState);

            this.delegate = delegate;
            this.component = component;
            this.args = args;
        }

        CustomComponentState.prototype.destroy = function destroy() {
            var delegate = this.delegate,
                component = this.component;

            if (hasDestructors(delegate)) {
                delegate.destroyComponent(component);
            }
        };

        return CustomComponentState;
    }();

    var CustomManagerDefinition = function CustomManagerDefinition(name, ComponentClass, delegate, template) {
        (0, _emberBabel.classCallCheck)(this, CustomManagerDefinition);

        this.name = name;
        this.ComponentClass = ComponentClass;
        this.delegate = delegate;
        this.template = template;
        this.manager = CUSTOM_COMPONENT_MANAGER;
        var layout = template.asLayout();
        var symbolTable = layout.symbolTable;
        this.symbolTable = symbolTable;
        this.state = {
            name: name,
            ComponentClass: ComponentClass,
            template: template,
            symbolTable: symbolTable,
            delegate: delegate
        };
    };

    var CAPABILITIES$2 = {
        dynamicLayout: false,
        dynamicTag: false,
        prepareArgs: false,
        createArgs: false,
        attributeHook: false,
        elementHook: false,
        createCaller: true,
        dynamicScope: true,
        updateHook: true,
        createInstance: true
    };

    var TemplateOnlyComponentManager = function (_AbstractManager4) {
        (0, _emberBabel.inherits)(TemplateOnlyComponentManager, _AbstractManager4);

        function TemplateOnlyComponentManager() {
            (0, _emberBabel.classCallCheck)(this, TemplateOnlyComponentManager);
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager4.apply(this, arguments));
        }

        TemplateOnlyComponentManager.prototype.getLayout = function getLayout(template) {
            var layout = template.asLayout();
            return {
                handle: layout.compile(),
                symbolTable: layout.symbolTable
            };
        };

        TemplateOnlyComponentManager.prototype.getCapabilities = function getCapabilities() {
            return CAPABILITIES$2;
        };

        TemplateOnlyComponentManager.prototype.create = function create() {
            return null;
        };

        TemplateOnlyComponentManager.prototype.getSelf = function getSelf() {
            return _runtime.NULL_REFERENCE;
        };

        TemplateOnlyComponentManager.prototype.getTag = function getTag() {
            return _reference.CONSTANT_TAG;
        };

        TemplateOnlyComponentManager.prototype.getDestructor = function getDestructor() {
            return null;
        };

        return TemplateOnlyComponentManager;
    }(AbstractManager);

    var MANAGER = new TemplateOnlyComponentManager();

    var TemplateOnlyComponentDefinition = function TemplateOnlyComponentDefinition(state) {
        (0, _emberBabel.classCallCheck)(this, TemplateOnlyComponentDefinition);

        this.state = state;
        this.manager = MANAGER;
    };

    function classHelper(_ref18) {
        var positional = _ref18.positional;

        var path = positional.at(0);
        var args = positional.length;
        var value = path.value();
        if (value === true) {
            if (args > 1) {
                return (0, _string.dasherize)(positional.at(1).value());
            }
            return null;
        }
        if (value === false) {
            if (args > 2) {
                return (0, _string.dasherize)(positional.at(2).value());
            }
            return null;
        }
        return value;
    }
    function classHelper$1(_vm, args) {
        return new InternalHelperReference(classHelper, args.capture());
    }

    function htmlSafe$1(_ref19) {
        var positional = _ref19.positional;

        var path = positional.at(0);
        return new SafeString(path.value());
    }
    function htmlSafeHelper(_vm, args) {
        return new InternalHelperReference(htmlSafe$1, args.capture());
    }

    function inputTypeHelper(_ref20) {
        var positional = _ref20.positional;

        var type = positional.at(0).value();
        if (type === 'checkbox') {
            return '-checkbox';
        }
        return '-text-field';
    }
    function inputTypeHelper$1(_vm, args) {
        return new InternalHelperReference(inputTypeHelper, args.capture());
    }

    function normalizeClass(_ref21) {
        var positional = _ref21.positional;

        var classNameParts = positional.at(0).value().split('.');
        var className = classNameParts[classNameParts.length - 1];
        var value = positional.at(1).value();
        if (value === true) {
            return (0, _string.dasherize)(className);
        } else if (!value && value !== 0) {
            return '';
        } else {
            return String(value);
        }
    }
    function normalizeClassHelper(_vm, args) {
        return new InternalHelperReference(normalizeClass, args.capture());
    }

    /**
    @module ember
    */
    /**
      The `{{action}}` helper provides a way to pass triggers for behavior (usually
      just a function) between components, and into components from controllers.
    
      ### Passing functions with the action helper
    
      There are three contexts an action helper can be used in. The first two
      contexts to discuss are attribute context, and Handlebars value context.
    
      ```handlebars
      {{! An example of attribute context }}
      <div onclick={{action "save"}}></div>
      {{! Examples of Handlebars value context }}
      {{input on-input=(action "save")}}
      {{yield (action "refreshData") andAnotherParam}}
      ```
    
      In these contexts,
      the helper is called a "closure action" helper. Its behavior is simple:
      If passed a function name, read that function off the `actions` property
      of the current context. Once that function is read, or immediately if a function was
      passed, create a closure over that function and any arguments.
      The resulting value of an action helper used this way is simply a function.
    
      For example, in the attribute context:
    
      ```handlebars
      {{! An example of attribute context }}
      <div onclick={{action "save"}}></div>
      ```
    
      The resulting template render logic would be:
    
      ```js
      var div = document.createElement('div');
      var actionFunction = (function(context){
        return function() {
          return context.actions.save.apply(context, arguments);
        };
      })(context);
      div.onclick = actionFunction;
      ```
    
      Thus when the div is clicked, the action on that context is called.
      Because the `actionFunction` is just a function, closure actions can be
      passed between components and still execute in the correct context.
    
      Here is an example action handler on a component:
    
      ```app/components/my-component.js
      import Component from '@ember/component';
    
      export default Component.extend({
        actions: {
          save() {
            this.get('model').save();
          }
        }
      });
      ```
    
      Actions are always looked up on the `actions` property of the current context.
      This avoids collisions in the naming of common actions, such as `destroy`.
      Two options can be passed to the `action` helper when it is used in this way.
    
      * `target=someProperty` will look to `someProperty` instead of the current
        context for the `actions` hash. This can be useful when targeting a
        service for actions.
      * `value="target.value"` will read the path `target.value` off the first
        argument to the action when it is called and rewrite the first argument
        to be that value. This is useful when attaching actions to event listeners.
    
      ### Invoking an action
    
      Closure actions curry both their scope and any arguments. When invoked, any
      additional arguments are added to the already curried list.
      Actions should be invoked using the [sendAction](/api/ember/release/classes/Component/methods/sendAction?anchor=sendAction)
      method. The first argument to `sendAction` is the action to be called, and
      additional arguments are passed to the action function. This has interesting
      properties combined with currying of arguments. For example:
    
      ```app/components/my-component.js
      import Component from '@ember/component';
    
      export default Component.extend({
        actions: {
          // Usage {{input on-input=(action (action 'setName' model) value="target.value")}}
          setName(model, name) {
            model.set('name', name);
          }
        }
      });
      ```
    
      The first argument (`model`) was curried over, and the run-time argument (`event`)
      becomes a second argument. Action calls can be nested this way because each simply
      returns a function. Any function can be passed to the `{{action}}` helper, including
      other actions.
    
      Actions invoked with `sendAction` have the same currying behavior as demonstrated
      with `on-input` above. For example:
    
      ```app/components/my-input.js
      import Component from '@ember/component';
    
      export default Component.extend({
        actions: {
          setName(model, name) {
            model.set('name', name);
          }
        }
      });
      ```
    
      ```handlebars
      {{my-input submit=(action 'setName' model)}}
      ```
    
      ```app/components/my-component.js
      import Component from '@ember/component';
    
      export default Component.extend({
        click() {
          // Note that model is not passed, it was curried in the template
          this.sendAction('submit', 'bob');
        }
      });
      ```
    
      ### Attaching actions to DOM elements
    
      The third context of the `{{action}}` helper can be called "element space".
      For example:
    
      ```handlebars
      {{! An example of element space }}
      <div {{action "save"}}></div>
      ```
    
      Used this way, the `{{action}}` helper provides a useful shortcut for
      registering an HTML element in a template for a single DOM event and
      forwarding that interaction to the template's context (controller or component).
      If the context of a template is a controller, actions used this way will
      bubble to routes when the controller does not implement the specified action.
      Once an action hits a route, it will bubble through the route hierarchy.
    
      ### Event Propagation
    
      `{{action}}` helpers called in element space can control event bubbling. Note
      that the closure style actions cannot.
    
      Events triggered through the action helper will automatically have
      `.preventDefault()` called on them. You do not need to do so in your event
      handlers. If you need to allow event propagation (to handle file inputs for
      example) you can supply the `preventDefault=false` option to the `{{action}}` helper:
    
      ```handlebars
      <div {{action "sayHello" preventDefault=false}}>
        <input type="file" />
        <input type="checkbox" />
      </div>
      ```
    
      To disable bubbling, pass `bubbles=false` to the helper:
    
      ```handlebars
      <button {{action 'edit' post bubbles=false}}>Edit</button>
      ```
    
      To disable bubbling with closure style actions you must create your own
      wrapper helper that makes use of `event.stopPropagation()`:
    
      ```handlebars
      <div onclick={{disable-bubbling (action "sayHello")}}>Hello</div>
      ```
    
      ```app/helpers/disable-bubbling.js
      import { helper } from '@ember/component/helper';
    
      export function disableBubbling([action]) {
        return function(event) {
          event.stopPropagation();
          return action(event);
        };
      }
      export default helper(disableBubbling);
      ```
    
      If you need the default handler to trigger you should either register your
      own event handler, or use event methods on your view class. See
      ["Responding to Browser Events"](/api/ember/release/classes/Component)
      in the documentation for `Component` for more information.
    
      ### Specifying DOM event type
    
      `{{action}}` helpers called in element space can specify an event type.
      By default the `{{action}}` helper registers for DOM `click` events. You can
      supply an `on` option to the helper to specify a different DOM event name:
    
      ```handlebars
      <div {{action "anActionName" on="doubleClick"}}>
        click me
      </div>
      ```
    
      See ["Event Names"](/api/ember/release/classes/Component) for a list of
      acceptable DOM event names.
    
      ### Specifying whitelisted modifier keys
    
      `{{action}}` helpers called in element space can specify modifier keys.
      By default the `{{action}}` helper will ignore click events with pressed modifier
      keys. You can supply an `allowedKeys` option to specify which keys should not be ignored.
    
      ```handlebars
      <div {{action "anActionName" allowedKeys="alt"}}>
        click me
      </div>
      ```
    
      This way the action will fire when clicking with the alt key pressed down.
      Alternatively, supply "any" to the `allowedKeys` option to accept any combination of modifier keys.
    
      ```handlebars
      <div {{action "anActionName" allowedKeys="any"}}>
        click me with any key pressed
      </div>
      ```
    
      ### Specifying a Target
    
      A `target` option can be provided to the helper to change
      which object will receive the method call. This option must be a path
      to an object, accessible in the current context:
    
      ```app/templates/application.hbs
      <div {{action "anActionName" target=someService}}>
        click me
      </div>
      ```
    
      ```app/controllers/application.js
      import Controller from '@ember/controller';
      import { inject as service } from '@ember/service';
    
      export default Controller.extend({
        someService: service()
      });
      ```
    
      @method action
      @for Ember.Templates.helpers
      @public
    */
    function action(_vm, args) {
        var named = args.named,
            positional = args.positional;

        var capturedArgs = positional.capture();
        // The first two argument slots are reserved.
        // pos[0] is the context (or `this`)
        // pos[1] is the action name or function
        // Anything else is an action argument.

        var _capturedArgs$referen = capturedArgs.references,
            context = _capturedArgs$referen[0],
            action = _capturedArgs$referen[1],
            restArgs = _capturedArgs$referen.slice(2);

        // TODO: Is there a better way of doing this?
        var debugKey = action._propertyKey;
        var target = named.has('target') ? named.get('target') : context;
        var processArgs = makeArgsProcessor(named.has('value') && named.get('value'), restArgs);
        var fn = void 0;
        if (typeof action[INVOKE] === 'function') {
            fn = makeClosureAction(action, action, action[INVOKE], processArgs, debugKey);
        } else if ((0, _reference.isConst)(target) && (0, _reference.isConst)(action)) {
            fn = makeClosureAction(context.value(), target.value(), action.value(), processArgs, debugKey);
        } else {
            fn = makeDynamicClosureAction(context.value(), target, action, processArgs, debugKey);
        }
        fn[ACTION] = true;
        return new UnboundReference(fn);
    }
    function NOOP$1(args) {
        return args;
    }
    function makeArgsProcessor(valuePathRef, actionArgsRef) {
        var mergeArgs = void 0;
        if (actionArgsRef.length > 0) {
            mergeArgs = function (args) {
                return actionArgsRef.map(function (ref) {
                    return ref.value();
                }).concat(args);
            };
        }
        var readValue = void 0;
        if (valuePathRef) {
            readValue = function (args) {
                var valuePath = valuePathRef.value();
                if (valuePath && args.length > 0) {
                    args[0] = (0, _emberMetal.get)(args[0], valuePath);
                }
                return args;
            };
        }
        if (mergeArgs && readValue) {
            return function (args) {
                return readValue(mergeArgs(args));
            };
        } else {
            return mergeArgs || readValue || NOOP$1;
        }
    }
    function makeDynamicClosureAction(context, targetRef, actionRef, processArgs, debugKey) {
        // We don't allow undefined/null values, so this creates a throw-away action to trigger the assertions
        if (true) {
            makeClosureAction(context, targetRef.value(), actionRef.value(), processArgs, debugKey);
        }
        return function () {
            return makeClosureAction(context, targetRef.value(), actionRef.value(), processArgs, debugKey).apply(undefined, arguments);
        };
    }
    function makeClosureAction(context, target, action, processArgs, debugKey) {
        var self = void 0;
        var fn = void 0;
        (true && !(action !== undefined && action !== null) && (0, _debug.assert)('Action passed is null or undefined in (action) from ' + target + '.', action !== undefined && action !== null));

        if (typeof action[INVOKE] === 'function') {
            self = action;
            fn = action[INVOKE];
        } else {
            var typeofAction = typeof action;
            if (typeofAction === 'string') {
                self = target;
                fn = target.actions && target.actions[action];
                (true && !(fn) && (0, _debug.assert)('An action named \'' + action + '\' was not found in ' + target, fn));
            } else if (typeofAction === 'function') {
                self = context;
                fn = action;
            } else {
                (true && !(false) && (0, _debug.assert)('An action could not be made for `' + (debugKey || action) + '` in ' + target + '. Please confirm that you are using either a quoted action name (i.e. `(action \'' + (debugKey || 'myAction') + '\')`) or a function available in ' + target + '.', false));
            }
        }
        return function () {
            for (var _len = arguments.length, args = Array(_len), _key2 = 0; _key2 < _len; _key2++) {
                args[_key2] = arguments[_key2];
            }

            var payload = { target: self, args: args, label: '@glimmer/closure-action' };
            return (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, function () {
                return _runloop.join.apply(undefined, [self, fn].concat(processArgs(args)));
            });
        };
    }

    var isEmpty = function (value) {
        return value === null || value === undefined || typeof value.toString !== 'function';
    };
    var normalizeTextValue = function (value) {
        if (isEmpty(value)) {
            return '';
        }
        return String(value);
    };
    /**
    @module ember
    */
    /**
      Concatenates the given arguments into a string.
    
      Example:
    
      ```handlebars
      {{some-component name=(concat firstName " " lastName)}}
    
      {{! would pass name="<first name value> <last name value>" to the component}}
      ```
    
      @public
      @method concat
      @for Ember.Templates.helpers
      @since 1.13.0
    */
    function concat(_ref22) {
        var positional = _ref22.positional;

        return positional.value().map(normalizeTextValue).join('');
    }
    function concat$1(_vm, args) {
        return new InternalHelperReference(concat, args.capture());
    }

    /**
    @module ember
    */
    /**
      Dynamically look up a property on an object. The second argument to `{{get}}`
      should have a string value, although it can be bound.
    
      For example, these two usages are equivalent:
    
      ```handlebars
      {{person.height}}
      {{get person "height"}}
      ```
    
      If there were several facts about a person, the `{{get}}` helper can dynamically
      pick one:
    
      ```handlebars
      {{get person factName}}
      ```
    
      For a more complex example, this template would allow the user to switch
      between showing the user's height and weight with a click:
    
      ```handlebars
      {{get person factName}}
      <button {{action (action (mut factName)) "height"}}>Show height</button>
      <button {{action (action (mut factName)) "weight"}}>Show weight</button>
      ```
    
      The `{{get}}` helper can also respect mutable values itself. For example:
    
      ```handlebars
      {{input value=(mut (get person factName)) type="text"}}
      <button {{action (action (mut factName)) "height"}}>Show height</button>
      <button {{action (action (mut factName)) "weight"}}>Show weight</button>
      ```
    
      Would allow the user to swap what fact is being displayed, and also edit
      that fact via a two-way mutable binding.
    
      @public
      @method get
      @for Ember.Templates.helpers
      @since 2.1.0
     */
    function get$1(_vm, args) {
        return GetHelperReference.create(args.positional.at(0), args.positional.at(1));
    }
    function referenceFromPath(source, path) {
        var innerReference = void 0;
        if (path === undefined || path === null || path === '') {
            innerReference = _runtime.NULL_REFERENCE;
        } else if (typeof path === 'string' && path.indexOf('.') > -1) {
            innerReference = referenceFromParts(source, path.split('.'));
        } else {
            innerReference = source.get(path);
        }
        return innerReference;
    }

    var GetHelperReference = function (_CachedReference$6) {
        (0, _emberBabel.inherits)(GetHelperReference, _CachedReference$6);

        GetHelperReference.create = function create(sourceReference, pathReference) {
            if ((0, _reference.isConst)(pathReference)) {
                var path = pathReference.value();
                return referenceFromPath(sourceReference, path);
            } else {
                return new GetHelperReference(sourceReference, pathReference);
            }
        };

        function GetHelperReference(sourceReference, pathReference) {
            (0, _emberBabel.classCallCheck)(this, GetHelperReference);

            var _this35 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference$6.call(this));

            _this35.sourceReference = sourceReference;
            _this35.pathReference = pathReference;
            _this35.lastPath = null;
            _this35.innerReference = _runtime.NULL_REFERENCE;
            var innerTag = _this35.innerTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
            _this35.tag = (0, _reference.combine)([sourceReference.tag, pathReference.tag, innerTag]);
            return _this35;
        }

        GetHelperReference.prototype.compute = function compute() {
            var lastPath = this.lastPath,
                innerReference = this.innerReference,
                innerTag = this.innerTag;

            var path = this.pathReference.value();
            if (path !== lastPath) {
                innerReference = referenceFromPath(this.sourceReference, path);
                innerTag.inner.update(innerReference.tag);
                this.innerReference = innerReference;
                this.lastPath = path;
            }
            return innerReference.value();
        };

        GetHelperReference.prototype[UPDATE] = function (value) {
            (0, _emberMetal.set)(this.sourceReference.value(), this.pathReference.value(), value);
        };

        return GetHelperReference;
    }(CachedReference$1);

    /**
    @module ember
    */
    /**
       Use the `{{hash}}` helper to create a hash to pass as an option to your
       components. This is specially useful for contextual components where you can
       just yield a hash:
    
       ```handlebars
       {{yield (hash
          name='Sarah'
          title=office
       )}}
       ```
    
       Would result in an object such as:
    
       ```js
       { name: 'Sarah', title: this.get('office') }
       ```
    
       Where the `title` is bound to updates of the `office` property.
    
       Note that the hash is an empty object with no prototype chain, therefore
       common methods like `toString` are not available in the resulting hash.
       If you need to use such a method, you can use the `call` or `apply`
       approach:
    
       ```js
       function toString(obj) {
         return Object.prototype.toString.apply(obj);
       }
       ```
    
       @method hash
       @for Ember.Templates.helpers
       @param {Object} options
       @return {Object} Hash
       @since 2.3.0
       @public
     */
    function hash(_vm, args) {
        return args.named.capture();
    }

    /**
    @module ember
    */

    var ConditionalHelperReference = function (_CachedReference$7) {
        (0, _emberBabel.inherits)(ConditionalHelperReference, _CachedReference$7);

        ConditionalHelperReference.create = function create(_condRef, truthyRef, falsyRef) {
            var condRef = ConditionalReference$1.create(_condRef);
            if ((0, _reference.isConst)(condRef)) {
                return condRef.value() ? truthyRef : falsyRef;
            } else {
                return new ConditionalHelperReference(condRef, truthyRef, falsyRef);
            }
        };

        function ConditionalHelperReference(cond, truthy, falsy) {
            (0, _emberBabel.classCallCheck)(this, ConditionalHelperReference);

            var _this36 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference$7.call(this));

            _this36.branchTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
            _this36.tag = (0, _reference.combine)([cond.tag, _this36.branchTag]);
            _this36.cond = cond;
            _this36.truthy = truthy;
            _this36.falsy = falsy;
            return _this36;
        }

        ConditionalHelperReference.prototype.compute = function compute() {
            var branch = this.cond.value() ? this.truthy : this.falsy;
            this.branchTag.inner.update(branch.tag);
            return branch.value();
        };

        return ConditionalHelperReference;
    }(CachedReference$1);

    /**
      The `if` helper allows you to conditionally render one of two branches,
      depending on the "truthiness" of a property.
      For example the following values are all falsey: `false`, `undefined`, `null`, `""`, `0`, `NaN` or an empty array.
    
      This helper has two forms, block and inline.
    
      ## Block form
    
      You can use the block form of `if` to conditionally render a section of the template.
    
      To use it, pass the conditional value to the `if` helper,
      using the block form to wrap the section of template you want to conditionally render.
      Like so:
    
      ```handlebars
      {{! will not render if foo is falsey}}
      {{#if foo}}
        Welcome to the {{foo.bar}}
      {{/if}}
      ```
    
      You can also specify a template to show if the property is falsey by using
      the `else` helper.
    
      ```handlebars
      {{! is it raining outside?}}
      {{#if isRaining}}
        Yes, grab an umbrella!
      {{else}}
        No, it's lovely outside!
      {{/if}}
      ```
    
      You are also able to combine `else` and `if` helpers to create more complex
      conditional logic.
    
      ```handlebars
      {{#if isMorning}}
        Good morning
      {{else if isAfternoon}}
        Good afternoon
      {{else}}
        Good night
      {{/if}}
      ```
    
      ## Inline form
    
      The inline `if` helper conditionally renders a single property or string.
    
      In this form, the `if` helper receives three arguments, the conditional value,
      the value to render when truthy, and the value to render when falsey.
    
      For example, if `useLongGreeting` is truthy, the following:
    
      ```handlebars
      {{if useLongGreeting "Hello" "Hi"}} Alex
      ```
    
      Will render:
    
      ```html
      Hello Alex
      ```
    
      ### Nested `if`
    
      You can use the `if` helper inside another helper as a nested helper:
    
      ```handlebars
      {{some-component height=(if isBig "100" "10")}}
      ```
    
      One detail to keep in mind is that both branches of the `if` helper will be evaluated,
      so if you have `{{if condition "foo" (expensive-operation "bar")`,
      `expensive-operation` will always calculate.
    
      @method if
      @for Ember.Templates.helpers
      @public
    */
    function inlineIf(_vm, _ref23) {
        var positional = _ref23.positional;
        (true && !(positional.length === 3 || positional.length === 2) && (0, _debug.assert)('The inline form of the `if` helper expects two or three arguments, e.g. ' + '`{{if trialExpired "Expired" expiryDate}}`.', positional.length === 3 || positional.length === 2));

        return ConditionalHelperReference.create(positional.at(0), positional.at(1), positional.at(2));
    }
    /**
      The inline `unless` helper conditionally renders a single property or string.
      This helper acts like a ternary operator. If the first property is falsy,
      the second argument will be displayed, otherwise, the third argument will be
      displayed
    
      ```handlebars
      {{unless useLongGreeting "Hi" "Hello"}} Ben
      ```
    
      You can use the `unless` helper inside another helper as a subexpression.
    
      ```handlebars
      {{some-component height=(unless isBig "10" "100")}}
      ```
    
      @method unless
      @for Ember.Templates.helpers
      @public
    */
    function inlineUnless(_vm, _ref24) {
        var positional = _ref24.positional;
        (true && !(positional.length === 3 || positional.length === 2) && (0, _debug.assert)('The inline form of the `unless` helper expects two or three arguments, e.g. ' + '`{{unless isFirstLogin "Welcome back!"}}`.', positional.length === 3 || positional.length === 2));

        return ConditionalHelperReference.create(positional.at(0), positional.at(2), positional.at(1));
    }

    /**
    @module ember
    */
    /**
      `log` allows you to output the value of variables in the current rendering
      context. `log` also accepts primitive types such as strings or numbers.
    
      ```handlebars
      {{log "myVariable:" myVariable }}
      ```
    
      @method log
      @for Ember.Templates.helpers
      @param {Array} params
      @public
    */
    function log(_ref25) {
        var _console;

        var positional = _ref25.positional;

        /* eslint-disable no-console */
        (_console = console).log.apply(_console, positional.value());
        /* eslint-enable no-console */
    }
    function log$1(_vm, args) {
        return new InternalHelperReference(log, args.capture());
    }

    /**
    @module ember
    */
    /**
      The `mut` helper lets you __clearly specify__ that a child `Component` can update the
      (mutable) value passed to it, which will __change the value of the parent component__.
    
      To specify that a parameter is mutable, when invoking the child `Component`:
    
      ```handlebars
      {{my-child childClickCount=(mut totalClicks)}}
      ```
    
      The child `Component` can then modify the parent's value just by modifying its own
      property:
    
      ```javascript
      // my-child.js
      export default Component.extend({
        click() {
          this.incrementProperty('childClickCount');
        }
      });
      ```
    
      Note that for curly components (`{{my-component}}`) the bindings are already mutable,
      making the `mut` unnecessary.
    
      Additionally, the `mut` helper can be combined with the `action` helper to
      mutate a value. For example:
    
      ```handlebars
      {{my-child childClickCount=totalClicks click-count-change=(action (mut totalClicks))}}
      ```
    
      The child `Component` would invoke the action with the new click value:
    
      ```javascript
      // my-child.js
      export default Component.extend({
        click() {
          this.get('click-count-change')(this.get('childClickCount') + 1);
        }
      });
      ```
    
      The `mut` helper changes the `totalClicks` value to what was provided as the action argument.
    
      The `mut` helper, when used with `action`, will return a function that
      sets the value passed to `mut` to its first argument. This works like any other
      closure action and interacts with the other features `action` provides.
      As an example, we can create a button that increments a value passing the value
      directly to the `action`:
    
      ```handlebars
      {{! inc helper is not provided by Ember }}
      <button onclick={{action (mut count) (inc count)}}>
        Increment count
      </button>
      ```
    
      You can also use the `value` option:
    
      ```handlebars
      <input value={{name}} oninput={{action (mut name) value="target.value"}}>
      ```
    
      @method mut
      @param {Object} [attr] the "two-way" attribute that can be modified.
      @for Ember.Templates.helpers
      @public
    */
    var MUT_REFERENCE = (0, _emberUtils.symbol)('MUT');
    var SOURCE = (0, _emberUtils.symbol)('SOURCE');
    function isMut(ref) {
        return ref && ref[MUT_REFERENCE];
    }
    function unMut(ref) {
        return ref[SOURCE] || ref;
    }
    function mut(_vm, args) {
        var rawRef = args.positional.at(0);
        if (isMut(rawRef)) {
            return rawRef;
        }
        // TODO: Improve this error message. This covers at least two distinct
        // cases:
        //
        // 1. (mut "not a path") – passing a literal, result from a helper
        //    invocation, etc
        //
        // 2. (mut receivedValue) – passing a value received from the caller
        //    that was originally derived from a literal, result from a helper
        //    invocation, etc
        //
        // This message is alright for the first case, but could be quite
        // confusing for the second case.
        (true && !(rawRef[UPDATE]) && (0, _debug.assert)('You can only pass a path to mut', rawRef[UPDATE]));

        var wrappedRef = Object.create(rawRef);
        wrappedRef[SOURCE] = rawRef;
        wrappedRef[INVOKE] = rawRef[UPDATE];
        wrappedRef[MUT_REFERENCE] = true;
        return wrappedRef;
    }

    /**
    @module ember
    */
    /**
      This is a helper to be used in conjunction with the link-to helper.
      It will supply url query parameters to the target route.
    
      Example
    
      ```handlebars
      {{#link-to 'posts' (query-params direction="asc")}}Sort{{/link-to}}
      ```
    
      @method query-params
      @for Ember.Templates.helpers
      @param {Object} hash takes a hash of query parameters
      @return {Object} A `QueryParams` object for `{{link-to}}`
      @public
    */
    function queryParams(_ref26) {
        var positional = _ref26.positional,
            named = _ref26.named;
        (true && !(positional.value().length === 0) && (0, _debug.assert)("The `query-params` helper only accepts hash parameters, e.g. (query-params queryParamPropertyName='foo') as opposed to just (query-params 'foo')", positional.value().length === 0));

        return new _emberRouting.QueryParams((0, _polyfills.assign)({}, named.value()));
    }
    function queryParams$1(_vm, args) {
        return new InternalHelperReference(queryParams, args.capture());
    }

    /**
      The `readonly` helper let's you specify that a binding is one-way only,
      instead of two-way.
      When you pass a `readonly` binding from an outer context (e.g. parent component),
      to to an inner context (e.g. child component), you are saying that changing that
      property in the inner context does not change the value in the outer context.
    
      To specify that a binding is read-only, when invoking the child `Component`:
    
      ```app/components/my-parent.js
      export default Component.extend({
        totalClicks: 3
      });
      ```
    
      ```app/templates/components/my-parent.hbs
      {{log totalClicks}} // -> 3
      {{my-child childClickCount=(readonly totalClicks)}}
      ```
    
      Now, when you update `childClickCount`:
    
      ```app/components/my-child.js
      export default Component.extend({
        click() {
          this.incrementProperty('childClickCount');
        }
      });
      ```
    
      The value updates in the child component, but not the parent component:
    
      ```app/templates/components/my-child.hbs
      {{log childClickCount}} //-> 4
      ```
    
      ```app/templates/components/my-parent.hbs
      {{log totalClicks}} //-> 3
      {{my-child childClickCount=(readonly totalClicks)}}
      ```
    
      ### Objects and Arrays
    
      When passing a property that is a complex object (e.g. object, array) instead of a primitive object (e.g. number, string),
      only the reference to the object is protected using the readonly helper.
      This means that you can change properties of the object both on the parent component, as well as the child component.
      The `readonly` binding behaves similar to the `const` keyword in JavaScript.
    
      Let's look at an example:
    
      First let's set up the parent component:
    
      ```app/components/my-parent.js
      import Component from '@ember/component';
    
      export default Component.extend({
        clicks: null,
    
        init() {
          this._super(...arguments);
          this.set('clicks', { total: 3 });
        }
      });
      ```
    
      ```app/templates/components/my-parent.hbs
      {{log clicks.total}} //-> 3
      {{my-child childClicks=(readonly clicks)}}
      ```
    
      Now, if you update the `total` property of `childClicks`:
    
      ```app/components/my-child.js
      import Component from '@ember/component';
    
      export default Component.extend({
        click() {
          this.get('clicks').incrementProperty('total');
        }
      });
      ```
    
      You will see the following happen:
    
      ```app/templates/components/my-parent.hbs
      {{log clicks.total}} //-> 4
      {{my-child childClicks=(readonly clicks)}}
      ```
    
      ```app/templates/components/my-child.hbs
      {{log childClicks.total}} //-> 4
      ```
    
      @method readonly
      @param {Object} [attr] the read-only attribute.
      @for Ember.Templates.helpers
      @private
    */
    function readonly(_vm, args) {
        var ref = unMut(args.positional.at(0));
        return new ReadonlyReference(ref);
    }

    /**
    @module ember
    */
    /**
      The `{{unbound}}` helper disconnects the one-way binding of a property,
      essentially freezing its value at the moment of rendering. For example,
      in this example the display of the variable `name` will not change even
      if it is set with a new value:
    
      ```handlebars
      {{unbound name}}
      ```
    
      Like any helper, the `unbound` helper can accept a nested helper expression.
      This allows for custom helpers to be rendered unbound:
    
      ```handlebars
      {{unbound (some-custom-helper)}}
      {{unbound (capitalize name)}}
      {{! You can use any helper, including unbound, in a nested expression }}
      {{capitalize (unbound name)}}
      ```
    
      The `unbound` helper only accepts a single argument, and it return an
      unbound value.
    
      @method unbound
      @for Ember.Templates.helpers
      @public
    */
    function unbound(_vm, args) {
        (true && !(args.positional.length === 1 && args.named.length === 0) && (0, _debug.assert)('unbound helper cannot be called with multiple params or hash params', args.positional.length === 1 && args.named.length === 0));

        return UnboundReference.create(args.positional.at(0).value());
    }

    var MODIFIERS = ['alt', 'shift', 'meta', 'ctrl'];
    var POINTER_EVENT_TYPE_REGEX = /^click|mouse|touch/;
    function isAllowedEvent(event, allowedKeys) {
        if (allowedKeys === null || allowedKeys === undefined) {
            if (POINTER_EVENT_TYPE_REGEX.test(event.type)) {
                return (0, _emberViews.isSimpleClick)(event);
            } else {
                allowedKeys = '';
            }
        }
        if (allowedKeys.indexOf('any') >= 0) {
            return true;
        }
        for (var i = 0; i < MODIFIERS.length; i++) {
            if (event[MODIFIERS[i] + 'Key'] && allowedKeys.indexOf(MODIFIERS[i]) === -1) {
                return false;
            }
        }
        return true;
    }
    var ActionHelper = {
        // registeredActions is re-exported for compatibility with older plugins
        // that were using this undocumented API.
        registeredActions: _emberViews.ActionManager.registeredActions,
        registerAction: function (actionState) {
            var actionId = actionState.actionId;

            _emberViews.ActionManager.registeredActions[actionId] = actionState;
            return actionId;
        },
        unregisterAction: function (actionState) {
            var actionId = actionState.actionId;

            delete _emberViews.ActionManager.registeredActions[actionId];
        }
    };

    var ActionState = function () {
        function ActionState(element, actionId, actionName, actionArgs, namedArgs, positionalArgs, implicitTarget, dom, tag) {
            (0, _emberBabel.classCallCheck)(this, ActionState);

            this.element = element;
            this.actionId = actionId;
            this.actionName = actionName;
            this.actionArgs = actionArgs;
            this.namedArgs = namedArgs;
            this.positional = positionalArgs;
            this.implicitTarget = implicitTarget;
            this.dom = dom;
            this.eventName = this.getEventName();
            this.tag = tag;
        }

        ActionState.prototype.getEventName = function getEventName() {
            return this.namedArgs.get('on').value() || 'click';
        };

        ActionState.prototype.getActionArgs = function getActionArgs() {
            var result = new Array(this.actionArgs.length);
            for (var i = 0; i < this.actionArgs.length; i++) {
                result[i] = this.actionArgs[i].value();
            }
            return result;
        };

        ActionState.prototype.getTarget = function getTarget() {
            var implicitTarget = this.implicitTarget,
                namedArgs = this.namedArgs;

            var target = void 0;
            if (namedArgs.has('target')) {
                target = namedArgs.get('target').value();
            } else {
                target = implicitTarget.value();
            }
            return target;
        };

        ActionState.prototype.handler = function handler(event) {
            var _this37 = this;

            var actionName = this.actionName,
                namedArgs = this.namedArgs;

            var bubbles = namedArgs.get('bubbles');
            var preventDefault = namedArgs.get('preventDefault');
            var allowedKeys = namedArgs.get('allowedKeys');
            var target = this.getTarget();
            var shouldBubble = bubbles.value() !== false;
            if (!isAllowedEvent(event, allowedKeys.value())) {
                return true;
            }
            if (preventDefault.value() !== false) {
                event.preventDefault();
            }
            if (!shouldBubble) {
                event.stopPropagation();
            }
            (0, _runloop.join)(function () {
                var args = _this37.getActionArgs();
                var payload = {
                    args: args,
                    target: target,
                    name: null
                };
                if (typeof actionName[INVOKE] === 'function') {
                    (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, function () {
                        actionName[INVOKE].apply(actionName, args);
                    });
                    return;
                }
                if (typeof actionName === 'function') {
                    (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, function () {
                        actionName.apply(target, args);
                    });
                    return;
                }
                payload.name = actionName;
                if (target.send) {
                    (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, function () {
                        target.send.apply(target, [actionName].concat(args));
                    });
                } else {
                    (true && !(typeof target[actionName] === 'function') && (0, _debug.assert)('The action \'' + actionName + '\' did not exist on ' + target, typeof target[actionName] === 'function'));

                    (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, function () {
                        target[actionName].apply(target, args);
                    });
                }
            });
            return shouldBubble;
        };

        ActionState.prototype.destroy = function destroy() {
            ActionHelper.unregisterAction(this);
        };

        return ActionState;
    }();

    var ActionModifierManager = function () {
        function ActionModifierManager() {
            (0, _emberBabel.classCallCheck)(this, ActionModifierManager);
        }

        ActionModifierManager.prototype.create = function create(element, args, _dynamicScope, dom) {
            var _args$capture = args.capture(),
                named = _args$capture.named,
                positional = _args$capture.positional,
                tag = _args$capture.tag;

            var implicitTarget = void 0;
            var actionName = void 0;
            var actionNameRef = void 0;
            if (positional.length > 1) {
                implicitTarget = positional.at(0);
                actionNameRef = positional.at(1);
                if (actionNameRef[INVOKE]) {
                    actionName = actionNameRef;
                } else {
                    var actionLabel = actionNameRef._propertyKey;
                    actionName = actionNameRef.value();
                    (true && !(typeof actionName === 'string' || typeof actionName === 'function') && (0, _debug.assert)('You specified a quoteless path, `' + actionLabel + '`, to the ' + '{{action}} helper which did not resolve to an action name (a ' + 'string). Perhaps you meant to use a quoted actionName? (e.g. ' + '{{action "' + actionLabel + '"}}).', typeof actionName === 'string' || typeof actionName === 'function'));
                }
            }
            var actionArgs = [];
            // The first two arguments are (1) `this` and (2) the action name.
            // Everything else is a param.
            for (var i = 2; i < positional.length; i++) {
                actionArgs.push(positional.at(i));
            }
            var actionId = (0, _emberUtils.uuid)();
            return new ActionState(element, actionId, actionName, actionArgs, named, positional, implicitTarget, dom, tag);
        };

        ActionModifierManager.prototype.install = function install(actionState) {
            var dom = actionState.dom,
                element = actionState.element,
                actionId = actionState.actionId;

            ActionHelper.registerAction(actionState);
            dom.setAttribute(element, 'data-ember-action', '');
            dom.setAttribute(element, 'data-ember-action-' + actionId, actionId);
        };

        ActionModifierManager.prototype.update = function update(actionState) {
            var positional = actionState.positional;

            var actionNameRef = positional.at(1);
            if (!actionNameRef[INVOKE]) {
                actionState.actionName = actionNameRef.value();
            }
            actionState.eventName = actionState.getEventName();
        };

        ActionModifierManager.prototype.getTag = function getTag(actionState) {
            return actionState.tag;
        };

        ActionModifierManager.prototype.getDestructor = function getDestructor(modifier) {
            return modifier;
        };

        return ActionModifierManager;
    }();

    function hashToArgs(hash) {
        if (hash === null) return null;
        var names = hash[0].map(function (key) {
            return '@' + key;
        });
        return [names, hash[1]];
    }

    function textAreaMacro(_name, params, hash, builder) {
        var definition = builder.compiler['resolver'].lookupComponentDefinition('-text-area', builder.referrer);
        wrapComponentClassAttribute(hash);
        builder.component.static(definition, [params || [], hashToArgs(hash), null, null]);
        return true;
    }

    /**
    @module ember
    */
    function buildSyntax(type, params, hash, builder) {
        var definition = builder.compiler['resolver'].lookupComponentDefinition(type, builder.referrer);
        builder.component.static(definition, [params, hashToArgs(hash), null, null]);
        return true;
    }
    /**
      The `{{input}}` helper lets you create an HTML `<input />` component.
      It causes an `TextField` component to be rendered.  For more info,
      see the [TextField](/api/ember/release/classes/TextField) docs and
      the [templates guide](https://guides.emberjs.com/release/templates/input-helpers/).
    
      ```handlebars
      {{input value="987"}}
      ```
    
      renders as:
    
      ```HTML
      <input type="text" value="987" />
      ```
    
      ### Text field
    
      If no `type` option is specified, a default of type 'text' is used.
      Many of the standard HTML attributes may be passed to this helper.
      <table>
        <tr><td>`readonly`</td><td>`required`</td><td>`autofocus`</td></tr>
        <tr><td>`value`</td><td>`placeholder`</td><td>`disabled`</td></tr>
        <tr><td>`size`</td><td>`tabindex`</td><td>`maxlength`</td></tr>
        <tr><td>`name`</td><td>`min`</td><td>`max`</td></tr>
        <tr><td>`pattern`</td><td>`accept`</td><td>`autocomplete`</td></tr>
        <tr><td>`autosave`</td><td>`formaction`</td><td>`formenctype`</td></tr>
        <tr><td>`formmethod`</td><td>`formnovalidate`</td><td>`formtarget`</td></tr>
        <tr><td>`height`</td><td>`inputmode`</td><td>`multiple`</td></tr>
        <tr><td>`step`</td><td>`width`</td><td>`form`</td></tr>
        <tr><td>`selectionDirection`</td><td>`spellcheck`</td><td>&nbsp;</td></tr>
      </table>
      When set to a quoted string, these values will be directly applied to the HTML
      element. When left unquoted, these values will be bound to a property on the
      template's current rendering context (most typically a controller instance).
      A very common use of this helper is to bind the `value` of an input to an Object's attribute:
    
      ```handlebars
      Search:
      {{input value=searchWord}}
      ```
    
      In this example, the initial value in the `<input />` will be set to the value of `searchWord`.
      If the user changes the text, the value of `searchWord` will also be updated.
    
      ### Actions
    
      The helper can send multiple actions based on user events.
      The action property defines the action which is sent when
      the user presses the return key.
    
      ```handlebars
      {{input action="submit"}}
      ```
    
      The helper allows some user events to send actions.
    
      * `enter`
      * `insert-newline`
      * `escape-press`
      * `focus-in`
      * `focus-out`
      * `key-press`
      * `key-up`
    
      For example, if you desire an action to be sent when the input is blurred,
      you only need to setup the action name to the event name property.
    
      ```handlebars
      {{input focus-out="alertMessage"}}
      ```
      See more about [Text Support Actions](/api/ember/release/classes/TextField)
    
      ### Extending `TextField`
    
      Internally, `{{input type="text"}}` creates an instance of `TextField`, passing
      arguments from the helper to `TextField`'s `create` method. You can extend the
      capabilities of text inputs in your applications by reopening this class. For example,
      if you are building a Bootstrap project where `data-*` attributes are used, you
      can add one to the `TextField`'s `attributeBindings` property:
    
      ```javascript
      import TextField from '@ember/component/text-field';
      TextField.reopen({
        attributeBindings: ['data-error']
      });
      ```
    
      Keep in mind when writing `TextField` subclasses that `TextField`
      itself extends `Component`. Expect isolated component semantics, not
      legacy 1.x view semantics (like `controller` being present).
      See more about [Ember components](/api/ember/release/classes/Component)
    
      ### Checkbox
    
      Checkboxes are special forms of the `{{input}}` helper.  To create a `<checkbox />`:
    
      ```handlebars
      Emberize Everything:
      {{input type="checkbox" name="isEmberized" checked=isEmberized}}
      ```
    
      This will bind checked state of this checkbox to the value of `isEmberized`  -- if either one changes,
      it will be reflected in the other.
    
      The following HTML attributes can be set via the helper:
    
      * `checked`
      * `disabled`
      * `tabindex`
      * `indeterminate`
      * `name`
      * `autofocus`
      * `form`
    
      ### Extending `Checkbox`
    
      Internally, `{{input type="checkbox"}}` creates an instance of `Checkbox`, passing
      arguments from the helper to `Checkbox`'s `create` method. You can extend the
      capablilties of checkbox inputs in your applications by reopening this class. For example,
      if you wanted to add a css class to all checkboxes in your application:
    
      ```javascript
      import Checkbox from '@ember/component/checkbox';
    
      Checkbox.reopen({
        classNames: ['my-app-checkbox']
      });
      ```
    
      @method input
      @for Ember.Templates.helpers
      @param {Hash} options
      @public
    */
    function inputMacro(_name, params, hash, builder) {
        if (params === null) {
            params = [];
        }
        if (hash !== null) {
            var keys = hash[0];
            var values = hash[1];
            var typeIndex = keys.indexOf('type');
            if (typeIndex > -1) {
                var typeArg = values[typeIndex];
                if (Array.isArray(typeArg)) {
                    // there is an AST plugin that converts this to an expression
                    // it really should just compile in the component call too.
                    var inputTypeExpr = params[0];
                    builder.dynamicComponent(inputTypeExpr, null, params.slice(1), hash, true, null, null);
                    return true;
                }
                if (typeArg === 'checkbox') {
                    (true && !(keys.indexOf('value') === -1) && (0, _debug.assert)("{{input type='checkbox'}} does not support setting `value=someBooleanValue`; " + 'you must use `checked=someBooleanValue` instead.', keys.indexOf('value') === -1));

                    wrapComponentClassAttribute(hash);
                    return buildSyntax('-checkbox', params, hash, builder);
                }
            }
        }
        return buildSyntax('-text-field', params, hash, builder);
    }

    /**
    @module ember
    */
    /**
        The `let` helper receives one or more positional arguments and yields
        them out as block params.
    
        This allows the developer to introduce shorter names for certain computations
        in the template.
    
        This is especially useful if you are passing properties to a component
        that receives a lot of options and you want to clean up the invocation.
    
        For the following example, the template receives a `post` object with
        `content` and `title` properties.
    
        We are going to call the `my-post` component, passing a title which is
        the title of the post suffixed with the name of the blog, the content
        of the post, and a series of options defined in-place.
    
        ```handlebars
        {{#let
            (concat post.title ' | The Ember.js Blog')
            post.content
            (hash
              theme="high-contrast"
              enableComments=true
            )
            as |title content options|
        }}
          {{my-post title=title content=content options=options}}
        {{/let}}
      ```
    
      @method let
      @for Ember.Templates.helpers
      @public
    */
    function blockLetMacro(params, _hash, template, _inverse, builder) {
        if (template !== null) {
            if (params !== null) {
                builder.compileParams(params);
                builder.invokeStaticBlock(template, params.length);
            } else {
                builder.invokeStatic(template);
            }
        }
        return true;
    }

    var CAPABILITIES$3 = {
        dynamicLayout: true,
        dynamicTag: false,
        prepareArgs: false,
        createArgs: false,
        attributeHook: false,
        elementHook: false,
        createCaller: true,
        dynamicScope: true,
        updateHook: true,
        createInstance: true
    };

    var MountManager = function (_AbstractManager5) {
        (0, _emberBabel.inherits)(MountManager, _AbstractManager5);

        function MountManager() {
            (0, _emberBabel.classCallCheck)(this, MountManager);
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager5.apply(this, arguments));
        }

        MountManager.prototype.getDynamicLayout = function getDynamicLayout(state, _) {
            var template = state.engine.lookup('template:application');
            var layout = template.asLayout();
            return {
                handle: layout.compile(),
                symbolTable: layout.symbolTable
            };
        };

        MountManager.prototype.getCapabilities = function getCapabilities() {
            return CAPABILITIES$3;
        };

        MountManager.prototype.create = function create(environment, state) {
            if (true) {
                this._pushEngineToDebugStack('engine:' + state.name, environment);
            }
            // TODO
            // mount is a runtime helper, this shouldn't use dynamic layout
            // we should resolve the engine app template in the helper
            // it also should use the owner that looked up the mount helper.
            var engine = environment.owner.buildChildEngineInstance(state.name);
            engine.boot();
            var applicationFactory = engine.factoryFor('controller:application');
            var controllerFactory = applicationFactory || (0, _emberRouting.generateControllerFactory)(engine, 'application');
            var controller = void 0;
            var self = void 0;
            var bucket = void 0;
            var tag = void 0;
            if (true) {
                var modelRef = state.modelRef;
                if (modelRef === undefined) {
                    controller = controllerFactory.create();
                    self = new RootReference(controller);
                    tag = _reference.CONSTANT_TAG;
                    bucket = { engine: engine, controller: controller, self: self, tag: tag };
                } else {
                    var model = modelRef.value();
                    var modelRev = modelRef.tag.value();
                    controller = controllerFactory.create({ model: model });
                    self = new RootReference(controller);
                    tag = modelRef.tag;
                    bucket = { engine: engine, controller: controller, self: self, tag: tag, modelRef: modelRef, modelRev: modelRev };
                }
            } else {
                controller = controllerFactory.create();
                self = new RootReference(controller);
                tag = _reference.CONSTANT_TAG;
                bucket = { engine: engine, controller: controller, self: self, tag: tag };
            }
            return bucket;
        };

        MountManager.prototype.getSelf = function getSelf(_ref27) {
            var self = _ref27.self;

            return self;
        };

        MountManager.prototype.getTag = function getTag(state) {
            return state.tag;
        };

        MountManager.prototype.getDestructor = function getDestructor(_ref28) {
            var engine = _ref28.engine;

            return engine;
        };

        MountManager.prototype.didRenderLayout = function didRenderLayout() {
            if (true) {
                this.debugStack.pop();
            }
        };

        MountManager.prototype.update = function update(bucket) {
            if (true) {
                var controller = bucket.controller,
                    modelRef = bucket.modelRef,
                    modelRev = bucket.modelRev;

                if (!modelRef.tag.validate(modelRev)) {
                    var model = modelRef.value();
                    bucket.modelRev = modelRef.tag.value();
                    controller.set('model', model);
                }
            }
        };

        return MountManager;
    }(AbstractManager);

    var MOUNT_MANAGER = new MountManager();

    var MountDefinition = function MountDefinition(name, modelRef) {
        (0, _emberBabel.classCallCheck)(this, MountDefinition);

        this.manager = MOUNT_MANAGER;
        this.state = { name: name, modelRef: modelRef };
    };

    /**
    @module ember
    */
    function mountHelper(vm, args) {
        var env = vm.env;
        var nameRef = args.positional.at(0);
        var modelRef = args.named.has('model') ? args.named.get('model') : undefined;
        return new DynamicEngineReference(nameRef, env, modelRef);
    }
    /**
      The `{{mount}}` helper lets you embed a routeless engine in a template.
      Mounting an engine will cause an instance to be booted and its `application`
      template to be rendered.
    
      For example, the following template mounts the `ember-chat` engine:
    
      ```handlebars
      {{! application.hbs }}
      {{mount "ember-chat"}}
      ```
    
      Additionally, you can also pass in a `model` argument that will be
      set as the engines model. This can be an existing object:
    
      ```
      <div>
        {{mount 'admin' model=userSettings}}
      </div>
      ```
    
      Or an inline `hash`, and you can even pass components:
    
      ```
      <div>
        <h1>Application template!</h1>
        {{mount 'admin' model=(hash
            title='Secret Admin'
            signInButton=(component 'sign-in-button')
        )}}
      </div>
      ```
    
      @method mount
      @param {String} name Name of the engine to mount.
      @param {Object} [model] Object that will be set as
                              the model of the engine.
      @for Ember.Templates.helpers
      @category ember-application-engines
      @public
    */
    function mountMacro(_name, params, hash, builder) {
        if (true) {
            (true && !(params.length === 1) && (0, _debug.assert)('You can only pass a single positional argument to the {{mount}} helper, e.g. {{mount "chat-engine"}}.', params.length === 1));
        } else {
            (true && !(params.length === 1 && hash === null) && (0, _debug.assert)('You can only pass a single argument to the {{mount}} helper, e.g. {{mount "chat-engine"}}.', params.length === 1 && hash === null));
        }
        var expr = [_wireFormat.Ops.Helper, '-mount', params || [], hash];
        builder.dynamicComponent(expr, null, [], null, false, null, null);
        return true;
    }

    var DynamicEngineReference = function () {
        function DynamicEngineReference(nameRef, env, modelRef) {
            (0, _emberBabel.classCallCheck)(this, DynamicEngineReference);

            this.tag = nameRef.tag;
            this.nameRef = nameRef;
            this.modelRef = modelRef;
            this.env = env;
            this._lastName = null;
            this._lastDef = null;
        }

        DynamicEngineReference.prototype.value = function value() {
            var env = this.env,
                nameRef = this.nameRef,
                modelRef = this.modelRef;

            var name = nameRef.value();
            if (typeof name === 'string') {
                if (this._lastName === name) {
                    return this._lastDef;
                }
                (true && !(env.owner.hasRegistration('engine:' + name)) && (0, _debug.assert)('You used `{{mount \'' + name + '\'}}`, but the engine \'' + name + '\' can not be found.', env.owner.hasRegistration('engine:' + name)));

                if (!env.owner.hasRegistration('engine:' + name)) {
                    return null;
                }
                this._lastName = name;
                this._lastDef = (0, _runtime.curry)(new MountDefinition(name, modelRef));
                return this._lastDef;
            } else {
                (true && !(name === null || name === undefined) && (0, _debug.assert)('Invalid engine name \'' + name + '\' specified, engine name must be either a string, null or undefined.', name === null || name === undefined));

                this._lastDef = null;
                this._lastName = null;
                return null;
            }
        };

        DynamicEngineReference.prototype.get = function get() {
            return _runtime.UNDEFINED_REFERENCE;
        };

        return DynamicEngineReference;
    }();

    var RootOutletReference = function () {
        function RootOutletReference(outletState) {
            (0, _emberBabel.classCallCheck)(this, RootOutletReference);

            this.outletState = outletState;
            this.tag = _reference.DirtyableTag.create();
        }

        RootOutletReference.prototype.get = function get(key) {
            return new PathReference(this, key);
        };

        RootOutletReference.prototype.value = function value() {
            return this.outletState;
        };

        RootOutletReference.prototype.update = function update(state) {
            this.outletState.outlets.main = state;
            this.tag.inner.dirty();
        };

        return RootOutletReference;
    }();

    var OutletReference = function () {
        function OutletReference(parentStateRef, outletNameRef) {
            (0, _emberBabel.classCallCheck)(this, OutletReference);

            this.parentStateRef = parentStateRef;
            this.outletNameRef = outletNameRef;
            this.tag = (0, _reference.combine)([parentStateRef.tag, outletNameRef.tag]);
        }

        OutletReference.prototype.value = function value() {
            var outletState = this.parentStateRef.value();
            var outlets = outletState === undefined ? undefined : outletState.outlets;
            return outlets === undefined ? undefined : outlets[this.outletNameRef.value()];
        };

        OutletReference.prototype.get = function get(key) {
            return new PathReference(this, key);
        };

        return OutletReference;
    }();

    var PathReference = function () {
        function PathReference(parent, key) {
            (0, _emberBabel.classCallCheck)(this, PathReference);

            this.parent = parent;
            this.key = key;
            this.tag = parent.tag;
        }

        PathReference.prototype.get = function get(key) {
            return new PathReference(this, key);
        };

        PathReference.prototype.value = function value() {
            var parent = this.parent.value();
            return parent && parent[this.key];
        };

        return PathReference;
    }();

    var OrphanedOutletReference = function () {
        function OrphanedOutletReference(root, name) {
            (0, _emberBabel.classCallCheck)(this, OrphanedOutletReference);

            this.root = root;
            this.name = name;
            this.tag = root.tag;
        }

        OrphanedOutletReference.prototype.value = function value() {
            var rootState = this.root.value();
            var outletState = rootState && rootState.outlets.main;
            var outlets = outletState && outletState.outlets;
            outletState = outlets && outlets.__ember_orphans__;
            outlets = outletState && outletState.outlets;
            if (outlets === undefined) {
                return;
            }
            var matched = outlets[this.name];
            if (matched === undefined || matched.render === undefined) {
                return;
            }
            var state = Object.create(null);
            state[matched.render.outlet] = matched;
            matched.wasUsed = true;
            return { outlets: state, render: undefined };
        };

        OrphanedOutletReference.prototype.get = function get(key) {
            return new PathReference(this, key);
        };

        return OrphanedOutletReference;
    }();

    /**
      The `{{outlet}}` helper lets you specify where a child route will render in
      your template. An important use of the `{{outlet}}` helper is in your
      application's `application.hbs` file:
    
      ```handlebars
      {{! app/templates/application.hbs }}
      <!-- header content goes here, and will always display -->
      {{my-header}}
      <div class="my-dynamic-content">
        <!-- this content will change based on the current route, which depends on the current URL -->
        {{outlet}}
      </div>
      <!-- footer content goes here, and will always display -->
      {{my-footer}}
      ```
    
      You may also specify a name for the `{{outlet}}`, which is useful when using more than one
      `{{outlet}}` in a template:
    
      ```handlebars
      {{outlet "menu"}}
      {{outlet "sidebar"}}
      {{outlet "main"}}
      ```
    
      Your routes can then render into a specific one of these `outlet`s by specifying the `outlet`
      attribute in your `renderTemplate` function:
    
      ```app/routes/menu.js
      import Route from '@ember/routing/route';
    
      export default Route.extend({
        renderTemplate() {
          this.render({ outlet: 'menu' });
        }
      });
      ```
    
      See the [routing guide](https://guides.emberjs.com/release/routing/rendering-a-template/) for more
      information on how your `route` interacts with the `{{outlet}}` helper.
      Note: Your content __will not render__ if there isn't an `{{outlet}}` for it.
    
      @method outlet
      @param {String} [name]
      @for Ember.Templates.helpers
      @public
    */
    function outletHelper(vm, args) {
        var scope = vm.dynamicScope();
        var nameRef = void 0;
        if (args.positional.length === 0) {
            nameRef = new _reference.ConstReference('main');
        } else {
            nameRef = args.positional.at(0);
        }
        return new OutletComponentReference(new OutletReference(scope.outletState, nameRef));
    }
    function outletMacro(_name, params, hash, builder) {
        var expr = [_wireFormat.Ops.Helper, '-outlet', params || [], hash];
        builder.dynamicComponent(expr, null, [], null, false, null, null);
        return true;
    }

    var OutletComponentReference = function () {
        function OutletComponentReference(outletRef) {
            (0, _emberBabel.classCallCheck)(this, OutletComponentReference);

            this.outletRef = outletRef;
            this.definition = null;
            this.lastState = null;
            // The router always dirties the root state.
            this.tag = outletRef.tag;
        }

        OutletComponentReference.prototype.value = function value() {
            var state = stateFor(this.outletRef);
            if (validate(state, this.lastState)) {
                return this.definition;
            }
            this.lastState = state;
            var definition = null;
            if (state !== null) {
                definition = (0, _runtime.curry)(new OutletComponentDefinition(state));
            }
            return this.definition = definition;
        };

        OutletComponentReference.prototype.get = function get(_key) {
            return _runtime.UNDEFINED_REFERENCE;
        };

        return OutletComponentReference;
    }();

    function stateFor(ref) {
        var outlet = ref.value();
        if (outlet === undefined) return null;
        var render = outlet.render;
        if (render === undefined) return null;
        var template = render.template;
        if (template === undefined) return null;
        return {
            ref: ref,
            name: render.name,
            outlet: render.outlet,
            template: template,
            controller: render.controller
        };
    }
    function validate(state, lastState) {
        if (state === null) {
            return lastState === null;
        }
        if (lastState === null) {
            return false;
        }
        return state.template === lastState.template && state.controller === lastState.controller;
    }

    var NON_SINGLETON_RENDER_MANAGER = void 0;
    var SINGLETON_RENDER_MANAGER = void 0;
    var RenderDefinition = void 0;
    if (_deprecatedFeatures.RENDER_HELPER) {
        var AbstractRenderManager = function (_AbstractManager6) {
            (0, _emberBabel.inherits)(AbstractRenderManager, _AbstractManager6);

            function AbstractRenderManager() {
                (0, _emberBabel.classCallCheck)(this, AbstractRenderManager);
                return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager6.apply(this, arguments));
            }

            AbstractRenderManager.prototype.create = function create(env, definition, args, dynamicScope) {
                var name = definition.name;

                if (true) {
                    this._pushToDebugStack('controller:' + name + ' (with the render helper)', env);
                }
                if (dynamicScope.rootOutletState) {
                    dynamicScope.outletState = new OrphanedOutletReference(dynamicScope.rootOutletState, name);
                }
                return this.createRenderState(args, env.owner, name);
            };

            AbstractRenderManager.prototype.getLayout = function getLayout(_ref29) {
                var template = _ref29.template;

                var layout = template.asLayout();
                return {
                    handle: layout.compile(),
                    symbolTable: layout.symbolTable
                };
            };

            AbstractRenderManager.prototype.getSelf = function getSelf(_ref30) {
                var controller = _ref30.controller;

                return new RootReference(controller);
            };

            return AbstractRenderManager;
        }(AbstractManager);

        if (true) {
            AbstractRenderManager.prototype.didRenderLayout = function () {
                this.debugStack.pop();
            };
        }
        var _CAPABILITIES = {
            dynamicLayout: false,
            dynamicTag: false,
            prepareArgs: false,
            createArgs: false,
            attributeHook: false,
            elementHook: false,
            createCaller: true,
            dynamicScope: true,
            updateHook: true,
            createInstance: true
        };

        var SingletonRenderManager = function (_AbstractRenderManage) {
            (0, _emberBabel.inherits)(SingletonRenderManager, _AbstractRenderManage);

            function SingletonRenderManager() {
                (0, _emberBabel.classCallCheck)(this, SingletonRenderManager);
                return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractRenderManage.apply(this, arguments));
            }

            SingletonRenderManager.prototype.createRenderState = function createRenderState(_args, owner, name) {
                var controller = owner.lookup('controller:' + name) || (0, _emberRouting.generateController)(owner, name);
                return { controller: controller };
            };

            SingletonRenderManager.prototype.getCapabilities = function getCapabilities(_) {
                return _CAPABILITIES;
            };

            SingletonRenderManager.prototype.getTag = function getTag() {
                // todo this should be the tag of the state args
                return _reference.CONSTANT_TAG;
            };

            SingletonRenderManager.prototype.getDestructor = function getDestructor() {
                return null;
            };

            return SingletonRenderManager;
        }(AbstractRenderManager);

        SINGLETON_RENDER_MANAGER = new SingletonRenderManager();
        var NONSINGLETON_CAPABILITIES = {
            dynamicLayout: false,
            dynamicTag: false,
            prepareArgs: false,
            createArgs: true,
            attributeHook: false,
            elementHook: false,
            dynamicScope: true,
            createCaller: false,
            updateHook: true,
            createInstance: true
        };

        var NonSingletonRenderManager = function (_AbstractRenderManage2) {
            (0, _emberBabel.inherits)(NonSingletonRenderManager, _AbstractRenderManage2);

            function NonSingletonRenderManager() {
                (0, _emberBabel.classCallCheck)(this, NonSingletonRenderManager);
                return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractRenderManage2.apply(this, arguments));
            }

            NonSingletonRenderManager.prototype.createRenderState = function createRenderState(args, owner, name) {
                var model = args.positional.at(1);
                var factory = owner.factoryFor('controller:' + name) || (0, _emberRouting.generateControllerFactory)(owner, 'controller:' + name);
                var controller = factory.create({ model: model.value() });
                return { controller: controller, model: model };
            };

            NonSingletonRenderManager.prototype.update = function update(_ref31) {
                var controller = _ref31.controller,
                    model = _ref31.model;

                controller.set('model', model.value());
            };

            NonSingletonRenderManager.prototype.getCapabilities = function getCapabilities(_) {
                return NONSINGLETON_CAPABILITIES;
            };

            NonSingletonRenderManager.prototype.getTag = function getTag(_ref32) {
                var model = _ref32.model;

                return model.tag;
            };

            NonSingletonRenderManager.prototype.getDestructor = function getDestructor(_ref33) {
                var controller = _ref33.controller;

                return controller;
            };

            return NonSingletonRenderManager;
        }(AbstractRenderManager);

        NON_SINGLETON_RENDER_MANAGER = new NonSingletonRenderManager();
        RenderDefinition = function RenderDefinition(name, template, manager) {
            (0, _emberBabel.classCallCheck)(this, RenderDefinition);

            this.manager = manager;
            this.state = {
                name: name,
                template: template
            };
        };
    }

    /**
     @module ember
    */
    var renderHelper = void 0;
    if (_deprecatedFeatures.RENDER_HELPER) {
        renderHelper = function renderHelper(vm, args) {
            var env = vm.env;
            var nameRef = args.positional.at(0);
            (true && !((0, _reference.isConst)(nameRef)) && (0, _debug.assert)('The first argument of {{render}} must be quoted, e.g. {{render "sidebar"}}.', (0, _reference.isConst)(nameRef)));
            (true && !(args.positional.length === 1 || !(0, _reference.isConst)(args.positional.at(1))) && (0, _debug.assert)('The second argument of {{render}} must be a path, e.g. {{render "post" post}}.', args.positional.length === 1 || !(0, _reference.isConst)(args.positional.at(1))));

            var templateName = nameRef.value();
            // tslint:disable-next-line:max-line-length
            (true && !(env.owner.hasRegistration('template:' + templateName)) && (0, _debug.assert)('You used `{{render \'' + templateName + '\'}}`, but \'' + templateName + '\' can not be found as a template.', env.owner.hasRegistration('template:' + templateName)));

            var template = env.owner.lookup('template:' + templateName);
            var controllerName = void 0;
            if (args.named.has('controller')) {
                var controllerNameRef = args.named.get('controller');
                // tslint:disable-next-line:max-line-length
                (true && !((0, _reference.isConst)(controllerNameRef)) && (0, _debug.assert)('The controller argument for {{render}} must be quoted, e.g. {{render "sidebar" controller="foo"}}.', (0, _reference.isConst)(controllerNameRef)));

                // TODO should be ensuring this to string here
                controllerName = controllerNameRef.value();
                // tslint:disable-next-line:max-line-length
                (true && !(env.owner.hasRegistration('controller:' + controllerName)) && (0, _debug.assert)('The controller name you supplied \'' + controllerName + '\' did not resolve to a controller.', env.owner.hasRegistration('controller:' + controllerName)));
            } else {
                controllerName = templateName;
            }
            if (args.positional.length === 1) {
                var def = new RenderDefinition(controllerName, template, SINGLETON_RENDER_MANAGER);
                return UnboundReference.create((0, _runtime.curry)(def));
            } else {
                var _def = new RenderDefinition(controllerName, template, NON_SINGLETON_RENDER_MANAGER);
                var captured = args.capture();
                return UnboundReference.create((0, _runtime.curry)(_def, captured));
            }
        };
    }
    /**
      Calling ``{{render}}`` from within a template will insert another
      template that matches the provided name. The inserted template will
      access its properties on its own controller (rather than the controller
      of the parent template).
    
      If a view class with the same name exists, the view class also will be used.
      Note: A given controller may only be used *once* in your app in this manner.
      A singleton instance of the controller will be created for you.
    
      Example:
    
      ```app/controllers/navigation.js
      import Controller from '@ember/controller';
    
      export default Controller.extend({
        who: "world"
      });
      ```
    
      ```handlebars
      <!-- navigation.hbs -->
      Hello, {{who}}.
      ```
    
      ```handlebars
      <!-- application.hbs -->
      <h1>My great app</h1>
      {{render "navigation"}}
      ```
    
      ```html
      <h1>My great app</h1>
      <div class='ember-view'>
        Hello, world.
      </div>
      ```
    
      Optionally you may provide a second argument: a property path
      that will be bound to the `model` property of the controller.
      If a `model` property path is specified, then a new instance of the
      controller will be created and `{{render}}` can be used multiple times
      with the same name.
    
      For example if you had this `author` template.
    
      ```handlebars
      <div class="author">
        Written by {{firstName}} {{lastName}}.
        Total Posts: {{postCount}}
      </div>
      ```
    
      You could render it inside the `post` template using the `render` helper.
    
      ```handlebars
      <div class="post">
        <h1>{{title}}</h1>
        <div>{{body}}</div>
        {{render "author" author}}
      </div>
      ```
    
      @method render
      @for Ember.Templates.helpers
      @param {String} name
      @param {Object?} context
      @param {Hash} options
      @return {String} HTML string
      @public
      @deprecated Use a component instead
    */
    var renderMacro = void 0;
    if (_deprecatedFeatures.RENDER_HELPER) {
        renderMacro = function renderMacro(_name, params, hash, builder) {
            if (_deprecatedFeatures.RENDER_HELPER && _emberEnvironment.ENV._ENABLE_RENDER_SUPPORT === true) {
                // TODO needs makeComponentDefinition a helper that returns a curried definition
                // TODO not sure all args are for definition or component
                // likely the controller name should be a arg to create?
                var expr = [_wireFormat.Ops.Helper, '-render', params || [], hash];
                builder.dynamicComponent(expr, null, null, null, false, null, null);
                return true;
            }
            return false;
        };
    }

    function refineInlineSyntax(name, params, hash, builder) {
        (true && !(!(builder.compiler['resolver']['resolver']['builtInHelpers'][name] && builder.referrer.owner.hasRegistration('helper:' + name))) && (0, _debug.assert)('You attempted to overwrite the built-in helper "' + name + '" which is not allowed. Please rename the helper.', !(builder.compiler['resolver']['resolver']['builtInHelpers'][name] && builder.referrer.owner.hasRegistration('helper:' + name))));

        if (name.indexOf('-') === -1) {
            return false;
        }
        var handle = builder.compiler['resolver'].lookupComponentDefinition(name, builder.referrer);
        if (handle !== null) {
            builder.component.static(handle, [params === null ? [] : params, hashToArgs(hash), null, null]);
            return true;
        }
        return false;
    }
    function refineBlockSyntax(name, params, hash, template, inverse, builder) {
        if (name.indexOf('-') === -1) {
            return false;
        }
        var handle = builder.compiler['resolver'].lookupComponentDefinition(name, builder.referrer);
        if (handle !== null) {
            wrapComponentClassAttribute(hash);
            builder.component.static(handle, [params, hashToArgs(hash), template, inverse]);
            return true;
        }
        (true && !(builder.referrer.owner.hasRegistration('helper:' + name)) && (0, _debug.assert)('A component or helper named "' + name + '" could not be found', builder.referrer.owner.hasRegistration('helper:' + name)));
        (true && !(!function () {
            var resolver = builder.compiler['resolver']['resolver'];
            var _builder$referrer = builder.referrer,
                owner = _builder$referrer.owner,
                moduleName = _builder$referrer.moduleName;

            if (name === 'component' || resolver['builtInHelpers'][name]) {
                return true;
            }
            var options = { source: 'template:' + moduleName };
            return owner.hasRegistration('helper:' + name, options) || owner.hasRegistration('helper:' + name);
        }()) && (0, _debug.assert)('Helpers may not be used in the block form, for example {{#' + name + '}}{{/' + name + '}}. Please use a component, or alternatively use the helper in combination with a built-in Ember helper, for example {{#if (' + name + ')}}{{/if}}.', !function () {
            var resolver = builder.compiler['resolver']['resolver'];var _builder$referrer = builder.referrer,
                owner = _builder$referrer.owner,
                moduleName = _builder$referrer.moduleName;
            if (name === 'component' || resolver['builtInHelpers'][name]) {
                return true;
            }var options = { source: 'template:' + moduleName };return owner.hasRegistration('helper:' + name, options) || owner.hasRegistration('helper:' + name);
        }()));

        return false;
    }
    var experimentalMacros = [];
    // This is a private API to allow for experimental macros
    // to be created in user space. Registering a macro should
    // should be done in an initializer.
    function registerMacros(macro) {
        experimentalMacros.push(macro);
    }
    function populateMacros(macros) {
        var inlines = macros.inlines,
            blocks = macros.blocks;

        inlines.add('outlet', outletMacro);
        if (_deprecatedFeatures.RENDER_HELPER) {
            inlines.add('render', renderMacro);
        }
        inlines.add('mount', mountMacro);
        inlines.add('input', inputMacro);
        inlines.add('textarea', textAreaMacro);
        inlines.addMissing(refineInlineSyntax);
        if (true === true) {
            blocks.add('let', blockLetMacro);
        }
        blocks.addMissing(refineBlockSyntax);
        for (var i = 0; i < experimentalMacros.length; i++) {
            var macro = experimentalMacros[i];
            macro(blocks, inlines);
        }
        return { blocks: blocks, inlines: inlines };
    }

    var getPrototypeOf = Object.getPrototypeOf;
    var MANAGERS = new WeakMap();
    function setComponentManager(managerId, obj) {
        MANAGERS.set(obj, managerId);
        return obj;
    }
    function getComponentManager(obj) {
        if (!true) {
            return;
        }
        var pointer = obj;
        while (pointer !== undefined && pointer !== null) {
            if (MANAGERS.has(pointer)) {
                return MANAGERS.get(pointer);
            }
            pointer = getPrototypeOf(pointer);
        }
        return;
    }

    function instrumentationPayload$1(name) {
        return { object: 'component:' + name };
    }
    function makeOptions(moduleName, namespace) {
        return {
            source: moduleName !== undefined ? 'template:' + moduleName : undefined,
            namespace: namespace
        };
    }
    var BUILTINS_HELPERS = {
        if: inlineIf,
        action: action,
        concat: concat$1,
        get: get$1,
        hash: hash,
        log: log$1,
        mut: mut,
        'query-params': queryParams$1,
        readonly: readonly,
        unbound: unbound,
        unless: inlineUnless,
        '-class': classHelper$1,
        '-each-in': eachIn,
        '-input-type': inputTypeHelper$1,
        '-normalize-class': normalizeClassHelper,
        '-html-safe': htmlSafeHelper,
        '-get-dynamic-var': _runtime.getDynamicVar,
        '-mount': mountHelper,
        '-outlet': outletHelper
    };
    if (_deprecatedFeatures.RENDER_HELPER) {
        BUILTINS_HELPERS['-render'] = renderHelper;
    }
    var BUILTIN_MODIFIERS = {
        action: new ActionModifierManager()
    };

    var RuntimeResolver = function () {
        function RuntimeResolver() {
            (0, _emberBabel.classCallCheck)(this, RuntimeResolver);

            this.handles = [undefined];
            this.objToHandle = new WeakMap();
            this.builtInHelpers = BUILTINS_HELPERS;
            this.builtInModifiers = BUILTIN_MODIFIERS;
            // supports directly imported late bound layouts on component.prototype.layout
            this.templateCache = new Map();
            this.componentDefinitionCache = new Map();
            this.customManagerCache = new Map();
            this.templateCacheHits = 0;
            this.templateCacheMisses = 0;
            this.componentDefinitionCount = 0;
            this.helperDefinitionCount = 0;
            var macros = new _opcodeCompiler.Macros();
            populateMacros(macros);
            this.compiler = new _opcodeCompiler.LazyCompiler(new CompileTimeLookup(this), this, macros);
        }
        /***  IRuntimeResolver ***/
        /**
         * public componentDefHandleCount = 0;
         * Called while executing Append Op.PushDynamicComponentManager if string
         */


        RuntimeResolver.prototype.lookupComponentDefinition = function lookupComponentDefinition(name, meta) {
            (true && !(name !== 'textarea') && (0, _debug.assert)('You cannot use `textarea` as a component name.', name !== 'textarea'));
            (true && !(name !== 'input') && (0, _debug.assert)('You cannot use `input` as a component name.', name !== 'input'));

            var handle = this.lookupComponentHandle(name, meta);
            if (handle === null) {
                (true && !(false) && (0, _debug.assert)('Could not find component named "' + name + '" (no component or template with that name was found)'));

                return null;
            }
            return this.resolve(handle);
        };

        RuntimeResolver.prototype.lookupComponentHandle = function lookupComponentHandle(name, meta) {
            var nextHandle = this.handles.length;
            var handle = this.handle(this._lookupComponentDefinition(name, meta));
            if (nextHandle === handle) {
                this.componentDefinitionCount++;
            }
            return handle;
        };

        RuntimeResolver.prototype.resolve = function resolve(handle) {
            return this.handles[handle];
        };

        RuntimeResolver.prototype.lookupHelper = function lookupHelper(name, meta) {
            var nextHandle = this.handles.length;
            var helper$$1 = this._lookupHelper(name, meta);
            if (helper$$1 !== null) {
                var handle = this.handle(helper$$1);
                if (nextHandle === handle) {
                    this.helperDefinitionCount++;
                }
                return handle;
            }
            return null;
        };

        RuntimeResolver.prototype.lookupModifier = function lookupModifier(name, _meta) {
            return this.handle(this._lookupModifier(name));
        };

        RuntimeResolver.prototype.lookupPartial = function lookupPartial(name, meta) {
            var partial = this._lookupPartial(name, meta);
            return this.handle(partial);
        };

        RuntimeResolver.prototype.createTemplate = function createTemplate(factory, owner) {
            var cache = this.templateCache.get(owner);
            if (cache === undefined) {
                cache = new Map();
                this.templateCache.set(owner, cache);
            }
            var template = cache.get(factory);
            if (template === undefined) {
                var compiler = this.compiler;

                var injections = { compiler: compiler };
                (0, _emberOwner.setOwner)(injections, owner);
                template = factory.create(injections);
                cache.set(factory, template);
                this.templateCacheMisses++;
            } else {
                this.templateCacheHits++;
            }
            return template;
        };

        RuntimeResolver.prototype.handle = function handle(obj) {
            if (obj === undefined || obj === null) {
                return null;
            }
            var handle = this.objToHandle.get(obj);
            if (handle === undefined) {
                handle = this.handles.push(obj) - 1;
                this.objToHandle.set(obj, handle);
            }
            return handle;
        };

        RuntimeResolver.prototype._lookupHelper = function _lookupHelper(_name, meta) {
            var helper$$1 = this.builtInHelpers[_name];
            if (helper$$1 !== undefined) {
                return helper$$1;
            }
            var owner = meta.owner,
                moduleName = meta.moduleName;

            var name = _name;
            var namespace = undefined;
            if (false) {
                var parsed = this._parseNameForNamespace(_name);
                name = parsed.name;
                namespace = parsed.namespace;
            }
            var options = makeOptions(moduleName, namespace);
            var factory = owner.factoryFor('helper:' + name, options) || owner.factoryFor('helper:' + name);
            if (!isHelperFactory(factory)) {
                return null;
            }
            return function (vm, args) {
                var helper$$1 = factory.create();
                if (isSimpleHelper(helper$$1)) {
                    return new SimpleHelperReference(helper$$1.compute, args.capture());
                }
                vm.newDestroyable(helper$$1);
                return ClassBasedHelperReference.create(helper$$1, args.capture());
            };
        };

        RuntimeResolver.prototype._lookupPartial = function _lookupPartial(name, meta) {
            var template = (0, _emberViews.lookupPartial)(name, meta.owner);
            if (template) {
                return new _opcodeCompiler.PartialDefinition(name, template);
            } else {
                throw new Error(name + ' is not a partial');
            }
        };

        RuntimeResolver.prototype._lookupModifier = function _lookupModifier(name) {
            return this.builtInModifiers[name];
        };

        RuntimeResolver.prototype._parseNameForNamespace = function _parseNameForNamespace(_name) {
            var name = _name;
            var namespace = undefined;
            var namespaceDelimiterOffset = _name.indexOf('::');
            if (namespaceDelimiterOffset !== -1) {
                name = _name.slice(namespaceDelimiterOffset + 2);
                namespace = _name.slice(0, namespaceDelimiterOffset);
            }
            return { name: name, namespace: namespace };
        };

        RuntimeResolver.prototype._lookupComponentDefinition = function _lookupComponentDefinition(_name, meta) {
            var name = _name;
            var namespace = undefined;
            if (false) {
                var parsed = this._parseNameForNamespace(_name);
                name = parsed.name;
                namespace = parsed.namespace;
            }

            var _lookupComponent2 = (0, _emberViews.lookupComponent)(meta.owner, name, makeOptions(meta.moduleName, namespace)),
                layout = _lookupComponent2.layout,
                component = _lookupComponent2.component;

            var key = component === undefined ? layout : component;
            if (key === undefined) {
                return null;
            }
            var cachedComponentDefinition = this.componentDefinitionCache.get(key);
            if (cachedComponentDefinition !== undefined) {
                return cachedComponentDefinition;
            }
            var finalizer = (0, _instrumentation._instrumentStart)('render.getComponentDefinition', instrumentationPayload$1, name);
            if (layout && !component && _emberEnvironment.ENV._TEMPLATE_ONLY_GLIMMER_COMPONENTS) {
                var _definition = new TemplateOnlyComponentDefinition(layout);
                finalizer();
                this.componentDefinitionCache.set(key, _definition);
                return _definition;
            }
            if (true && component && component.class) {
                var managerId = getComponentManager(component.class);
                if (managerId) {
                    var manager = this._lookupComponentManager(meta.owner, managerId);
                    (true && !(!!manager) && (0, _debug.assert)('Could not find custom component manager \'' + managerId + '\' which was specified by ' + component.class, !!manager));

                    var _definition2 = new CustomManagerDefinition(name, component, manager, layout || meta.owner.lookup((0, _container.privatize)(_templateObject)));
                    finalizer();
                    this.componentDefinitionCache.set(key, _definition2);
                    return _definition2;
                }
            }
            var definition = layout || component ? new CurlyComponentDefinition(name, component || meta.owner.factoryFor((0, _container.privatize)(_templateObject2)), null, layout // TODO fix type
            ) : null;
            finalizer();
            this.componentDefinitionCache.set(key, definition);
            return definition;
        };

        RuntimeResolver.prototype._lookupComponentManager = function _lookupComponentManager(owner, managerId) {
            if (this.customManagerCache.has(managerId)) {
                return this.customManagerCache.get(managerId);
            }
            var delegate = owner.lookup('component-manager:' + managerId);
            this.customManagerCache.set(managerId, delegate);
            return delegate;
        };

        return RuntimeResolver;
    }();

    // factory for DI
    var TemplateCompiler = {
        create: function () {
            return new RuntimeResolver().compiler;
        }
    };

    var ComponentTemplate = template({ "id": "9QlMnd4c", "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}", "meta": { "moduleName": "packages/ember-glimmer/lib/templates/component.hbs" } });

    var OutletTemplate = template({ "id": "qAsZ1L5U", "block": "{\"symbols\":[],\"statements\":[[1,[21,\"outlet\"],false]],\"hasEval\":false}", "meta": { "moduleName": "packages/ember-glimmer/lib/templates/outlet.hbs" } });

    var TOP_LEVEL_NAME = '-top-level';
    var TOP_LEVEL_OUTLET = 'main';

    var OutletView = function () {
        function OutletView(_environment, renderer, owner, template) {
            (0, _emberBabel.classCallCheck)(this, OutletView);

            this._environment = _environment;
            this.renderer = renderer;
            this.owner = owner;
            this.template = template;
            var ref = this.ref = new RootOutletReference({
                outlets: { main: undefined },
                render: {
                    owner: owner,
                    into: undefined,
                    outlet: TOP_LEVEL_OUTLET,
                    name: TOP_LEVEL_NAME,
                    controller: undefined,
                    template: template
                }
            });
            this.state = {
                ref: ref,
                name: TOP_LEVEL_NAME,
                outlet: TOP_LEVEL_OUTLET,
                template: template,
                controller: undefined
            };
        }

        OutletView.extend = function extend(injections) {
            return function (_OutletView) {
                (0, _emberBabel.inherits)(_class, _OutletView);

                function _class() {
                    (0, _emberBabel.classCallCheck)(this, _class);
                    return (0, _emberBabel.possibleConstructorReturn)(this, _OutletView.apply(this, arguments));
                }

                _class.create = function create(options) {
                    if (options) {
                        return _OutletView.create.call(this, (0, _polyfills.assign)({}, injections, options));
                    } else {
                        return _OutletView.create.call(this, injections);
                    }
                };

                return _class;
            }(OutletView);
        };

        OutletView.reopenClass = function reopenClass(injections) {
            (0, _polyfills.assign)(this, injections);
        };

        OutletView.create = function create(options) {
            var _environment = options._environment,
                renderer = options.renderer,
                template = options.template;

            var owner = options[_emberOwner.OWNER];
            return new OutletView(_environment, renderer, owner, template);
        };

        OutletView.prototype.appendTo = function appendTo(selector) {
            var target = void 0;
            if (this._environment.hasDOM) {
                target = typeof selector === 'string' ? document.querySelector(selector) : selector;
            } else {
                target = selector;
            }
            (0, _runloop.schedule)('render', this.renderer, 'appendOutletView', this, target);
        };

        OutletView.prototype.rerender = function rerender() {
            /**/
        };

        OutletView.prototype.setOutletState = function setOutletState(state) {
            this.ref.update(state);
        };

        OutletView.prototype.destroy = function destroy() {
            /**/
        };

        return OutletView;
    }();

    function setupApplicationRegistry(registry) {
        registry.injection('service:-glimmer-environment', 'appendOperations', 'service:-dom-tree-construction');
        registry.injection('renderer', 'env', 'service:-glimmer-environment');
        // because we are using injections we can't use instantiate false
        // we need to use bind() to copy the function so factory for
        // association won't leak
        registry.register('service:-dom-builder', {
            create: function (_ref34) {
                var bootOptions = _ref34.bootOptions;
                var _renderMode = bootOptions._renderMode;

                switch (_renderMode) {
                    case 'serialize':
                        return _node.serializeBuilder.bind(null);
                    case 'rehydrate':
                        return _runtime.rehydrationBuilder.bind(null);
                    default:
                        return _runtime.clientBuilder.bind(null);
                }
            }
        });
        registry.injection('service:-dom-builder', 'bootOptions', '-environment:main');
        registry.injection('renderer', 'builder', 'service:-dom-builder');
        registry.register((0, _container.privatize)(_templateObject3), RootTemplate);
        registry.injection('renderer', 'rootTemplate', (0, _container.privatize)(_templateObject3));
        registry.register('renderer:-dom', InteractiveRenderer);
        registry.register('renderer:-inert', InertRenderer);
        if (_emberBrowserEnvironment.hasDOM) {
            registry.injection('service:-glimmer-environment', 'updateOperations', 'service:-dom-changes');
        }
        registry.register('service:-dom-changes', {
            create: function (_ref35) {
                var document = _ref35.document;

                return new _runtime.DOMChanges(document);
            }
        });
        registry.register('service:-dom-tree-construction', {
            create: function (_ref36) {
                var document = _ref36.document;

                var Implementation = _emberBrowserEnvironment.hasDOM ? _runtime.DOMTreeConstruction : _node.NodeDOMTreeConstruction;
                return new Implementation(document);
            }
        });
    }
    function setupEngineRegistry(registry) {
        registry.register('view:-outlet', OutletView);
        registry.register('template:-outlet', OutletTemplate);
        registry.injection('view:-outlet', 'template', 'template:-outlet');
        registry.injection('service:-dom-changes', 'document', 'service:-document');
        registry.injection('service:-dom-tree-construction', 'document', 'service:-document');
        registry.register((0, _container.privatize)(_templateObject), ComponentTemplate);
        registry.register('service:-glimmer-environment', Environment$1);
        registry.register((0, _container.privatize)(_templateObject4), TemplateCompiler);
        registry.injection('template', 'compiler', (0, _container.privatize)(_templateObject4));
        registry.optionsForType('helper', { instantiate: false });
        registry.register('helper:loc', loc$1);
        registry.register('component:-text-field', TextField);
        registry.register('component:-text-area', TextArea);
        registry.register('component:-checkbox', Checkbox);
        registry.register('component:link-to', LinkComponent);
        if (!_emberEnvironment.ENV._TEMPLATE_ONLY_GLIMMER_COMPONENTS) {
            registry.register((0, _container.privatize)(_templateObject2), Component);
        }
    }

    /**
      [Glimmer](https://github.com/tildeio/glimmer) is a templating engine used by Ember.js that is compatible with a subset of the [Handlebars](http://handlebarsjs.com/) syntax.
    
      ### Showing a property
    
      Templates manage the flow of an application's UI, and display state (through
      the DOM) to a user. For example, given a component with the property "name",
      that component's template can use the name in several ways:
    
      ```app/components/person-profile.js
      import Component from '@ember/component';
    
      export default Component.extend({
        name: 'Jill'
      });
      ```
    
      ```app/components/person-profile.hbs
      {{name}}
      <div>{{name}}</div>
      <span data-name={{name}}></span>
      ```
    
      Any time the "name" property on the component changes, the DOM will be
      updated.
    
      Properties can be chained as well:
    
      ```handlebars
      {{aUserModel.name}}
      <div>{{listOfUsers.firstObject.name}}</div>
      ```
    
      ### Using Ember helpers
    
      When content is passed in mustaches `{{}}`, Ember will first try to find a helper
      or component with that name. For example, the `if` helper:
    
      ```handlebars
      {{if name "I have a name" "I have no name"}}
      <span data-has-name={{if name true}}></span>
      ```
    
      The returned value is placed where the `{{}}` is called. The above style is
      called "inline". A second style of helper usage is called "block". For example:
    
      ```handlebars
      {{#if name}}
      I have a name
      {{else}}
      I have no name
      {{/if}}
      ```
    
      The block form of helpers allows you to control how the UI is created based
      on the values of properties.
      A third form of helper is called "nested". For example here the concat
      helper will add " Doe" to a displayed name if the person has no last name:
    
      ```handlebars
      <span data-name={{concat firstName (
      if lastName (concat " " lastName) "Doe"
      )}}></span>
      ```
    
      Ember's built-in helpers are described under the [Ember.Templates.helpers](/api/ember/release/classes/Ember.Templates.helpers)
      namespace. Documentation on creating custom helpers can be found under
      [Helper](/api/classes/Ember.Helper.html).
    
      ### Invoking a Component
    
      Ember components represent state to the UI of an application. Further
      reading on components can be found under [Component](/api/ember/release/classes/Component).
    
      @module @ember/component
      @main @ember/component
      @public
     */

    exports.RootTemplate = RootTemplate;
    exports.template = template;
    exports.Checkbox = Checkbox;
    exports.TextField = TextField;
    exports.TextArea = TextArea;
    exports.LinkComponent = LinkComponent;
    exports.Component = Component;
    exports.ROOT_REF = ROOT_REF;
    exports.Helper = Helper;
    exports.helper = helper;
    exports.Environment = Environment$1;
    exports.SafeString = SafeString;
    exports.escapeExpression = escapeExpression;
    exports.htmlSafe = htmlSafe;
    exports.isHTMLSafe = isHTMLSafe;
    exports.Renderer = Renderer;
    exports.InertRenderer = InertRenderer;
    exports.InteractiveRenderer = InteractiveRenderer;
    exports._resetRenderers = _resetRenderers;
    exports.renderSettled = renderSettled;
    exports.getTemplate = getTemplate;
    exports.setTemplate = setTemplate;
    exports.hasTemplate = hasTemplate;
    exports.getTemplates = getTemplates;
    exports.setTemplates = setTemplates;
    exports.setupEngineRegistry = setupEngineRegistry;
    exports.setupApplicationRegistry = setupApplicationRegistry;
    exports._registerMacros = registerMacros;
    exports._experimentalMacros = experimentalMacros;
    exports.AbstractComponentManager = AbstractManager;
    exports.UpdatableReference = UpdatableReference;
    exports.INVOKE = INVOKE;
    exports.iterableFor = _iterableFor;
    exports.DebugStack = DebugStack$1;
    exports.OutletView = OutletView;
    exports.capabilities = capabilities;
    exports.setComponentManager = setComponentManager;
    exports.getComponentManager = getComponentManager;
});